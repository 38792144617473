/* eslint-disable @typescript-eslint/camelcase */
// import { useNavigate } from "react-router-dom";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  publicClient,
  LoanStage,
  preAssignedURL,
  CreateLoanParamsBridgeLoan,
  isCancel,
  LoanRule,
  UserRole
} from "@toorak/tc-common-fe-sdk";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
// import { push } from "connected-react-router";
import {
  PropertyDetailsRepresentable,
  // BridgeLoanDetailsRepresentable,
  ThirtyYearLoanDetailsRepresentable,
  // ThirtyYearPropertyDetailsRepresentable,
  getEmptyBorrower,
  BorrowerInformation,
  MultiplUnitInfo
} from "./create-loan.reducerHelper";
import {
  updateSummaryDetailsReducer,
  addExceptionGrade,
  addInternalGrade,
  setLoanPreviousState,
  updatePreviousStageRedux,
  getCreditScoreBucketsSuccess,
  getLoanPurposesSuccess,
  getAppraisalVendorsSuccess,
  loanTypeSuccess,
  setMandatoryResultsSuccess,
  setMandatoryDocumentsSuccess,
  tagsSuccess,
  dashboardTagsSuccess,
  loanListSuccess,
  loanListCount,
  loanListMultiDispatch,
  updateLoanListTabCount,
  sortLoanListSuccess,
  // dropdownDataSuccess,
  compactSearchUpdate,
  headerFiltersUpdateSuccess,
  rescindSuccessDialog,
  activeInteractionsModal,
  deleteActIntAskQuest,
  addResponseToActiveIntArray,
  filtersUpdateSuccess,
  setCreditSyncFlag,
  fetchEditHistory,
  fetchInfoIconContent,
  updateBridgeLoanGurantorRedux,
  missingEditComments,
  fetchPartyId,
  updateBorrowerDetails,
  deleteBorrowerDetails,
  updateLoanStatusLoan,
  updatePropertyDetailsRedux,
  updateBridgeLoanDetailsRedux,
  updateThirtyYearLoanDetailsRedux,
  setTermsheetByIdSuccess,
  updateFilesInDocsRedux,
  validateFormRedux,
  setActiveTabAndPropertyRedux,
  updateTagsInDocs,
  updateFailureCountInDocs,
  setDocsByIdSuccess,
  updatePropertyUnits,
  loanTypeFailed,
  activeIntFailed,
  updateSelectedLoansRedux,
  updateLoanListSearch,
  updateLoadingFlag,
  setRateSheetData,
  setVersionDate,
  setRateSheetVersions,
  setOverlaysVersions,
  updateSelectedVersionRedux,
  setDueDiligenceInfo,
  updateToggleSelectionsRedux,
  updateOnHoldInfo,
  updateLoanLandmark,
  customerInfo,
  validateFormForSubmit,
  updateSuccessCountInDocs,
  postLoanSuccess,
  dropDownDataSuccess,
  filterDataSuccess,
  setLoanByIdSuccess,
  loanUserMapSuccess,
  setAutoSaveCall,
  resetLoanStore,
  updateLoanStage,
  updateFESBorrowerDetails
} from "./create-loan.reducer";
import { getRateLockRequestedStatus, setIntervalX, uuidV4 } from "../utils/utils";
import {
  evaluateLoan,
  getWaiverList
} from "../stores/EvaluationResults/EvaluationResults.action";
import {
  showLoader,
  hideLoader
} from "../stores/loaderAndException/loaderAndException.action";
import {
  formatValueByType,
  isNullOrUndefined,
  isViewOnly,
  toISOLocalDateString,
  isEmptyValue,
  getNumFromCommaSeparatedNum,
  formatNumberToCommaSeparated,
  sanitizePercentage
} from "../utils/formatChecks";
import { getBridgeLoanGuarantorInformationToPass } from "./CreateLoanHeader";
import {
  getEvaluationRequestBody,
  percentageOccupiedFormula
} from "./evaluation-results/helpers/ruleRequestBody";
import {
  isLATUser,
  isRoleDSCR,
  isRoleBridge,
  isRole,
  isILP
} from "../utils/AccessManagement";
import { getConfig, getLoanType as loanTypeName } from "../config/config";
import { setCookie, getCookie } from "../utils/cookies";
import { getDefaultValue } from "./constants/loanFieldType";
import {
  LoanStructureTypes,
  RecourseEnum,
  ToorakProductEnum
  // rentDescription
} from "./constants/loanCreationDropDownValues";
import {
  ErrorPriority,
  getErrorConfigObject
} from "../ErrorHandler/ErrorConfigData";
import {
  InternalDDStatusEnum,
  InquiryStatusEnum,
  InternalRolesEnum,
  loansToILPAtInitialReviewCutOff,
  // TABLE_FUNDING,
  iLPGoLiveDate,
  postApprovalStates
} from "../utils/constants";
import {
  appendToQueryForLATToggle,
  appendToQueryForLAT1,
  appendToQueryForLAT2,
  onHoldQuery,
  noOnHoldQuery
} from "../utils/queryConstants";
import { errorMessageJson } from "../ErrorHandler/ErrorMessageJson";
import {
  getEmptyUnitInfo,
  updateLoanDetailsInRedux
} from "../stores/loanAndPropertyDetails/loanAndPropertyDetails.action";
import { formatFesLoanDetailsForRedux } from "../stores/FrontEndSizer/fesLoanCreation.action";
import { globalGetDataByLoanID } from "./CreateLoan";
// import { SET_GRADE } from "../stores/ILP/ILP.action";
import { ObjectType } from "../masterView/common";
import { getAutomaticTaggedDocuments } from "../stores/documentReview/DocumentReview.action";
import { resetAppraisalStore } from "../stores/appraisal/appraisal.reducer";
import {
  ToorvalRoles,
  matchRole,
  matchRoles,
  getIsToorvalFilter,
  getFilter
} from "./appraisal/util";
import { typeCastForReadOnly } from "./tape-to-file/TapeToFileUtils";
import {
  getQueryForAggregations,
  getQueryForSort
} from "../utils/esCustomSortAndFilterQueries";
import {
  updateAwsDocumentUrl,
  updateHasDocumentStatus
} from "../stores/Notes/Notes.reducer";
import { setGradeRedux } from "../stores/ILP/ILP.reducer";
import { setLoading } from "./create-loan.reducer";
import { resetExceptionStore } from "../stores/ExceptionsResults/ExceptionsReducer";
import { resetWaiverStore } from "../stores/WaiverRequest/WaiverRequest.reducer";
import { resetDiscussionStore } from "../stores/Discussions/Discussions.reducer";
import { getAutomaticPLTaggedDocuments } from "../stores/purchasedLoansDocReview/purchasedLoansDocReview.reducer";
import { resetEvaluationStore } from "../stores/EvaluationResults/EvaluationResults.reducer";
import { setRawLoanListData } from "../stores/masterView/masterView.reducer";
// import { setLoading } from "./create-loan.reducer";
// import {
//   UPDATE_AWS_DOCUMENT_URL,
//   UPDATE_HAS_DOCUMENT_STATUS
// } from "../stores/Notes/Notes.constant";

// export const GET_CREDIT_SCORE_BUCKETS_LOADING =
//   "GET_CREDIT_SCORE_BUCKETS_LOADING";
// export const GET_CREDIT_SCORE_BUCKETS_SUCCESS =
//   "GET_CREDIT_SCORE_BUCKETS_SUCCESS";
// export const GET_CREDIT_SCORE_BUCKETS_FAILED =
//   "GET_CREDIT_SCORE_BUCKETS_FAILED";

// export const GET_LOAN_PURPOSES_LOADING = "GET_LOAN_PURPOSES_LOADING";
// export const GET_LOAN_PURPOSES_SUCCESS = "GET_LOAN_PURPOSES_SUCCESS";
// export const GET_LOAN_PURPOSES_FAILED = "GET_LOAN_PURPOSES_FAILED";

// export const GET_APPRAISAL_VENDORS_LOADING = "GET_APPRAISAL_VENDORS_LOADING";
// export const GET_APPRAISAL_VENDORS_SUCCESS = "GET_APPRAISAL_VENDORS_SUCCESS";
// export const GET_APPRAISAL_VENDORS_FAILED = "GET_APPRAISAL_VENDORS_FAILED";
// export const CONDO_QUEST_OPENED = "CONDO_QUEST_OPENED";
// export const LOAN_TYPE_LOADING = "LOAN_TYPE_LOADING";
// export const LOAN_TYPE_SUCCESS = "LOAN_TYPE_SUCCESS";
// export const ADD_EXCEPTION_GRADE = "ADD_EXCEPTION_GRADE";
// export const ADD_INTERNAL_GRADE = "ADD_INTERNAL_GRADE";
// export const LOAN_TYPE_FAILED = "LOAN_TYPE_FAILED";
// export const SET_CREDIT_SYNC_FLAG = "SET_CREDIT_SYNC_FLAG";
// export const POST_LOAN_LOADING = "POST_LOAN_LOADING";
// export const POST_LOAN_SUCCESS = "POST_LOAN_SUCCESS";
// export const POST_LOAN_FAILED = "POST_LOAN_FAILED";

// export const VALUE_BEING_EDITED = "VALUE_BEING_EDITED";
// export const RESET_LOAN_STORE = "RESET_LOAN_STORE";
// export const RESET_EVALUATION_STORE = "RESET_EVALUATION_STORE";
// export const RESET_EXCEPTION_STORE = "RESET_EXCEPTION_STORE";
// export const RESET_WAIVER_STORE = "RESET_WAIVER_STORE";
// export const RESET_DISCUSSION_STORE = "RESET_DISCUSSION_STORE";
// export const POST_PROPERTY_SUCCESS = "POST_PROPERTY_SUCCESS";
// export const POST_PROPERTY_LOADING = "POST_PROPERTY_LOADING";
// export const POST_PROPERTY_FAILED = "POST_PROPERTY_FAILED";

// export const SAVE_LOAN_DETAILS_FORM = "SAVE_LOAN_DETAILS_FORM";
// export const VALIDATE_FORM = "VALIDATE_FORM";
// export const VALIDATE_FORM_FOR_SUBMIT = "VALIDATE_FORM_FOR_SUBMIT";
// export const UPDATE_PROPERTY_DETAILS = "UPDATE_PROPERTY_DETAILS";
// export const UPDATE_LOAN_STATUS = "UPDATE_LOAN_STATUS";
// export const UPDATE_ILP_STATE = "UPDATE_ILP_STATE";
// export const UPDATE_TTF_STATE = "UPDATE_TTF_STATE";
// export const UPDATE_COMPLETE_DATE = "UPDATE_COMPLETE_DATE";
// export const GET_PROPERTY_DETAILS = "GET_PROPERTY_DETAILS";
// export const GET_PROPERTY_LOADING = "GET_PROPERTY_LOADING";
// export const GET_PROPERTY_FAILED = "GET_PROPERTY_FAILED";
// export const UPDATE_BRIDGE_LOAN_GUARANTOR = "UPDATE_BRIDGE_LOAN_GUARANTOR";
// export const UPDATE_BRIDGE_LOAN_DETAILS = "UPDATE_BRIDGE_LOAN_DETAILS";
// export const UPDATE_SUMMARY_DETAILS = "UPDATE_SUMMARY_DETAILS";
// export const UPDATE_LOAN_CONFIG = "UPDATE_LOAN_CONFIG";
// export const UPDATE_LOAN_DATA = "UPDATE_LOAN_DATA";
// export const UPDATE_SUMMARY_DETAILS_PROPERTY =
//   "UPDATE_SUMMARY_DETAILS_PROPERTY";
// export const UPDATE_PRIMARY_GUARANTOR = "UPDATE_PRIMARY_GUARANTOR";
// export const DELETE_BRIDGE_LOAN_GUARANTOR = "DELETE_BRIDGE_LOAN_GUARANTOR";
// export const UPDATE_FILES_IN_DOCS = "UPDATE_FILES_IN_DOCS";
// export const UPDATE_PERCENTAGE_IN_DOCS = "UPDATE_PERCENTAGE_IN_DOCS";
// export const UPDATE_TAGS_IN_DOCS = "UPDATE_TAGS_IN_DOCS";
// export const UPDATE_FAILURE_COUNT_IN_DOCS = "UPDATE_FAILURE_COUNT_IN_DOCS";
// export const UPDATE_SUCCESS_COUNT_IN_DOCS = "UPDATE_SUCCESS_COUNT_IN_DOCS";
// export const UPDATE_THIRTY_YEAR_LOAN_GUARANTOR =
//   "UPDATE_THIRTY_YEAR_LOAN_GUARANTOR";
// export const UPDATE_THIRTY_YEAR_LOAN_PRIMARY_GUARANTOR =
//   "UPDATE_THIRTY_YEAR_LOAN_PRIMARY_GUARANTOR";
// export const DELETE_THIRTY_YEAR_LOAN_GUARANTOR =
//   "DELETE_THIRTY_YEAR_LOAN_GUARANTOR";
// export const UPDATE_30_YEARS_LOAN_DETAILS = "UPDATE_30_YEAR_LOAN_DETAILS";
// export const SET_ACTIVE_PROPERTY_ID = "SET_ACTIVE_PROPERTY_ID";
// export const DELETE_PROPERTY_DETAILS = "DELETE_PROPERTY_DETAILS";
// export const SET_ACTIVE_TAB_AND_PROPERTY = "SET_ACTIVE_TAB_AND_PROPERTY";
// export const RESCIND_SUCCESS_DIALOG = "RESCIND_SUCCESS_DIALOG";
// export const SET_ACTIVE_EDIT_LOAN_SECTION = "SET_ACTIVE_EDIT_LOAN_SECTION";
// export const DELETE_THIRTY_YEAR_PROPERTY_DETAILS =
//   "DELETE_THIRTY_YEAR_PROPERTY_DETAILS";

// export const RESET_LOAN_DETAILS = "RESET_LOAN_DETAILS";

// export const LOAN_TO_DELETE = "LOAN_TO_DELETE";
// export const LOAN_TO_CONVERT = "LOAN_TO_CONVERT";
// export const LOAN_TO_CHECK_UPLOAD = "LOAN_TO_CHECK_UPLOAD";

// export const LOAN_LIST_LOADING = "LOAN_LIST_LOADING";
// export const FILTERS_UPDATE_SUCCESS = "FILTERS_UPDATE_SUCCESS";
// export const HEADER_FILTERS_UPDATE_SUCCESS = "HEADER_FILTERS_UPDATE_SUCCESS";
// export const UPDATE_ACTIVE_INTERACTIONS_ARRAY =
//   "UPDATE_ACTIVE_INTERACTIONS_ARRAY";
// export const DELETE_WAIVER = "DELETE_WAIVER";
// export const LOAN_LIST_SUCCESS = "LOAN_LIST_SUCCESS";
// export const LOAN_LIST_COUNT = "LOAN_LIST_COUNT";
// export const LOAN_LIST_FAILED = "LOAN_LIST_FAILED";
// export const ACTIVE_INT_FAILED = "ACTIVE_INT_FAILED";
// export const ADD_RESPONSE_TO_ACTIVEINT_ARRAY =
//   "ADD_RESPONSE_TO_ACTIVEINT_ARRAY";
// export const SORT_LOAN_LIST_SUCCESS = "SORT_LOAN_LIST_SUCCESS";
// export const SET_RAW_LOAN_LIST_DATA = "SET_RAW_LOAN_LIST_DATA";
// export const DROPDOWN_DATA_SUCCESS = "DROPDOWN_DATA_SUCCESS";
// export const FILTER_DATA_SUCCESS = "FILTER_DATA_SUCCESS";
// export const UPDATE_SELECTED_LOAN_RULE_VERSIONS =
//   "UPDATE_SELECTED_LOAN_RULE_VERSIONS";
// export const GET_LOAN_BY_ID_LOADING = "GET_LOAN_BY_ID_LOADING";
// export const GET_LOAN_BY_ID_SUCCESS = "GET_LOAN_BY_ID_SUCCESS";
// export const SET_LOAN_PREVIOUS_STATE = "SET_LOAN_PREVIOUS_STATE";
// export const GET_LOAN_BY_ID_FAILED = "GET_LOAN_BY_ID_FAILED";
// export const GET_DOCS_BY_ID_LOADING = "GET_LOAN_BY_ID_LOADING";
// export const GET_DOCS_BY_ID_SUCCESS = "GET_DOCS_BY_ID_SUCCESS";
// export const GET_DOCS_BY_ID_FAILED = "GET_LOAN_BY_ID_FAILED";
// export const GET_TERMSHEET_BY_ID_SUCCESS = "GET_TERMSHEET_BY_ID_SUCCESS";
// export const DOCS_CURRENT_UPLOAD_SUCCEESS = "DOCS_CURRENT_UPLOAD_SUCCEESS";
// export const FETCH_EDIT_HISTORY = "FETCH_EDIT_HISTORY";
// export const SET_RATE_SHEET_DATA = "SET_RATE_SHEET_DATA";
// export const SET_RATE_SHEET_VERSIONS = "SET_RATE_SHEET_VERSIONS";
// export const SET_OVERLAYS_VERSIONS = "SET_OVERLAYS_VERSIONS";
// export const FETCH_PARTYID = "FETCH_PARTYID";
// export const SET_VERSION_DATE = "SET_VERSION_DATE";
// export const GET_MANDATORY_DOCUMENTS_LOADING =
//   "GET_MANDATORY_DOCUMENTS_LOADING";
// export const GET_MANDATORY_DOCUMENTS_SUCCESS =
//   "GET_MANDATORY_DOCUMENTS_SUCCESS";
// export const GET_MANDATORY_DOCUMENTS_FAILED = "GET_MANDATORY_DOCUMENTS_FAILED";
// export const FETCH_INFO_ICON_CONTENT = "FETCH_INFO_ICON_CONTENT";

// export const GET_MANDATORY_RESULTS_LOADING = "GET_MANDATORY_RESULTS_LOADING";
// export const GET_MANDATORY_RESULTS_SUCCESS = "GET_MANDATORY_RESULTS_SUCCESS";
// export const GET_MANDATORY_RESULTS_FAILED = "GET_MANDATORY_RESULTS_FAILED";
// export const TAGS_LOADING = "TAGS_LOADING";
// export const TAGS_SUCCESS = "TAGS_SUCCESS";
// export const DASHBOARD_TAGS_SUCCESS = "DASHBOARD_TAGS_SUCCESS";
// export const TAGS_FAILED = "TAGS_FAILED";
// export const INTERNAL_USERS_SUCCESS = "INTERNAL_USERS_SUCCESS";
// export const GROUPS_SUCCESS = "GROUPS_SUCCESS";
// export const GURRANTOR_FIELD_CHANGED = "GURRANTOR_FIELD_CHANGED";
// export const BORROWER_FIELD_CHANGED = "BORROWER_FIELD_CHANGED";
// export const UNIT_FIELD_CHANGED = "UNIT_FIELD_CHANGED";
// export const UPDATE_PROPERTY_UNITS = "UPDATE_PROPERTY_UNITS";
// export const DELETE_PROPERTY_UNITS = "DELETE_PROPERTY_UNITS";
// export const UPDATE_SELECTED_LIST = "UPDATE_SELECTED_LIST";
// export const SET_SELECTED_LOAN_LIST = "SET_SELECTED_LOAN_LIST";
// export const MISSING_EDIT_COMMENTS = "MISSING_EDIT_COMMENTS";
// export const UPLOAD_EXCEL_LOAN_CHANGE = "UPLOAD_EXCEL_LOAN_CHANGE";
// export const UPDATE_EDIT_COMMENTS = "UPDATE_EDIT_COMMENTS";
// export const UPDATE_BORROWER_DETAILS = "UPDATE_BORROWER_DETAILS";
// export const UPDATE_PRIMARY_BORROWER = "UPDATE_PRIMARY_BORROWER";
// export const DELETE_BORROWER_DETAILS = "DELETE_BORROWER_DETAILS";
// export const UPDATE_PREVIOUS_STAGE = "UPDATE_PREVIOUS_STAGE";
// export const UPDATE_IS_WITHDRAWN_STATE = "UPDATE_IS_WITHDRAWN_STATE";
// export const MISSING_BORROWER_OR_GUARANTOR = "MISSING_BORROWER_OR_GUARANTOR";
// export const SET_AUTO_SAVE_CALL = "SET_AUTO_SAVE_CALL";
// export const LOAN_USERMAP_SUCCESS = "LOAN_USERMAP_SUCCESS";
// export const UPDATE_LOAN_STAGE = "UPDATE_LOAN_STAGE";
// export const UPDATE_TIME_INTERVAL = "UPDATE_TIME_INTERVAL";
// export const UPDATE_COMPACT_TAB = "UPDATE_COMPACT_TAB";
// export const UPDATE_COMPACT_TAB_INDEX = "UPDATE_COMPACT_TAB_INDEX";
// export const UPDATE_LOAN_LIST_TAB_COUNT = "UPDATE_LOAN_LIST_TAB_COUNT";
// export const UPDATE_LOAN_LIST_VIEW = "UPDATE_LOAN_LIST_VIEW";
// export const UPDATE_SELECTED_LOANS = "UPDATE_SELECTED_LOANS";
// export const UPDATE_LOAN_LIST_SEARCH = "UPDATE_LOAN_LIST_SEARCH";
// export const SET_SEARCH_TYPE = "SET_SEARCH_TYPE";
// export const UPDATE_LOADING_FLAG = "UPDATE_LOADING_FLAG";
// export const SHOW_SUMMARY_CHECK_LIST_MODAL = "SHOW_SUMMARY_CHECK_LIST_MODAL";
// export const HIDE_SUMMARY_CHECK_LIST_MODAL = "HIDE_SUMMARY_CHECK_LIST_MODAL";
// export const SINGLE_LOAN_ROW = "SINGLE_LOAN_ROW";
// export const UPDATE_SELECTED_VERSION = "UPDATE_SELECTED_VERSION";
// export const UPDATE_LOAN_RULE_VERSIONS = "UPDATE_LOAN_RULE_VERSIONS";
// export const SET_DUE_DILIGENCE_INFO = "SET_DUE_DILIGENCE_INFO";
// export const UPDATE_TOGGLE_SELECTIONS = "UPDATE_TOGGLE_SELECTIONS";
// export const SELECT_ALL_LOANS = "SELECT_ALL_LOANS";
// export const UPDATE_ON_HOLD_INFO = "UPDATE_ON_HOLD_INFO";
// export const UPDATE_LOAN_LANDMARK = "UPDATE_LOAN_LANDMARK";
// export const UPDATE_CONFIDENCE_SCORE = "UPDATE_CONFIDENCE_SCORE";
// export const OPEN_COLUMN_REORDER = "OPEN_COLUMN_REORDER";
// export const CUSTOMER_INFO = "CUSTOMER_INFO";
// export const CUSTOM_COLUMN_LIST = "CUSTOM_COLUMN_LIST";
// export const UPDATE_SORT_CONFIG = "UPDATE_SORT_CONFIG";
// export const UPDATE_TTF_SUBMIT = "UPDATE_TTF_SUBMIT";
// export const COMPACT_SEARCH_UPDATE = "COMPACT_SEARCH_UPDATE";
// export const ACTIVE_INTERACTIONS_MODAL = "ACTIVE_INTERACTIONS_MODAL";
// export const DELETE_ACT_INT_ASKQUEST = "DELETE_ACT_INT_ASKQUEST";
// export const SET_LOAN_TABLE_FUNDING = "SET_LOAN_TABLE_FUNDING";
// export const SET_WAREHOUSE_FUNDING = "SET_WAREHOUSE_FUNDING";
// export const CLOSE_ACTIVE_INTERACTIONS_MODAL =
//   "CLOSE_ACTIVE_INTERACTIONS_MODAL";
// export const SET_PAGE_BLOCK = "SET_PAGE_BLOCK";
// export const IS_GROUNDUP_BRIDGE = "IS_GROUNDUP_BRIDGE";
// export const UPDATE_ACT_INT_LOANLIST = "UPDATE_ACT_INT_LOANLIST";
// export const LOAN_LIST_MULTI_DISPATCH = "LOAN_LIST_MULTI_DISPATCH";
// export const SET_TABLE_FUNDING_FLAG = "SET_TABLE_FUNDING_FLAG";
// export const SET_WAREHOUSE_FUNDING_FLAG = "SET_WAREHOUSE_FUNDING_FLAG";
// export const SET_PARTYID = "SET_PARTYID";

const creditScoreBuckets = ["FICO 800+", "FICO 600-800", "FICO 400-600"];
const appraisalVendors = ["Vendor 1", "Vendor 2", "Vendor 3"];
const loanPurposes = [
  "Cash Out Refinance",
  "Purchase",
  "Refinance (Cash Out)",
  "Refinance (No Cash Out)"
];

interface InputItem {
  value?: string;
  comment: string;
  updatedBy?: string;
  field: string;
}

function appendToorvalRules(searchQuery: any, fromFilter: boolean = false) {
  const { loanStage, detailedViewSelectedFiltersList = {} } = getFilter();
  const { filterSelections = {} } = detailedViewSelectedFiltersList;
  const isToorvalFilter = getIsToorvalFilter();
  const loanStateFilter = filterSelections?.["loan.loanState.raw"];
  // handle old toorval's using LoanStatusEnum.Submit as status
  if (
    loanStateFilter?.some(
      (v: string) =>
        v === LoanStatusEnum.UnderReview || v === LoanStatusEnum.Submit
    )
  ) {
    // when on All Loans and Submit is chosen
    if (loanStateFilter?.includes(LoanStatusEnum.Submit)) {
      // do not show toorval
      if (!fromFilter)
        searchQuery.bool.must_not.push({
          terms: {
            "loan.loanStage.raw": [LoanStage.tv]
          }
        });
    } else if (loanStateFilter?.includes(LoanStatusEnum.UnderReview)) {
      searchQuery.bool.must = searchQuery?.bool?.must?.reduce(
        (agg: any, element: any) => {
          if (element === "allTab") return agg;
          // show both submit and UnderReview
          if (element?.terms?.["loan.loanState.raw"]) {
            agg.push({
              terms: {
                "loan.loanState.raw": [
                  ...element.terms["loan.loanState.raw"],
                  LoanStatusEnum.Submit
                ]
              }
            });
            return agg;
          }
          // show toorval but not fes
          if (element?.terms?.["loan.loanStage.raw"] && !fromFilter) {
            agg.push({
              terms: {
                "loan.loanStage.raw": [
                  ...element.terms["loan.loanStage.raw"].filter(
                    (v: string) => v === LoanStage.fes
                  ),
                  LoanStage.tv
                ]
              }
            });
            return agg;
          }
          agg.push(element);
          return agg;
        },
        []
      );

      // show toorval but not fes
      if (!fromFilter) {
        searchQuery.bool.must_not.push({
          terms: { "loan.loanStage.raw": [LoanStage.fes] }
        });
        // stip out converted toorvals
        searchQuery?.bool?.must_not?.push({
          exists: {
            field: "appraisal.appraisal.convertedTo"
          }
        });
      }
    }
  }
  if (
    matchRoles(
      [ToorvalRoles.APPRAISER], // if user is these roles
      [ToorvalRoles.LAT, ToorvalRoles.ORIGINATOR], // and not these roles
      false
    )
  ) {
    if (
      isToorvalFilter ||
      loanStage === "See both" ||
      loanStage === "Toorval Appraisal"
    ) {
      // do not show data entry
      searchQuery?.bool?.must_not?.push({
        terms: {
          "loan.loanState.raw": [LoanStatusEnum.DataEntry]
        }
      });
    }
  }
  if (isToorvalFilter || loanStage === "Toorval Appraisal") {
    searchQuery?.bool?.must_not?.push({
      exists: {
        field: "appraisal.appraisal.convertedTo"
      }
    });
    searchQuery.bool.must_not = searchQuery?.bool?.must_not?.filter(
      ({ terms }: any) => {
        return !terms?.["loan.loanState.raw"]?.some(
          (v: string) => v === "Rejected"
        );
      }
    );
  }
}

export const getLoanStage = (loanDetails: string) => {
  switch (loanDetails) {
    case LoanStage.pre:
      return "Pre-Close";
    case LoanStage.post:
      return "Post-Close";
    case LoanStage.fes:
      return "Guideline Evaluation";
    case LoanStage.tv:
      return "Toorval";
    default:
      return "";
  }
};

// export const updateLoanConfig: any = (field: string, value: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: UPDATE_LOAN_CONFIG,
//       payload: { field, value }
//     });
//   };
// };
// export const setSelectedLoanList: any = (ids: string[]) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_SELECTED_LOAN_LIST,
//       payload: ids
//     });
//   };
// };

// export const updateList: any = (ids: string[]) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: UPDATE_SELECTED_LIST,
//       payload: ids
//     });
//   };
// };
// export const updateLoanLevelData: any = (fieldId: string, value: any) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: UPDATE_LOAN_DATA,
//       payload: { fieldId, value }
//     });
//   };
// };
// export const setCustomColumnList: any = (data: any[]) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: CUSTOM_COLUMN_LIST,
//       payload: data
//     });
//   };
// };

// export const setIsWithdrawnLoan: any = (data: boolean) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: UPDATE_IS_WITHDRAWN_STATE,
//       payload: data
//     });
//   };
// };

// export const setOpenColumnReorder: any = (value: boolean) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: OPEN_COLUMN_REORDER,
//       payload: value
//     });
//   };
// };

// export const setPageBlock: any = (value: boolean) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_PAGE_BLOCK,
//       payload: value
//     });
//   };
// };

// export function setAutoSaveCall(value: boolean): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_AUTO_SAVE_CALL,
//       payload: { autoSave: value }
//     });
//   };
// }
// export function valueEditing(value: boolean): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: VALUE_BEING_EDITED,
//       payload: value
//     });
//   };
// }

export function getCreditScoreBuckets(): any {
  return async (dispatch: any) => {
    try {
      // dispatch({ type: GET_CREDIT_SCORE_BUCKETS_LOADING });
      dispatch(setLoading());

      // dispatch({
      //   type: GET_CREDIT_SCORE_BUCKETS_SUCCESS,
      //   payload: { buckets: creditScoreBuckets }
      // });
      dispatch(
        getCreditScoreBucketsSuccess({
          buckets: creditScoreBuckets
        })
      );
    } catch (err) {
      // dispatch({ type: GET_CREDIT_SCORE_BUCKETS_FAILED, error: err });
      dispatch(
        loanTypeFailed({
          err
        })
      );
    }
  };
}
export function getLoanHistory(loanId: string): any {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/aggregate/auditservice/loans/${loanId}`;
    const resp = await publicClient.get(url);

    // dispatch({
    //   type: FETCH_EDIT_HISTORY,
    //   payload: resp.data
    // });
    dispatch(fetchEditHistory(resp.data));
  };
}

export function getRateSheetData(
  orgPatyId: string,
  loanType: string,
  loanRuleVersions: any[]
): any {
  return async (dispatch: any) => {
    const url =
      loanType === loanTypeName[0].displayValue
        ? `${getConfig().apiUrl}/ratesheet/bridge?originatorId=${orgPatyId}`
        : `${getConfig().apiUrl}/ratesheet/30year?originatorId=${orgPatyId}`;

    const data = loanRuleVersions.filter((it: any) => it.version);
    const resp = await publicClient.post(url, data);
    // dispatch({
    //   type: SET_RATE_SHEET_DATA,
    //   payload: resp.data.data
    // });
    dispatch(
      setRateSheetData({
        rateSheetData: resp.data.data
      })
    );
  };
}

export function getRateSheetVersions(
  loanType: string,
  dataType: LoanRule,
  tpId?: string,
  activeVersion?: any
): any {
  return async (dispatch: any) => {
    const { OVERLAYS, RULE_VERSION } = LoanRule;
    const url =
      loanType === loanTypeName[0].displayValue
        ? `${getConfig().apiUrl}/ratesheet/bridge/${dataType}`
        : `${getConfig().apiUrl}/ratesheet/30year/${dataType}?tpId=${tpId}`;
    const resp = await publicClient.get(url);

    switch (dataType) {
      case OVERLAYS: {
        // dispatch({
        //   type: SET_OVERLAYS_VERSIONS,
        //   payload: resp.data.data
        // });
        dispatch(
          setOverlaysVersions({
            overlaysVersions: resp.data.data
          })
        );

        break;
      }
      case RULE_VERSION: {
        // dispatch({
        //   type: SET_VERSION_DATE,
        //   response: resp?.data?.data,
        //   activeVersion,
        //   dataType
        // });

        dispatch(
          setVersionDate({
            response: resp?.data?.data,
            activeVersion,
            dataType
          })
        );
        break;
      }
      default: {
        // dispatch({
        //   type: SET_RATE_SHEET_VERSIONS,
        //   payload: resp.data.data
        // });
        dispatch(
          setRateSheetVersions({
            rateSheetVersions: resp.data.data
          })
        );

        // dispatch({
        //   type: SET_VERSION_DATE,
        //   response: resp?.data?.data,
        //   activeVersion,
        //   dataType
        // });
        dispatch(
          setVersionDate({
            response: resp?.data?.data,
            activeVersion,
            dataType
          })
        );

        break;
      }
    }
  };
}

export function getAppraisalVendors(): any {
  return async (dispatch: any) => {
    try {
      dispatch(setLoading());

      // dispatch({
      //   type: GET_APPRAISAL_VENDORS_SUCCESS,
      //   payload: { appraisalVendors }
      // });
      dispatch(
        getAppraisalVendorsSuccess({
          appraisalVendors
        })
      );
    } catch (err) {
      // dispatch({ type: GET_APPRAISAL_VENDORS_FAILED, error: err });
      dispatch(
        loanTypeFailed({
          err
        })
      );
    }
  };
}

export function getLoanPurposes(): any {
  return async (dispatch: any) => {
    try {
      // dispatch({ type: GET_LOAN_PURPOSES_LOADING });
      dispatch(setLoading());

      // dispatch({
      //   type: GET_LOAN_PURPOSES_SUCCESS,
      //   payload: { loanPurposes }
      // });
      dispatch(
        getLoanPurposesSuccess({
          loanPurposes
        })
      );
    } catch (err) {
      //   dispatch({ type: GET_LOAN_PURPOSES_FAILED, error: err });
      // }
      dispatch(
        loanTypeFailed({
          err
        })
      );
    }
  };
}

export function validateForm(isSubmitValidation?: boolean): any {
  return async (dispatch: any) => {
    dispatch(
      validateFormRedux({
        validate: true,
        isSubmitValidation: isSubmitValidation || false
      })
    );
  };
}

export function errorsOnSubmit(error: any): any {
  return async (dispatch: any) => {
    dispatch(
      validateFormForSubmit({
        error
      })
    );
  };
}

// export function saveLoanDetailsForm(
//   loanDetails: Partial<{
//     loanType: LoanType;

//     borrowerInformation: { errors: number; payload: BorrowerInformation };
//     guarantorInformation: { errors: number; payload: GuarantorInformation };
//     loanInformation: { errors: number; payload: LoanInformation };
//     loanEconomics: { errors: number; payload: LoanEconomics };
//   }>
// ): any {
//   return { type: SAVE_LOAN_DETAILS_FORM, payload: loanDetails };
// }

// export function resetLoanDetails(): any {            //not getting used
//   return { type: RESET_LOAN_DETAILS, payload: {} };
// }

export function updatePropertyDetails(
  propertyId: string,
  propertyDetails: Partial<PropertyDetailsRepresentable>,
  position?: number
): any {
  return async (dispatch: any) => {
    // type: UPDATE_PROPERTY_DETAILS,
    // payload: { propertyId, propertyDetails, position }
    if (propertyId) {
      dispatch(
        updatePropertyDetailsRedux({ propertyId, propertyDetails, position })
      );
    }
  };
}

// export function updateThirtyYearPropertyDetails(
//   propertyId: string,
//   propertyDetails: Partial<ThirtyYearPropertyDetailsRepresentable>,
//   position?: number
// ): any {
//   return {
//     type: UPDATE_PROPERTY_DETAILS,
//     payload: { propertyId, propertyDetails, position }
//   };
// }

export function validateAddress(
  city: string = "",
  state: string = "",
  zipCode: string = ""
): any {
  return new Promise((resolve, reject) => {
    // if first digit is 0, during extraction it is storing only 4 digits. so adding 0 here.
    const zipcodeToUse = zipCode.length < 5 ? `0${zipCode}` : zipCode;
    const url = `https://us-zipcode.api.smartystreets.com/lookup?key=${getConfig().smartyStreetsApiKey
      }&city=${city}&state=${state}&zipcode=${zipcodeToUse}`;
    publicClient
      .get(url)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

export function updateThirtyYearLoanDetails(
  thirtyYearLoanDetails: Partial<ThirtyYearLoanDetailsRepresentable>
): any {
  // return {
  //   type: UPDATE_30_YEARS_LOAN_DETAILS,
  //   payload: { thirtyYearLoanDetails }
  // };
  return async (dispatch: any) => {
    dispatch(updateThirtyYearLoanDetailsRedux({ thirtyYearLoanDetails }));
  };
}

export function updateBridgeLoanDetails(bridgeLoanDetails: any): any {
  return async (dispatch: any) => {
    dispatch(updateBridgeLoanDetailsRedux({ bridgeLoanDetails }));
  };
}
export function updateBorrowerInfo(
  borrowerId: string,
  borrowerDetails: Partial<BorrowerInformation> | { [index: string]: any }
): any {
  return async (dispatch: any) => {
    {
      dispatch(updateBorrowerDetails({ borrowerId, borrowerDetails }));
    }
  };
}

export function updateFESBorrowerInfo(
  borrowerId: string,
  borrowerDetails: Partial<BorrowerInformation> | { [index: string]: any }
): any {
  return async (dispatch: any) => {
    {
      dispatch(updateFESBorrowerDetails({ borrowerId, borrowerDetail: borrowerDetails }));
    }
  };
}

// export function updatePrimaryBorrower(borrowerId: string): any {
//   return {
//     type: UPDATE_PRIMARY_BORROWER,
//     payload: { borrowerId }
//   };
// }
// export function primaryGuarantorOrBorrowerCheck(isMissing: boolean): any {
//   return {
//     type: MISSING_BORROWER_OR_GUARANTOR,
//     payload: { isMissing }
//   };
// }

export function editCommentMissing(isMissing: boolean): any {
  return async (dispatch: any) => {
    dispatch(missingEditComments({ isMissing }));
  };
}
export const deleteBorrower = (
  borrowerId: string,
  borrowerType?: string
): any => {
  return async (dispatch: any) => {
    let onlyClearValues = false;
    if (borrowerType) onlyClearValues = true;

    // dispatch({
    //   type: DELETE_BORROWER_DETAILS,
    //   payload: { borrowerId, borrowerType, onlyClearValues }
    // });
    dispatch(
      deleteBorrowerDetails({ borrowerId, borrowerType, onlyClearValues })
    );
  };
};
export function updateSummaryDetails(
  key: string,
  value: any,
  summaryObject?: boolean,
  dueDDParty?: boolean
): any {
  return async (dispatch: any) => {
    // return {
    //   type: UPDATE_SUMMARY_DETAILS,
    //   payload: {
    //     key,
    //     value,
    //     summaryObject,
    //     dueDDParty
    //   }
    // };
    dispatch(
      updateSummaryDetailsReducer({
        key,
        value,
        summaryObject,
        dueDDParty
      })
    );
  };
}
export function updateBridgeLoanGurantor(
  guarantorId: string,
  guarantorInformations: any
): any {
  return async (dispatch: any) => {
    dispatch(
      updateBridgeLoanGurantorRedux({
        guarantorId,
        guarantorInformation: guarantorInformations
      })
    );
  };
}
export const dispatchAndUpdate: any = (
  loanId: string,
  state: LoanStatusEnum,
  loanStage: LoanStage,
  dispatch: any,
  comment?: string,
  bySystem?: boolean,
  oldState?: LoanStatusEnum | null,
  statusUpdateCallback?: Function
) => {
  return async (dispatch: any) => {
    dispatch(showLoader());
    try {
      const body: { [key: string]: any } = {
        loanStage,
        loanState: state,
        comments: ""
      };
      if (comment) {
        body.comments = comment;
      }
      if (bySystem) {
        body.bySystem = bySystem;
      }
      await updateLoanStatusbyLoanId(loanId, body);
      if (statusUpdateCallback) {
        statusUpdateCallback(dispatch, loanId, loanStage);
      }
      const { Rejected, Approved, FinalReview } = LoanStatusEnum;
      if (
        state === LoanStatusEnum.InitialReview ||
        state === LoanStatusEnum.Submit ||
        state === LoanStatusEnum.DueDiligenceReview
      ) {
        dispatch(getWaiverList(loanId, loanStage, true));
      }
      if (state === LoanStatusEnum.Withdrawn) {
        const updateBoxUrl = `${getConfig().apiUrl
          }/documentaggregatorservice/updatefolder/LOAN/${loanId}?loanStage=${loanStage}`;
        publicClient.post(updateBoxUrl);
      }
      if (
        state === FinalReview &&
        oldState &&
        [Rejected, Approved].includes(oldState)
      ) {
        // for updating rate-sheet, lpp-8175
        globalGetDataByLoanID(loanId, loanStage);
      }
      // dispatch({
      //   type: UPDATE_LOAN_STATUS,
      //   payload: state
      // });
      dispatch(updateLoanStatusLoan(state));

      dispatch(hideLoader());
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
    }
  };
};

export function getExceptionGrade(
  loanId: string,
  loanStage: LoanStage,
  isFinalReview?: boolean
): any {
  return async (dispatch: any) => {
    let grade: string = "";
    const url = `${getConfig().apiUrl
      }/exceptionservice/${loanId}/grades?loanStage=${loanStage}`;
    const response = await publicClient.get(url);
    if (response.data) {
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].name.toUpperCase() === "SECURITIZATION_GRADE") {
          grade = response.data[i].value;
        }
        if (response.data[i].name.toUpperCase() === "WORKFLOW GRADE") {
          // dispatch({
          //   type: ADD_INTERNAL_GRADE,
          //   payload: {
          //     workflowGrade: response.data[i].value
          //   }
          // });
          dispatch(
            addInternalGrade({
              workflowGrade: response.data[i].value
            })
          );
          dispatch(setGradeRedux(response.data[i].value));
        }
      }
      // dispatch({
      //   type: ADD_EXCEPTION_GRADE,
      //   payload: {
      //     exceptionGrade: grade
      //   }
      // });

      dispatch(
        addExceptionGrade({
          exceptionGrade: grade
        })
      );
    }
  };
}

export function getDDId(
  userId: string,
  loanType: string,
  loanStage: string
): Promise<any> {
  return new Promise(async (resolve, reject) => {
    try {
      const loanTypeValue =
        loanType === loanTypeName[0].displayValue
          ? loanTypeName[0].loanCreationName
          : loanTypeName[1].loanCreationName;
      const url = `${getConfig().apiUrl
        }/aggregate/custom/ORIGINATOR_DD_LIST/value/fetch`;
      const response = await publicClient.post(url, {
        userId,
        loanType: loanTypeValue,
        loanStage,
        fetchDefault: true
      });
      const ddId = response.data?.length ? response.data[0].partyId : "NA";
      resolve(ddId);
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}
export function updateEditComments(
  loanId: string,
  commentData: any,
  isConversion: boolean = false
): any {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/audit/add/loans/${loanId}`;
    const response = await publicClient.post(url, commentData, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    // no need to have the get call, if updating audit service for loan conversion PRE => POST
    if (response.status === 200 && !isConversion) {
      dispatch(getLoanHistory(loanId));
    }
  };
}

export function getDDTeams(
  userId?: string,
  loanType?: string,
  loanStage?: string
): any {
  return async (dispatch: any) => {
    const loanTypeValue =
      loanType === loanTypeName[0].displayValue
        ? "Bridge Loan"
        : "30 Year Loan";
    const url = `${getConfig().apiUrl
      }/aggregate/custom/ORIGINATOR_DD_LIST/value/fetch`;
    const response = await publicClient.post(url, {
      userId,
      loanType: loanTypeValue,
      loanStage,
      fetchDefault: false
    });
    if (response.data?.length) {
      // dispatch({
      //   type: SET_DUE_DILIGENCE_INFO,
      //   payload: response.data
      // });
      dispatch(
        setDueDiligenceInfo({
          dueDiligenceInfo: response.data
        })
      );
    }
  };
}

// export function updateThirtyYearLoanGuarantor(
//   guarantorId: string,
//   guarantorInformations: any
// ) {
//   return {
//     type: UPDATE_THIRTY_YEAR_LOAN_GUARANTOR,
//     payload: {
//       guarantorId,
//       guarantorInformation: guarantorInformations
//     }
//   };
// }

export function updateFilesInDocs(file: any, currentTab: any) {
  return updateFilesInDocsRedux({
    file,
    currentSection: currentTab
  });
}

export function updateTaggedArrayInDocs(
  tags: any,
  filesList: any,
  currentTab: any
) {
  return updateTagsInDocs({
    tags,
    filesList,
    currentSection: currentTab
  });
  // type: UPDATE_TAGS_IN_DOCS,
  // payload: { tags, filesList },
  // currentSection: currentTab
}

export function updateUploadErrorInDocs(error: boolean, currentTab: any) {
  return updateFailureCountInDocs({
    error,
    currentSection: currentTab
  });
}

export function updateUploadSuccessNumberInDocs(
  success: boolean,
  noOfDeletedFiles: number,
  currentTab: any
) {
  return updateSuccessCountInDocs({
    success,
    noOfDeletedFiles,
    currentSection: currentTab
  });
}

// export function updateLoanGuarantorDetails(
//   i: number,
//   bridgeLoanDetails: Partial<BridgeLoanDetailsRepresentable>
// ) {
//   return {
//     type: UPDATE_BRIDGE_LOAN_DETAILS,
//     payload: { i, bridgeLoanDetails }
//   };
// }

// export function updatePrimaryGuarantor(
//   guarantorId: string,
//   guarantorInformations: any
// ) {
//   return {
//     type: UPDATE_PRIMARY_GUARANTOR,
//     payload: {
//       guarantorId,
//       guarantorInformation: guarantorInformations
//     }
//   };
// }

// export function updateThirtyYearPrimaryGuarantor(
//   guarantorId: string,
//   guarantorInformations: any
// ) {
//   return {
//     type: UPDATE_THIRTY_YEAR_LOAN_PRIMARY_GUARANTOR,
//     payload: {
//       guarantorId,
//       guarantorInformation: guarantorInformations
//     }
//   };
// }

export function deletePropertyDetails(
  loanId: string,
  loanStage: LoanStage,
  propertyId: string
): Promise<any> {
  const url = `${getConfig().apiUrl
    }/aggregate/loan_property/${loanId}/properties/${propertyId}?stage=${loanStage}`;
  return new Promise((resolve, reject) => {
    publicClient
      .delete(url)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

// export function updateDeletePropertyDetails(propertyId: string) {
//   return {
//     type: DELETE_PROPERTY_DETAILS,
//     payload: { propertyId }
//   };
// }

// export function updateDeleteThirtyYearPropertyDetails(propertyId: string) {
//   return {
//     type: DELETE_THIRTY_YEAR_PROPERTY_DETAILS,
//     payload: { propertyId }
//   };
// }

// export function deleteBridgeLoanGuarantor(guarantorId: string) {
//   return {
//     type: DELETE_BRIDGE_LOAN_GUARANTOR,
//     payload: { guarantorId }
//   };
// }

// export function deleteThirtyYearLoanGuarantor(guarantorId: string) {
//   return {
//     type: DELETE_THIRTY_YEAR_LOAN_GUARANTOR,
//     payload: { guarantorId }
//   };
// }

// export function setActivePropertyId(propertyId: string) {
//   return {
//     type: SET_ACTIVE_PROPERTY_ID,
//     payload: { propertyId }
//   };
// }
export function setActiveTabAndProperty(
  activeTab: string,
  propertyId?: string,
  callback?: Function
) {
  return setActiveTabAndPropertyRedux({
    activeTab,
    propertyId,
    callback
  });
}

export const openRescindSuccessDialog = (val: boolean) => {
  return rescindSuccessDialog({ val });
};
export function getBorrowerObjectForLoanSave(
  borrowerInformation: any,
  isSubmitted?: boolean
) {
  const borrowerObj: any = [];
  if (borrowerInformation?.length) {
    let latestBorrowerSequence: any = getCookie("latestBorrowerSequence");
    latestBorrowerSequence = latestBorrowerSequence
      ? Number(latestBorrowerSequence) ?? -1
      : -1;
    const loanUserSequenceMap = new Map();
    borrowerInformation.forEach(
      (element: BorrowerInformation, index: number) => {
        const {
          isPrimaryBorrower,
          borrowerEmail,
          borrowerType,
          borrowingEntityName,
          firstName,
          lastName,
          foreignNationalString,
          originalCreditScoreMedian,
          originalCreditReportDate,
          borrowerExperience,
          heavyRehabExperience,
          borrowerGUCExperience,
          billingAddress,
          billingPhoneNumber,
          borrowerLoanUserSequence,
          eiNumber,
          loanUserMapId,
          ssNumber,
          passportExpirationDate,
          partyId,
          city,
          state,
          pincode,
          locationValidationStatus
        } = element.payload;
        const experience: any = borrowerExperience ?? borrowerGUCExperience;
        const partyType = borrowerType === "Entity" ? "account" : "person";
        const accountName =
          borrowerType === "Entity" ? borrowingEntityName || "" : "";
        const accountType = borrowerType === "Entity" ? "BORROWER" : "person";
        const firstNameToSave =
          borrowerType === "Individual" ? firstName : borrowingEntityName;
        let creditDateValue: string | null = null;
        if (
          originalCreditReportDate &&
          !isNaN(new Date(originalCreditReportDate).getTime())
        ) {
          if (typeof originalCreditReportDate === "string") {
            creditDateValue = new Date(originalCreditReportDate).toISOString();
          } else
            creditDateValue = toISOLocalDateString(originalCreditReportDate);
        }
        if (creditDateValue) creditDateValue = creditDateValue.split("T")[0];
        let loanUserSequence = "";
        if (isSubmitted) {
          if (!borrowerLoanUserSequence) latestBorrowerSequence++;
          loanUserSequence =
            borrowerLoanUserSequence || latestBorrowerSequence.toString();
        } else {
          loanUserSequence = JSON.stringify(index);
        }
        let currVal = Number(loanUserSequence);

        if (loanUserSequenceMap.get(loanUserSequence) != null) {
          while (loanUserSequenceMap.get(loanUserSequence) != null) {
            currVal++;
            loanUserSequence = currVal.toString();
          }
        }
        loanUserSequenceMap.set(loanUserSequence, loanUserSequence);
        const borrowerInfo = {
          loanUserType: "Borrower",
          loanUserSequence,
          isPrimary: isPrimaryBorrower || false,
          originalCreditScoreMedian: isEmptyValue(originalCreditScoreMedian)
            ? null
            : originalCreditScoreMedian.toString(),
          originalCreditScoreReportDate: creditDateValue,
          experience: isEmptyValue(experience) && isNaN(+experience) ? null
            : typeof experience === "number" ? experience : Number(`${experience}`?.replace(/[-e]/g, '')),
          ownershipOfEntity: null,
          ...(partyId && {
            partyId
          }),
          ...(loanUserMapId && {
            loanUserMapId
          }),
          heavyRehabExperience: isEmptyValue(heavyRehabExperience)
            ? null
            : Number(heavyRehabExperience),
          borrowerGUCExperience: isEmptyValue(borrowerGUCExperience) && isNaN(Number(borrowerGUCExperience))
            ? null
            : Number(borrowerGUCExperience),
          customer: {
            accountName,
            accountType,

            addressList: [
              {
                partyAddressId: 1,
                addressNumber: "",
                addressType: "",
                addressLine1: billingAddress,
                addressLine2: "",
                addressLine3: "",
                city,
                state,
                country: "",
                pincode,
                locationValidationStatus
              }
            ],
            contactList: [
              {
                partyContactId: 1,
                contactType: "",
                contactNumber: billingPhoneNumber || "",
                email: borrowerEmail || null
              }
            ],
            dateOfBirth: null,
            firstName: firstNameToSave,
            middleName: null,
            lastName,
            partyType,
            foreignNationalString: foreignNationalString || null,
            ...(eiNumber && {
              eiNumber
            }),
            ...(ssNumber && {
              ssNumber
            }),
            ...(passportExpirationDate && {
              passportExpirationDate
            })
          }
        };
        borrowerObj.push(borrowerInfo);
      }
    );
  }
  return borrowerObj;
}

function getNonNaNVal(val: any) {
  if (isNaN(val)) {
    return null;
  }
  return val;
}
export function postLoan(
  createLoanParams: any,
  isDefaultCheck?: boolean,
  notExcelError?: boolean, // this has to be sent as true in case of submitted loans.Or else whole flow will break
  condoEligibSave?: boolean,
  calculatedCondo?: string,
  auditComments?: any
): Promise<any> {
  return new Promise((resolve, reject) => {
    const {
      loanId,
      loanType,
      borrowerInformation,
      fesLoanCondo,
      loanConfig,
      guarantorInfo,
      selectedFundingChannel
    } = createLoanParams;
    const url = `${getConfig().apiUrl}/aggregate/loans`;
    const borrowerInfo = getBorrowerObjectForLoanSave(
      borrowerInformation,
      notExcelError
    );
    const loanUserMap = [...borrowerInfo, ...guarantorInfo];
    let selectedRecourseValue: null | RecourseEnum = null;
    if (
      createLoanParams.selectedRecourse === "recourse-yes" ||
      createLoanParams.selectedRecourse === "Y" ||
      createLoanParams.selectedRecourse === RecourseEnum.fullRecourse
    ) {
      selectedRecourseValue = RecourseEnum.fullRecourse;
    } else if (
      createLoanParams.selectedRecourse === "recourse-no" ||
      createLoanParams.selectedRecourse === "N" ||
      createLoanParams.selectedRecourse === RecourseEnum.nonRecourse
    ) {
      selectedRecourseValue = RecourseEnum.nonRecourse;
    }

    let selectedCrossValue;
    if (createLoanParams.selectedCross === "Y") {
      selectedCrossValue = true;
    } else if (createLoanParams.selectedCross === "N") {
      selectedCrossValue = false;
    }
    let isWarehouseFunded = false;
    let tempSelectedFundingChannelValue;
    if (selectedFundingChannel === "Advance Funding") {
      tempSelectedFundingChannelValue = "advance_funding";
    } else if (selectedFundingChannel === "Table Funding") {
      tempSelectedFundingChannelValue = "table_funding";
    } else if (selectedFundingChannel === "Warehouse Funding") {
      tempSelectedFundingChannelValue = "normal";
      isWarehouseFunded = true;
    } else tempSelectedFundingChannelValue = "normal";
    let subordinateFinancing;
    if (createLoanParams.subordinateFinancing === "Yes") {
      subordinateFinancing = true;
    } else if (createLoanParams.subordinateFinancing === "No") {
      subordinateFinancing = false;
    }
    let businessPurposeOccupancy;
    if (createLoanParams.businessPurposeOccupancy === "Yes") {
      businessPurposeOccupancy = true;
    } else if (createLoanParams.businessPurposeOccupancy === "No") {
      businessPurposeOccupancy = false;
    }
    const noOfProperties =
      !isNullOrUndefined(createLoanParams?.numberOfProperties) &&
        createLoanParams?.numberOfProperties !== ""
        ? Number(createLoanParams.numberOfProperties)
        : null;
    const extensionOptions =
      !isNullOrUndefined(createLoanParams?.extensionOption) &&
        createLoanParams?.extensionOption !== ""
        ? Number(createLoanParams.extensionOption)
        : null;
    const { loanSummary, loanFees, achData } = createLoanParams;
    let loanSummaryObj;
    if (loanSummary) {
      const {
        updatedInsuranceEscrowBalance,
        updatedTaxEscrowBalance,
        monthlyTaxAmount,
        MonthlyInsuranceAmount,
        escrowTaxes,
        monthsOfTaxes,
        escrowInsurance,
        monthsOfInsurance,
        percentageStepUp,
        daysUntil,
        annualFloodInsurance
      } = loanSummary;
      loanSummaryObj = {
        ...loanSummary,
        percentageStepUp: getNonNaNVal(
          Number(percentageStepUp?.toString()?.replace("%", "")) ?? null
        ),
        daysUntil: !isEmptyValue(daysUntil) ? Number(daysUntil) : null,
        updatedTaxEscrowBalance:
          updatedTaxEscrowBalance?.replace("$", "") || null,
        updatedInsuranceEscrowBalance:
          updatedInsuranceEscrowBalance?.replace("$", "") || null,
        monthlyTaxAmount: monthlyTaxAmount?.replace("$", "") || null,
        MonthlyInsuranceAmount:
          MonthlyInsuranceAmount?.replace("$", "") || null,
        escrowTaxes: escrowTaxes?.replace("$", "") || null,
        monthsOfTaxes: monthsOfTaxes ? Number(monthsOfTaxes) : null,
        escrowInsurance: escrowInsurance?.replace("$", "") || null,
        monthsOfInsurance: monthsOfInsurance ? Number(monthsOfInsurance) : null,
        annualFloodInsurance: !isEmptyValue(annualFloodInsurance)
          ? annualFloodInsurance.toString()?.replace("$", "")
          : null
      };
    }
    const isRateTypeFixed = createLoanParams?.rateType === "Fixed";
    let requestObject: ObjectType = {
      loanId: loanId.toString(),
      loanTypeId: 2,
      loanType,
      loanEconomics: {
        financedInterestReserve:
          createLoanParams.financialInterestReserve?.replace("$", "") || null,
        originalLoanAmount:
          createLoanParams.loanAmount?.replace("$", "") || null,
        originalMaximumLoanAmount:
          createLoanParams.maxLoanAmount?.replace("$", "") || null,
        cutOffDateLoanAmount:
          createLoanParams.cutOffLoanAmount?.replace("$", "") || null,
        cutOffDateMaximumLoanAmount:
          createLoanParams.cutOffMaxLoanAmount?.replace("$", "") || null,
        interestRateAsOfCutOffDate: getNonNaNVal(
          Number(createLoanParams.interestRate?.toString()?.replace("%", "")) ?? null
        ),
        accrualType: createLoanParams.accrualType || null,
        totalBudgetAmount:
          createLoanParams.budgetAmount?.replace("$", "") || null,
        cashOutAmount: createLoanParams.cashOutAmount?.replace("$", "") || null,
        currentLoanBalance:
          createLoanParams.currentLoanBalance?.replace("$", "") || null,
        rateType: createLoanParams.rateType ? createLoanParams.rateType : null,
        armProductType: createLoanParams.armProductType
          ? createLoanParams.armProductType
          : null,
        armIndex: isRateTypeFixed
          ? null
          : createLoanParams.armIndex
            ? createLoanParams.armIndex
            : null,
        grossArmMargin: isRateTypeFixed
          ? null
          : createLoanParams.grossArmMargin?.toString()?.replace("%", "") ||
          null,
        interestOnlyPeriod: !isNullOrUndefined(
          createLoanParams.interestOnlyPeriod
        )
          ? parseInt(createLoanParams.interestOnlyPeriod, 10)
          : null,
        originalMonthlyPiPayment:
          createLoanParams.originalMonthlyPiPayment?.replace("$", "") || null,
        initialRateAdjustmentPeriod: isRateTypeFixed
          ? null
          : getNonNaNVal(
            parseInt(createLoanParams.initialRateAdjustmentPeriod, 10) ?? null
          ),
        initialPayAdjustmentPeriod: isRateTypeFixed
          ? null
          : getNonNaNVal(
            parseInt(createLoanParams.initialPayAdjustmentPeriod, 10) ?? null
          ),
        initialPeriodicCap: getNonNaNVal(
          Number(createLoanParams.initialPeriodicCap) ?? null
        ),
        lifeRateCap: isRateTypeFixed
          ? null
          : getNonNaNVal(
            createLoanParams.lifeRateCap?.toString()?.replace("%", "") ?? null
          ),
        subsequentPeriodicCap: getNonNaNVal(
          Number(createLoanParams.subsequentPeriodicCap) ?? null
        ),
        paymentAdjustmentFrequency: isRateTypeFixed
          ? null
          : getNonNaNVal(createLoanParams.paymentAdjustmentFrequency ?? null),
        rateAdjustmentFrequency: isRateTypeFixed
          ? null
          : getNonNaNVal(createLoanParams.rateAdjustmentFrequency ?? null),
        prepaymentPenaltyMonths: getNonNaNVal(
          parseInt(createLoanParams.prepaymentPenaltyMonths, 10) ?? null
        ),
        prepayPenaltyType: createLoanParams.prepayPenaltyType ?? null,
        initialInterestRateDown:
          createLoanParams?.initialInterestRateDown ?? null,
        initialInterestRateUp: createLoanParams?.initialInterestRateUp ?? null,
        subsequentInterestRateDown:
          createLoanParams?.subsequentInterestRateDown ?? null,
        subsequentInterestRateUp:
          createLoanParams?.subsequentInterestRateUp ?? null,
        armLookBackDays: createLoanParams?.armLookBackDays ?? null,
        armRoundFlag: createLoanParams?.armRoundFlag ?? null,
        armRoundingFactor: createLoanParams?.armRoundingFactor ?? null,
        costToLoanRatio: !isEmptyValue(createLoanParams.costToLoanRatio)
          ? Number(createLoanParams.costToLoanRatio?.toString()?.replace("%", ""))
          : null,
        debtServiceCoverageRatio: getNonNaNVal(
          Number(
            createLoanParams.debtServiceCoverageRatio?.toString()?.replace("%", "")
          ) ?? null
        ), // lpp-5116
        insuranceAndAmortization:
          createLoanParams.insuranceAndAmortization?.replace("$", "") || null,
        borrowerLiquidity:
          createLoanParams.borrowerLiquidity?.replace("$", "") || null,
        originalQualifyingPaymentMonthly:
          createLoanParams.originalQualifyingPaymentMonthly?.replace("$", "") ||
          null,
        qualifyingPaymentInReservesMonthly:
          createLoanParams.qualifyingPaymentInReservesMonthly
            ? parseInt(createLoanParams.qualifyingPaymentInReservesMonthly, 10)
            : null,
        lifetimeMinRate:
          createLoanParams.lifetimeMinRate?.toString()?.replace("%", "") ||
          null,
        lifetimeMaxRate: isRateTypeFixed
          ? null
          : createLoanParams.lifetimeMaxRate?.toString()?.replace("%", "") ||
          null,
        subordinateFinancing,
        businessPurposeOccupancy,
        financedBudgetAmount:
          createLoanParams.financedBudgetAmount?.replace("$", "") || null,
        totalOriginationAndDiscountPoints: getNonNaNVal(
          createLoanParams.discountPoints?.toString()?.replace("%", "") ?? null
        ),
        ...(createLoanParams.armRoundingFactor != null && {
          armRoundingFactor: createLoanParams.armRoundingFactor
        }),
        ...(createLoanParams.hoiDwellingCoverage != null && {
          hoiDwellingCoverage: createLoanParams.hoiDwellingCoverage
        }),
        ...(createLoanParams.floodInsuranceCoverage != null && {
          floodInsuranceCoverage: createLoanParams.floodInsuranceCoverage
        }),
        ...(createLoanParams.initialInterestRateUp != null && {
          initialInterestRateUp: createLoanParams.initialInterestRateUp
        }),
        ...(createLoanParams.initialInterestRateDown != null && {
          initialInterestRateDown: createLoanParams.initialInterestRateDown
        }),
        ...(createLoanParams.subsequentInterestRateUp != null && {
          subsequentInterestRateUp: createLoanParams.subsequentInterestRateUp
        }),
        ...(createLoanParams.subsequentInterestRateDown != null && {
          subsequentInterestRateDown:
            createLoanParams.subsequentInterestRateDown
        }),
        ...(createLoanParams.borrowerIsLLC != null && {
          borrowerIsLLC: createLoanParams.borrowerIsLLC
        }),
        ...(createLoanParams.hoaPaymentFrequency != null && {
          hoaPaymentFrequency: createLoanParams.hoaPaymentFrequency
        }),
        ...(createLoanParams.armRoundFlag != null && {
          armRoundFlag: createLoanParams.armRoundFlag
        }),
        ...(createLoanParams.lateCharge != null && {
          lateCharge: createLoanParams.lateCharge
        }),
        ...(createLoanParams.lateChargePercentage != null && {
          lateChargePercentage: createLoanParams.lateChargePercentage
        }),
        ...(createLoanParams.armLookBackDays != null && {
          armLookBackDays: getNonNaNVal(
            parseInt(createLoanParams.armLookBackDays, 10) ?? null
          )
        }),
        ...(createLoanParams.interestOnly != null && {
          interestOnly: createLoanParams.interestOnly
        }),
        ...(createLoanParams.grossAmountDueFromBorrowerHUD != null && {
          grossAmountDueFromBorrowerHUD:
            createLoanParams.grossAmountDueFromBorrowerHUD
        }),
        ...(createLoanParams.defaultInterestRateType != null && {
          defaultInterestRateType: createLoanParams.defaultInterestRateType
        }),
        includeOutOfPocketBudgetInARLTV:
          createLoanParams?.includeOutOfPocketBudgetInARLTV ?? true
      },
      loanDetailId: {
        fundingType: tempSelectedFundingChannelValue,
        isWarehouseFunded
      },
      loanInfo: {
        primaryLoanId: createLoanParams.primaryLoanID,
        loanStructure: createLoanParams.selectedLoanStructure || null,
        loanPurpose: createLoanParams.loanPurpose || null,
        cutOffDate: createLoanParams.cutOffDate,
        // pricingPaymentDueDate: createLoanParams.pricingPaymentDueDate,
        originationDate: createLoanParams.originationDate,
        loanSubmissionDate: createLoanParams.loanSubmissionDate,
        firstPaymentDateOfLoan: createLoanParams.firstPaymentDate,
        originalMaturityDate: createLoanParams.maturityDate,
        recourseString: selectedRecourseValue,
        toorakProduct: createLoanParams.selectedToorakProduct || null,
        pledgeOfEquity: false,
        crossCollaterlization: selectedCrossValue,
        extensionOptions,
        noOfProperties,
        condoEligibility: createLoanParams.condoEligibility || null,
        creditEvent: createLoanParams?.creditEvent || "No",
        calculatedCondoEligibility:
          createLoanParams.calculatedCondoEligibility || null,
        ...(createLoanParams.defaultRate !== null && {
          defaultRate: typeCastForReadOnly(
            createLoanParams.defaultRate,
            "percentage"
          )
        }),
        ...(createLoanParams.defaultRateCurable !== null && {
          defaultRateCurable: getNonNaNVal(
            Number(createLoanParams.defaultRateCurable) ?? null
          )
        })
      },
      loanUserMap,
      loanConfig: {
        ...loanConfig,
        sellerPartyId: createLoanParams.sellerPartyId
      },
      loanSummary: loanSummaryObj,
      loanCondominium: {}
    };
    if (loanFees != null && Object.keys(loanFees)?.length) {
      requestObject.loanFees = loanFees;
    }
    if (achData != null && Object.keys(achData)?.length) {
      requestObject.achData = achData;
    }

    if (condoEligibSave === true) {
      requestObject.loanInfo.calculatedCondoEligibility = calculatedCondo;
      requestObject.loanInfo.condoEligibility = calculatedCondo;
      requestObject.loanCondominium = {
        borrowerName: fesLoanCondo.borrowerName,
        monthlyHOAFee: fesLoanCondo?.monthlyHOAFee?.replace("$", ""),
        hoaDuesAboveFifteen:
          fesLoanCondo.hoaDuesAboveFifteen !== null
            ? fesLoanCondo.hoaDuesAboveFifteen === "Yes"
            : null,
        hoaDuesAboveTwenty:
          fesLoanCondo.hoaDuesAboveTwenty !== null
            ? fesLoanCondo.hoaDuesAboveTwenty === "Yes"
            : null,
        reserveFundsForOneYear:
          fesLoanCondo.reserveFundsForOneYear !== null
            ? fesLoanCondo.reserveFundsForOneYear === "Yes"
            : null,
        reserveFundsForTwoYears:
          fesLoanCondo.reserveFundsForTwoYears !== null
            ? fesLoanCondo.reserveFundsForTwoYears === "Yes"
            : null,
        isProjectCompleted:
          fesLoanCondo.isProjectCompleted !== null
            ? fesLoanCondo.isProjectCompleted === "Yes"
            : null,
        subjectToAdditionalPhasing:
          fesLoanCondo.subjectToAdditionalPhasing !== null
            ? fesLoanCondo.subjectToAdditionalPhasing === "Yes"
            : null,
        soldPercentage:
          fesLoanCondo.soldPercentage !== null
            ? fesLoanCondo?.soldPercentage?.replace("%", "") / 100 || null
            : null,
        hoaUnderOwnerControl:
          fesLoanCondo.hoaUnderOwnerControl !== null
            ? fesLoanCondo.hoaUnderOwnerControl === "Yes"
            : null,
        simpleEstateOwnership:
          fesLoanCondo.simpleEstateOwnership !== null
            ? fesLoanCondo.simpleEstateOwnership === "Yes"
            : null,
        ownerOccupiedUnits: Number(fesLoanCondo.ownerOccupiedUnits) || null,
        renterOccupiedUnits: Number(fesLoanCondo.renterOccupiedUnits) || null,
        renterOccupiedPercentage:
          fesLoanCondo.renterOccupiedPercentage !== null
            ? fesLoanCondo.renterOccupiedPercentage.replace("%", "") / 100 ||
            null
            : null,
        individualOwnershipAboveTwentyFive:
          fesLoanCondo.individualOwnershipAboveTwentyFive !== null
            ? fesLoanCondo.individualOwnershipAboveTwentyFive === "Yes"
            : null,
        masterAssurancePolicy:
          fesLoanCondo.masterAssurancePolicy !== null
            ? fesLoanCondo.masterAssurancePolicy === "Yes"
            : null,
        projectHasMobileHomes:
          fesLoanCondo.projectHasMobileHomes !== null
            ? fesLoanCondo.projectHasMobileHomes === "Yes"
            : null,
        otherSourcesIncomeMoreThanTwenty:
          fesLoanCondo.otherSourcesIncomeMoreThanTwenty !== null
            ? fesLoanCondo.otherSourcesIncomeMoreThanTwenty === "Yes"
            : null,
        commercialPurposesAreaMoreThanForty:
          fesLoanCondo.commercialPurposesAreaMoreThanForty !== null
            ? fesLoanCondo.commercialPurposesAreaMoreThanForty === "Yes"
            : null,
        restrictOwnerToRent:
          fesLoanCondo.restrictOwnerToRent !== null
            ? fesLoanCondo.restrictOwnerToRent === "Yes"
            : null,
        documentsWithSEC:
          fesLoanCondo.documentsWithSEC !== null
            ? fesLoanCondo.documentsWithSEC === "Yes"
            : null,
        hoawithLitOrBankruptcy:
          fesLoanCondo.hoawithLitOrBankruptcy !== null
            ? fesLoanCondo.hoawithLitOrBankruptcy === "Yes"
            : null,
        ownerControlStartDate:
          fesLoanCondo.ownerControlStartDate !== ""
            ? fesLoanCondo.ownerControlStartDate
            : null,
        indivdualOwnedUnits: Number(fesLoanCondo.indivdualOwnedUnits),
        hoawithLitOrBankruptcyDesc: fesLoanCondo.hoawithLitOrBankruptcyDesc,
        restrictOwnerToRentOutDesc: fesLoanCondo.restrictOwnerToRentOutDesc,
        condoProjectName: fesLoanCondo.condoProjectName,
        streetAddressLine1: fesLoanCondo.streetAddressLine1,
        noOfUnits: Number(fesLoanCondo.noOfUnits),
        state: fesLoanCondo.state,
        city: fesLoanCondo.city,
        postalCode: fesLoanCondo.postalCode
      };
    }
    if (!notExcelError) {
      requestObject = {
        ...requestObject,
        ...{
          loanState: LoanStatusEnum.ExcelError
        }
      };
    }
    if (auditComments) {
      let result: { [key: string]: string } = {};
      // Check if auditComments is coming in the array of objects used for audit service and transform
      if (
        Array.isArray(auditComments) &&
        auditComments.every(
          (item) =>
            typeof item === "object" &&
            "comment" in item &&
            "field" in item
        )
      ) {
        auditComments.forEach((item: InputItem) => {
          result[item.field] = item.comment;
        });
      } else {
        result = auditComments;
      }
      requestObject.auditComments = result;
    }
    // to get the request object
    if (isDefaultCheck) {
      return resolve(requestObject);
    }

    publicClient
      .post(url, requestObject, {
        errorConfig: getErrorConfigObject({
          errorPriority: notExcelError
            ? ErrorPriority.medium
            : ErrorPriority.low,
          customErrorMessage: {
            heading: errorMessageJson.postLoan.heading,
            body: notExcelError
              ? errorMessageJson.postLoan.message
              : errorMessageJson.postLoanAutoSave.message
          }
        })
      } as any)
      .then((res: { data: any }) => {
        const foo = JSON.parse(JSON.stringify(res.data));
        sessionStorage.setItem(
          "originatorPartyIdNew",
          foo?.loanDetailId?.originatorPartyId
        );
        resolve(res.data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
}

export const postLoanAutoSave: any = (
  impactingFieldChanged: boolean = false,
  isEvaluationPanelVisible: boolean,
  loanId: string,
  loanStage: LoanStage,
  loanType: string,
  data: any,
  propertyDetails: any,
  loanEvaluationResult: any,
  loanConfig: any
) => {
  return async (dispatch: any) => {
    if (!isEvaluationPanelVisible) {
      try {
        postLoan({
          loanId,
          loanType,
          loanConfig,
          guarantorInfo: getBridgeLoanGuarantorInformationToPass(
            data.guarantorInformation
          ),
          borrowerInformation: data.borrowerInformation,
          ...data.loanEconomics,
          ...data.loanInformation
        })
          .then((response: CreateLoanParamsBridgeLoan) => {
            // dispatch({
            //   type: POST_LOAN_SUCCESS,
            //   payload: {
            //     reservation: response
            //   }
            // });
            dispatch(
              postLoanSuccess({
                reservation: response
              })
            );

            if (impactingFieldChanged) {
              getEvaluationRequestBody(
                loanId,
                loanStage,
                data,
                loanEvaluationResult,
                propertyDetails,
                loanType
              )
                .then(({ evaluate, loanEvaluationRequest }) => {
                  if (evaluate && loanEvaluationRequest) {
                    dispatch(
                      evaluateLoan(loanEvaluationRequest, loanId, loanStage)
                    );
                  }
                })
                .catch((e) => {
                  dispatch(setAutoSaveCall(false));
                  console.error("error while sending evaluation request", e);
                });
            } else {
              dispatch(setAutoSaveCall(false));
            }
          })
          .catch((error) => {
            dispatch(setAutoSaveCall(false));
            console.error(error);
            // dispatch({ type: POST_LOAN_FAILED, error });
            dispatch(
              loanTypeFailed({
                error
              })
            );
          });
      } catch (error) {
        dispatch(setAutoSaveCall(false));
        console.error(error);
      }
    } else {
      dispatch(setAutoSaveCall(false));
    }
  };
};

function isFalsyButNotZero(value: any) {
  return !value && value !== 0;
}

export function postPropertyData(
  loanId: string,
  loanStage: LoanStage,
  propertyDetails: any,
  propertyOrder?: number | null,
  isDefaultCheck?: boolean,
  isSubmit?: boolean, // if this function is getting called in any state after submit, this field has to be sent as true. or else flow will break.
  skipAudit?: boolean,
  auditComments?: any
): Promise<any> {
  const properties: any[] = [];
  propertyDetails.forEach((item: any, index: number) => {
    const propertyPosition = propertyOrder || index + 1;
    const {
      changeInUseCase,
      numberOfUnits,
      propertyType,
      propertyValuationDate,
      // originalPropertyValuationDate is alias of propertyValidationDate - new field used only in TTF
      originalPropertyValuationDate,
      propertyAcquisitionDate,
      preRehabSquareFootage,
      postRehabSquareFootage,
      isPropertyPurchasedLast2Years,
      squareFootage,
      toorvalId
    } = item?.propertyInformation?.payload || {};
    const changeInUseCaseValue =
      (["N", "No", false].includes(changeInUseCase) ? false : ["Y", "Yes", true].includes(changeInUseCase) ? true : null ) ?? null;
    const occupiedPercent =
      item.propertyInformation.payload.percentageOccupied ||
      item.propertyInformation.payload.occupiedPercentage;
    const percentageOccupiedValue = occupiedPercent
      ? Number(occupiedPercent.replace("%", ""))
      : null;
    const rentalCashflowRatioValue = item.propertyEconomics.payload
      .rentalCashflowRatio
      ? Number(
        item.propertyEconomics.payload.rentalCashflowRatio.replace("%", "")
      )
      : null;
    let numberOfUnitsValue: null | number = null;
    if (!isNullOrUndefined(numberOfUnits) && numberOfUnits !== "") {
      numberOfUnitsValue = getNumFromCommaSeparatedNum(numberOfUnits);
    }
    let propertyTypeValue = null;
    if (propertyType && propertyType !== "") {
      propertyTypeValue = propertyType;
    }
    let addressValueToPass = "";
    if (item.propertyLocation.payload) {
      const { address } = item.propertyLocation.payload;
      if (address && typeof address === "object") {
        addressValueToPass = address.street_line;
      } else if (address && address !== "") {
        addressValueToPass = address;
      }
    }
    const propertyUnits: any = [];
    item.unitsInformation.forEach(
      (
        ele: {
          errors: number | null;
          unitId: number;
          payload: MultiplUnitInfo;
        },
        indx: number
      ) => {
        const percentageOccupied = percentageOccupiedFormula({
          leaseStatus: ele.payload.leaseStatus
        });
        propertyUnits.push({
          propertyUnitId: ele.unitId || null,
          propertyUnitOrder: `${indx + 1}`,
          leaseStatus: ele.payload.leaseStatus,
          noOfBathrooms: "",
          noOfBedrooms: "",
          currentLeaseTermMonthly: ele.payload.currentLeaseTerm
            ? Number(ele.payload.currentLeaseTerm)
            : null,
          marketRentMonthly: ele.payload.marketRentMonthly
            ? ele.payload.marketRentMonthly.replace("$", "").replace(/,/g, "")
            : null,
          thirdPartyRentRangeAmount: ele.payload.thirdPartyRentRangeAmount
            ? ele.payload.thirdPartyRentRangeAmount.replace("$", "").replace(/,/g, "")
            : null,
          inPlaceLeaseRentMonthly: ele.payload.inPlaceLeaseRent
            ? ele.payload.inPlaceLeaseRent?.replace("$", "").replace(/,/g, "")
            : null,
          mostRecentLeaseRentMonthly: ele.payload.recentLeaseRent
            ? ele.payload.recentLeaseRent?.replace("$", "").replace(/,/g, "")
            : null,
          percentageOccupied: percentageOccupied
            ? percentageOccupied?.replace("%", "").replace(/,/g, "")
            : null
        });
      }
    );
    const { propertySummary } = item;
    let propertySummaryObj;
    if (propertySummary) {
      const {
        monthlyTaxAmount,
        monthlyInsuranceAmount,
        escrowTaxes,
        monthsOfTaxes,
        escrowInsurance,
        monthsOfInsurance
      } = propertySummary;
      propertySummaryObj = {
        ...propertySummary,
        monthlyTaxAmount: monthlyTaxAmount?.replace("$", "") || null,
        monthlyInsuranceAmount:
          monthlyInsuranceAmount?.replace("$", "") || null,
        escrowTaxes: escrowTaxes?.replace("$", "") || null,
        monthsOfTaxes: monthsOfTaxes ? Number(monthsOfTaxes) : null,
        escrowInsurance: escrowInsurance?.replace("$", "") || null,
        monthsOfInsurance: monthsOfInsurance ? Number(monthsOfInsurance) : null
      };
    }

    let propertyObjToPass: any = {
      loanId,
      loanPropertyId: Number(item.propertyId),
      loanPropertyOrder: propertyPosition,
      propertyinfo: {
        propertyUnits: numberOfUnitsValue,
        toorvalId,
        propertyType: propertyTypeValue,
        recentPropertyValuationDate: propertyValuationDate,
        // originalPropertyValuationDate is alias of propertyValidationDate - new field used only in TTF
        propertyValuationDate: originalPropertyValuationDate,
        propertyAcquisitionDate,
        changeInUseCase: changeInUseCaseValue,
        isPropertyPurchasedLast2Years: isPropertyPurchasedLast2Years || null,
        squareFootage: squareFootage
          ? getNumFromCommaSeparatedNum(squareFootage)
          : null,
        preRehabSquareFootage: !isFalsyButNotZero(preRehabSquareFootage)
          ? getNumFromCommaSeparatedNum(preRehabSquareFootage)
          : null,
        postRehabSquareFootage: postRehabSquareFootage
          ? getNumFromCommaSeparatedNum(postRehabSquareFootage)
          : null,
        occupiedPercentage: percentageOccupiedValue,
        ...(item.propertyInformation.payload.collateralId != null && {
          collateralId: item.propertyInformation.payload.collateralId
        }),
        ...(item.propertyInformation.payload.appraisalType != null && {
          appraisalType: item.propertyInformation.payload.appraisalType
        }),
        ...(item.propertyInformation.payload.appraisalVendor != null && {
          appraisalVendor: item.propertyInformation.payload.appraisalVendor
        }),
        ...(item.propertyInformation.payload.appraisalCertificationOrLicense !=
          null && {
          appraisalCertificationOrLicense:
            item.propertyInformation.payload.appraisalCertificationOrLicense
        }),
        ...(item.propertyInformation.payload.appraisalExpirationDate !=
          null && {
          appraisalExpirationDate:
            item.propertyInformation.payload.appraisalExpirationDate
        }),
        ...(item.propertyInformation.payload.appraisalState != null && {
          appraisalState: item.propertyInformation.payload.appraisalState
        }),
        ...(item.propertyInformation.payload.propertyValuationType != null && {
          propertyValuationType:
            item.propertyInformation.payload.propertyValuationType
        }),
        ...(item.propertyInformation.payload.preRehabUnits != null && {
          preRehabUnits: parseInt(
            item.propertyInformation.payload.preRehabUnits,
            10
          )
        }),
        ...(item.propertyInformation.payload.postRehabUnits != null && {
          postRehabUnits: parseInt(
            item.propertyInformation.payload.postRehabUnits,
            10
          )
        })
      },
      propertyLocation: {
        addressLine1: addressValueToPass,
        addressLine2: "",
        city: item.propertyLocation.payload.city,
        state: item.propertyLocation.payload.state,
        postalCode: item.propertyLocation.payload.zipCode,
        country: "",
        locationValidationStatus:
          item.propertyLocation.payload.locationValidationStatus
      },
      propertyEconomics: {
        appraisalReportedNoiNcf:
          item.propertyEconomics.payload.appraisalReported?.replace("$", "") ||
          null,
        originalAsIsAppraisalValue:
          item.propertyEconomics.payload.originalAppraisalValue?.replace(
            "$",
            ""
          ) || null,
        thirdPartyValuation:
          item.propertyEconomics.payload.thirdPartyValuation?.replace(
            "$",
            ""
          ) || null,
        originalAsRepairedAppraisedValue:
          item.propertyEconomics.payload.originalRepairedAppraisalValue?.replace(
            "$",
            ""
          ) || null,
        purchasePrice:
          item.propertyEconomics.payload.purchasePrice?.replace("$", "") ||
          null,
        costBasis:
          item.propertyEconomics.payload.costBasis?.replace("$", "") || null,
        grossPotentialRent:
          item.propertyEconomics.payload.grossPotentialRent?.replace("$", "") ||
          null,
        assignmentFees:
          item.propertyEconomics.payload.assignmentFees?.replace?.("$", "") ||
          null,
        annualPropertyTaxes:
          item.propertyEconomics.payload.annualPropertyTaxes?.replace(
            "$",
            ""
          ) || null,
        insurance:
          item.propertyEconomics.payload.insurance?.replace("$", "") || null,
        rentalCashflowRatio: rentalCashflowRatioValue,
        annualHazardInsurance:
          item.propertyEconomics.payload.annualHazardInsurance?.replace(
            "$",
            ""
          ) || null,
        annualFloodInsurance:
          item.propertyEconomics.payload.annualFloodInsurance?.replace(
            "$",
            ""
          ) || null,
        annualHoaFee:
          item.propertyEconomics?.payload?.annualHoaFee?.replace("$", "") || item.propertyEconomics?.payload?.annualHOAFee?.replace("$", "") || null,
        decliningMarkets:
          item?.propertyEconomics?.payload?.decliningMarkets || "No"
      },
      propertyUnit: propertyUnits,
      propertySummary: propertySummaryObj
    };
    if (skipAudit) {
      propertyObjToPass = {
        ...propertyObjToPass,
        "auditContext": {
          "skipAudit": true
        }
      }
    }

    if (auditComments) {
      let result: { [key: string]: string } = {};
      // Check if auditComments is coming in the array of objects used for audit service and transform
      if (Array.isArray(auditComments) && auditComments.every(item =>
        typeof item === 'object' &&
        'comment' in item &&
        'field' in item
      )) {
        auditComments.forEach((item: InputItem) => {
          if (item.field.startsWith(`data.properties[${index + 1}]`)) {
            result[item.field] = item.comment;
          }
        });
      }
      if (Object.keys(result)?.length) {
        propertyObjToPass = {
          ...propertyObjToPass,
          auditContext: {
            auditComments: result
          }
        };
      }
    }
    if (!isSubmit && loanStage !== LoanStage.fes) {
      propertyObjToPass = {
        ...propertyObjToPass,
        ...{ hasErrors: true },
        ...{ isPropertyPurchasedLast2Years }
      };
    } else {
      propertyObjToPass = {
        ...propertyObjToPass,
        ...{ hasErrors: false }
      };
    }
    properties.push(propertyObjToPass);
  });

  // to get the request object
  if (isDefaultCheck) {
    return new Promise((resolve, reject) => {
      resolve(properties);
    });
  }

  const url = `${getConfig().apiUrl
    }/aggregate/loan_property/${loanId}/properties?stage=${loanStage}`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, properties, {
        errorConfig: getErrorConfigObject({ skipErrorHandling: true })
      } as any)
      .then((res: any) => resolve(res))
      .catch((e: any) => reject(e));
  });
}

// ------------------DOCS UPLOAD APIs START------------------------------------------------

export function getPreAssignedURL(
  body: preAssignedURL,
  contentType: string,
  token?: any
): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/cloudfileupload`;
    const header = {
      headers: { "Content-Type": contentType },
      cancelToken: token,
      errorConfig: getErrorConfigObject({ skipErrorHandling: true })
    };
    publicClient
      .post(url, body, header)
      .then((res: any) => resolve(res.data))
      .catch((e: any) => {
        if (isCancel(e)) {
          console.error("req cancelled", e);
          reject({ error: "Request cancelled" });
        } else {
          reject();
        }
      });
  });
}

const apiUrlHost =
  window.location.hostname === "dev.toorakcapital.info" ||
    window.location.hostname === "localhost"
    ? "https://api-dev.toorakcapital.info"
    : getConfig().apiUrl;

export function docSelfServe(
  s3Url: any,
  destFolder: any,
  submitCallBack: any
): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    try {
      const url = `${apiUrlHost}/copyinbox`;
      const reqBody = {
        s3_url: s3Url,
        destination_folder_id: destFolder
      };
      const response = await publicClient.post(url, reqBody);
      if (response) {
        submitCallBack(response?.data);
      }
      dispatch(hideLoader());
    } catch (err) {
      dispatch(hideLoader());
      submitCallBack(err);
      console.error("error", err);
    }
  };
}

export function previewUpload(body: preAssignedURL): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/cloudfileupload`;
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

export function postRateLock(body: any[]): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/aggregate/loans/rateLock`;
    publicClient
      .post(url, body)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch(reject);
  });
}

export function insertDocs(
  body: any,
  primaryIdenValue: string,
  secondaryIden: string = "",
  secondaryIdenValue: string = "",
  primaryIdentifier: string = "",
  fromCondition?: boolean,
  hasInquiry?: boolean
): Promise<string> {
  return new Promise((resolve, reject) => {
    if (primaryIdentifier === "CHAT") {
      if (hasInquiry) {
        // AM-261
        body?.forEach((item: any) => (item.chatDocument = "false"));
      } else {
        body?.forEach((item: any) => (item.chatDocument = "true"));
      }
    } else {
      body?.forEach((item: any) => (item.chatDocument = null));
    }
    let url = `${getConfig().apiUrl
      }/docs/${primaryIdentifier}/${primaryIdenValue}/documents`;
    if (secondaryIden) {
      url += `?secondary_identifier_name=${secondaryIden}&secondary_identifier_value=${secondaryIdenValue}`;
    }
    const header = {
      headers: { "Content-Type": "text/plain" }
    };
    publicClient
      .post(url, body, header)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function uploadAWSFile(
  data: FormData,
  config: any,
  url: any,
  token?: any
) {
  return new Promise((resolve, reject) => {
    const header = {
      onUploadProgress: config.onUploadProgress || null,
      cancelToken: token
    };
    url.url
      ? publicClient
        .put(url.url, data, header)
        .then((res: { data: string | PromiseLike<string> }) => {
          resolve(res);
        })
        .catch((e: any) => {
          if (isCancel(e)) {
            console.error("req cancelled", e);
            reject({ error: "Request cancelled" });
          } else {
            reject();
          }
        })
      : reject({ status: 400, Error: "Bad Data" });
  });
}
export function uploadAWSFilePreview(url: any) {
  return new Promise((resolve, reject) => {
    url.url
      ? publicClient
        .put(url.url)
        .then((res: any) => {
          resolve(res);
        })
        .catch(reject)
      : reject({ status: 400, Error: "Bad Data" });
  });
}

export function deleteTagsFile(req: any) {
  const url = `${getConfig().apiUrl}/deletetags/documents/${req.docsId
    }/tags?tagCode=${req.code}`;
  return new Promise((resolve, reject) => {
    publicClient
      .delete(url)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function deleteInternalTag(docId: any, tagCode: any, csScoreObj?: any) {
  const url = `${getConfig().apiUrl
    }/deleteComprehendTags/documents/${docId}/tags?tagCode=${tagCode}`;
  return new Promise((resolve, reject) => {
    publicClient
      .delete(url)
      .then((res: any) =>
        resolve({
          res,
          csScoreObj
        })
      )
      .catch(reject);
  });
}

export function addTagsFile(
  req: any,
  body: any,
  removeTaggedFiles: boolean = false
) {
  let url: string = `${getConfig().apiUrl}/addtags/documents/${req.docsId
    }/tags`;
  if (removeTaggedFiles) {
    url = `${url}?removeTaggedFiles=true`;
  }
  const header = {
    headers: { "Content-Type": "text/plain" }
  };
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, body, header)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

// export function updatePercentageInDocs(
//   uniqueId: any,
//   percentage: number,
//   currentTab: any
// ) {
//   return {
//     type: UPDATE_PERCENTAGE_IN_DOCS,
//     payload: { uniqueId, percentage },
//     currentSection: currentTab
//   };
// }

export function deleteUploadedFile(
  docId: string,
  defaultIden: string = "LOAN",
  loanId: string = ""
) {
  const url = `${getConfig().apiUrl
    }/documentaggregatorservice/deletedoc/${defaultIden}/${loanId}/documents/${docId}`;
  return new Promise((resolve, reject) => {
    publicClient
      .delete(url)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function downloadFile(
  body: preAssignedURL,
  fileName: string
): Promise<string> {
  return new Promise(async (resolve, reject) => {
    const url = `${getConfig().apiUrl}/cloudfiledownload`;
    const awsUrl = await publicClient.post(url, body);
    if (awsUrl.data.url) {
      const { data } = await publicClient.get(awsUrl.data.url, {
        responseType: "blob"
      });
      resolve(clickAndDownload(data, fileName));
    } else {
      reject(awsUrl);
    }
  });
}
export function previewDownload(body: preAssignedURL): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/cloudfiledownload`;
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}
export const clickAndDownloadFile = (awsUrl: string, fileName: string) => {
  return new Promise(async (resolve, reject) => {
    const { data } = await publicClient.get(awsUrl, {
      responseType: "blob"
    });
    resolve(clickAndDownload(data, fileName));
  });
};
async function addFileToZip(zip: JSZip, fileUrl: string, fileName: string) {
  const response = await publicClient.get(fileUrl, {
    responseType: "arraybuffer"
  });
  zip.file(fileName, response.data);
}

export async function downloadAsZip(
  responseArr: any,
  lookUpObj: any,
  zipName: string,
  dispatch?: any
) {
  // Create a new JSZip instance
  const zip = new JSZip();
  // Promise array to track all file downloads
  const promises: Promise<void>[] = [];
  const fileNameLookupObject: any = {};
  // Loop through each file URL
  responseArr.forEach((item: any, index: number) => {
    let fileNameWithExtension = lookUpObj[index].name;
    const lastDotIndex = fileNameWithExtension.lastIndexOf(".");
    let fileName = fileNameWithExtension.substring(0, lastDotIndex); // Extracting the filename without extension
    const extension = fileNameWithExtension.substring(lastDotIndex); // Extracting the extension
    let count = fileNameLookupObject[fileNameWithExtension] ?? 0; // Get the current count of the filename or initialize to 0
    // Check if the filename already exists
    while (fileNameLookupObject[fileNameWithExtension] !== undefined) {
      count++; // Increment the count
      fileNameWithExtension = `${fileName} (${count})${extension}`; // Append count to filename
    }
    // Update fileNameLookupObject with the new filename and count
    fileNameLookupObject[fileNameWithExtension] = count;
    promises.push(addFileToZip(zip, item?.url, fileNameWithExtension));
  });
  // Wait for all files to be added to the zip
  Promise.all(promises)
    .then(() => {
      // Generate the zip file asynchronously
      return zip.generateAsync({ type: "blob" });
    })
    .then((content) => {
      // Trigger download of the zip file
      saveAs(content, `${zipName}.zip`);
      dispatch && dispatch(hideLoader());
      console.log("Zip file created successfully!");
    })
    .catch((err) => {
      dispatch && dispatch(hideLoader());
      console.error("Error:", err);
    });
}
export function getUnzipFiles(body: preAssignedURL) {
  return new Promise(async (resolve, reject) => {
    const url = `${getConfig().apiUrl}/aggregate/documentservice/unzip`;

    try {
      const res = await publicClient.post(url, body); // Making the req
      resolve(res.data);
    } catch (e) {
      const err: any = e;
      if (err.response && err.response.data) {
        console.error(err.response.data?.message); // some reason error message
        reject(err.response.data?.message);
      }
    }
  });
}

export function downloadFileNew(
  fileName: string,
  docsId: any,
  primaryIdentifier: string,
  primaryIdenValue: string,
  secondaryIden: string = "",
  secondaryIdenValue: string = "",
  forPreview: boolean = false
): Promise<string> {
  return new Promise(async (resolve, reject) => {
    let url = `${getConfig().apiUrl
      }/documentaggregatorservice/documentdetails/${primaryIdentifier}/${primaryIdenValue}/documents/${docsId}/download`;
    if (secondaryIden && secondaryIdenValue) {
      url += `?secondary_identifier_name=${secondaryIden}&secondary_identifier_value=${secondaryIdenValue}`;
    }
    const awsUrl = await publicClient.post(url);
    if (awsUrl?.data?.url) {
      const { data } = await publicClient.get(awsUrl.data.url, {
        responseType: "blob"
      });
      resolve(forPreview ? awsUrl : clickAndDownload(data, fileName));
    } else {
      reject(awsUrl);
    }
  });
}

export function awsNewUrlExtract(
  fileName: string,
  docsId: any,
  primaryIdentifier: string,
  primaryIdenValue: string,
  secondaryIden: string = "",
  secondaryIdenValue: string = "",
  callBack: any,
  event?: any,
  index?: any
): Promise<string> {
  return new Promise(async (resolve, reject) => {
    let url = `${getConfig().apiUrl
      }/documentaggregatorservice/documentdetails/${primaryIdentifier}/${primaryIdenValue}/documents/${docsId}/download`;
    if (secondaryIden && secondaryIdenValue) {
      url += `?secondary_identifier_name=${secondaryIden}&secondary_identifier_value=${secondaryIdenValue}`;
    }
    const awsUrl = await publicClient.post(url);
    if (awsUrl) {
      resolve(event ? callBack(awsUrl, event, index) : callBack(awsUrl));
    } else {
      reject(awsUrl);
    }
  });
}

export function clickAndDownload(data: any, fileName: string) {
  const downloadUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", fileName); // any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
  return data;
}

export function uploadIntoBox(
  pIden: string,
  pIdenVal: string,
  sIden: string = "",
  sIdenVal: string = ""
) {
  return new Promise((resolve, reject) => {
    let url: string = `${getConfig().apiUrl
      }/uploaddocumentintobox/${pIden}/${pIdenVal}`;
    if (sIden && sIdenVal)
      url += `?secondary_identifier_name=${sIden}&secondary_identifier_value=${sIdenVal}`;

    publicClient
      .post(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
export function exportLoanToExcel(
  loanId: string,
  loanStage: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/documentaggregatorservice/${loanId}/datatape?loanStage=${loanStage}`;
    publicClient
      .get(url)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

// ---------------------------DOCS UPLOAD APIs END------------------------------------
//-----------------------------------------------------------------------------------

export function getLoanType(): any {
  return async (dispatch: any) => {
    try {
      // dispatch({ type: LOAN_TYPE_LOADING });
      dispatch(setLoading());

      const url = `${getConfig().apiUrl}/loans/lookup/loantypes`;
      const response = await publicClient.get(url);
      // dispatch({
      //   type: LOAN_TYPE_SUCCESS,
      //   payload: {
      //     loanTypes: response.data
      //   }
      // });
      dispatch(
        loanTypeSuccess({
          loanTypes: response.data
        })
      );
    } catch (err) {
      const e: any = err;
      // dispatch({
      //   type: LOAN_TYPE_FAILED,
      //   error: {
      //     message: e?.message
      //   }
      dispatch(
        loanTypeFailed({
          error: {
            message: e?.message
          }
        })
      );
    }
  };
}

export function addNewLender(body: any) {
  const url = `${getConfig().apiUrl}/aggregate/custom/WAREHOUSE_LENDER`;

  return new Promise((resolve, reject) => {
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}
export function getUserNameAndPartyType(partyId: any) {
  const url = `${getConfig().apiUrl}/aggregate/customer/${partyId}`;

  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function getUserName(partyId: any) {
  const url = `${getConfig().apiUrl}/customer/${partyId}`;

  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function editCustomer(body: any, partyId: any) {
  const url = `${getConfig().apiUrl}/customer/${partyId}`;

  return new Promise((resolve, reject) => {
    publicClient
      .put(url, body)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function getOriginatorNames() {
  const url = `${getConfig().apiUrl}/access/roles`;

  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function getLoanId(
  loanTypeId: number,
  loanTypeName: string,
  loanStage: LoanStage,
  originatorPartyId: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/aggregate/loans/create`;
    const body: any = {
      loanTypeId,
      originatorPartyId,
      loanType: loanTypeName,
      stage: loanStage,
      loanOrigin: "UI/TC",
      fundingType: "normal"
    };
    publicClient
      .post(url, body)
      .then((res: { data: { toorakLoanId: string | PromiseLike<string> } }) =>
        resolve(res.data.toorakLoanId)
      )
      .catch(reject);
  });
}
export function getAutoComplete(column: any, value: string, keyName: string) {
  let quaryObj: any = {};
  const mustObj = {
    nested: {
      path: "borrowers",
      query: {
        prefix: {
          "borrowers.firstName.raw": {
            value
          }
        }
      }
    }
  };
  if (isLATUser()) {
    quaryObj = {
      bool: {
        must: mustObj,
        must_not: {
          terms: {
            "loan.loanState.raw": [LoanStatusEnum.ExcelError]
          }
        }
        // removed LoanStatusEnum.DataEntry from loan.loanState.raw in must_not object so that LAT user can also view loan with loan status as 'DATA ENTRY'
      }
    };
  } else {
    quaryObj = mustObj;
  }

  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${getConfig().apiUrl}/search/${getConfig().aggregateUrl
        }/_search`;
      const scriptFields: any = {};
      scriptFields.loanDynamicStatus = {
        script: {
          source:
            "if(doc['loan.lastLoanStatusUpdateDate'].empty) { return 0} long days = ((new Date().getTime() - doc['loan.lastLoanStatusUpdateDate'].value.millis) / (1000*60*60*24)); return days"
        }
      };
      appendToorvalRules(quaryObj);
      const response = await publicClient.post(
        url,
        {
          script_fields: scriptFields,
          query: quaryObj,
          aggs: {
            borrowers: {
              nested: {
                path: "borrowers"
              },
              aggs: {
                firstName: { terms: { field: "borrowers.firstName.raw" } }
              }
            }
          },
          _source: ["borrowers.firstName"]
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      const dummyResponse: any = {
        user: {
          buckets:
            response.data.response.aggregations.borrowers.firstName.buckets
        }
      };
      dispatch(hideLoader());
      // dispatch({
      //   type: DROPDOWN_DATA_SUCCESS,
      //   payload: {
      //     loanLists: dummyResponse,
      //     pageCount: 0,
      //     autoComplete: true
      //   }
      // });
      dispatch(
        dropDownDataSuccess({
          loanLists: dummyResponse,
          pageCount: 0,
          autoComplete: true
        })
      );
    } catch (err) {
      const e: any = err;
      console.error(e);
      dispatch(
        loanTypeFailed({
          error: {
            message: e?.message
          }
        })
      );
    }
  };
}
export function getDropDownData(
  column: any,
  filterSelections?: any,
  isBoolean?: any,
  stages?: any,
  toggleArr?: any[],
  timeInterval?: any
) {
  const originalToggles: any = ["Purchased", "Rejected", "Withdrawn"];
  const mustNotToggle: any =
    toggleArr &&
    toggleArr?.length &&
    originalToggles.filter((item: any) => !toggleArr.includes(item));
  const stagesParam = stages.length;
  let searchQuery: any;
  let accountIds: any = localStorage.getItem("account_ids");
  let should = [] as any;
  // let workflowStatus: any = {};
  accountIds = JSON.parse(accountIds);
  const objForQuery = [] as any;
  const mustnotArray: any = [];
  if (!isILP()) {
    if (mustNotToggle && mustNotToggle.length !== 0) {
      mustnotArray.push({
        terms: {
          "loan.loanState.raw": mustNotToggle
        }
      });
    } else if (toggleArr && toggleArr.length === 0)
      mustnotArray.push({
        terms: {
          "loan.loanState.raw": originalToggles
        }
      });
  }

  if (stages.length === 2) {
    mustnotArray.push({
      terms: {
        "loan.loanStage.raw": [LoanStage.fes]
      }
    });
  }
  if (stages.length === 1 && stages[0] !== "ON-HOLD") {
    mustnotArray.push({
      terms: {
        "loan.loanStage.raw": [LoanStage.pre, LoanStage.post]
      }
    });
  }
  if (!isILP()) {
    if (stagesParam === 1 && stages[0] === "ON-HOLD") {
      objForQuery.push(onHoldQuery);
    } else {
      objForQuery.push(noOnHoldQuery);
    }
    handleRolesPrivileges(mustnotArray);
  }
  if (!stages.includes("ON-HOLD")) {
    objForQuery.push({
      terms: {
        "loan.loanStage.raw": stages
      }
    });
  }
  // if (isILP()) {
  //   handleAssignToBasedOnRoleAndViceVersa(
  //     column,
  //     objForQuery,
  //     filterSelections
  //   );
  // }

  if (getCookie("loginFlow") === "originator") {
    objForQuery.push({
      // eslint-disable-next-line
      terms: {
        "loan.loanDetailId.originatorPartyId.raw": [getCookie("org_id")]
      }
    });
    if (mustnotArray.length) {
      searchQuery = {
        bool: {
          must: objForQuery,
          must_not: mustnotArray
        }
      };
    } else {
      searchQuery = {
        bool: {
          must: objForQuery
        }
      };
    }
  } else {
    if (stages[0] !== "TOORVAL" && !matchRole(ToorvalRoles.APPRAISER)) {
      objForQuery.push({
        terms: {
          "loan.loanDetailId.originatorPartyId.raw": accountIds
        }
      });
    }
    if (!isILP()) {
      if (stagesParam === 1 && stages[0] !== "ON-HOLD") {
        mustnotArray.push({
          terms: {
            "loan.loanState.raw":
              stages[0] === LoanStage.fes && isLATUser()
                ? [LoanStatusEnum.ExcelError, LoanStatusEnum.DataEntry]
                : [LoanStatusEnum.ExcelError]
          }
        });
      } else {
        mustnotArray.push({
          terms: {
            "loan.loanState.raw":
              stages[0] === "FES" && isLATUser()
                ? [LoanStatusEnum.DataEntry, LoanStatusEnum.ExcelError]
                : [LoanStatusEnum.ExcelError]
          }
        });
        if (stagesParam === 2) {
          mustnotArray.push(appendToQueryForLATToggle(stagesParam));
        }
        if (stagesParam === 3) {
          mustnotArray.push(appendToQueryForLATToggle(stagesParam));
        }
        mustnotArray.push(appendToQueryForLAT1(), appendToQueryForLAT2());
      }
    }
    if (isILP()) {
      const appendedQueries: any = appendILPQueries(
        objForQuery,
        timeInterval,
        mustnotArray
      );
      should = appendedQueries.should;
      // workflowStatus = appendedQueries.workflowStatus;
    }

    if (mustnotArray.length) {
      searchQuery = {
        bool: {
          must: objForQuery,
          must_not: mustnotArray
        }
      };
    } else {
      searchQuery = {
        bool: {
          must: objForQuery
        }
      };
    }
  }
  if (isRole(UserRole.LAT_TREASURER)) {
    if (searchQuery.bool.must.length > 1) {
      const indexToRemove = getESIndexToDelete(searchQuery.bool.must);
      searchQuery.bool.must.splice(indexToRemove, 1);
    } else {
      delete searchQuery.bool.must;
    }
  }

  if (
    getCookie("GUIDE") === "selected" &&
    stages.length === 1 &&
    stages[0] !== "ON-HOLD" &&
    searchQuery?.bool?.must
  ) {
    searchQuery.bool.must.push({
      terms: {
        "loan.loanStage.raw": [LoanStage.fes]
      }
    });
  }

  if (should.length) {
    searchQuery.bool.should = should;
  }
  const { key, tbField } = column;
  const url = `${getConfig().apiUrl}/search/${getConfig().aggregateUrl
    }/_search`;
  const scriptFields: any = {};
  scriptFields.loanDynamicStatus = {
    script: {
      source:
        "if(doc['loan.lastLoanStatusUpdateDate'].empty) { return 0} long days = ((new Date().getTime() - doc['loan.lastLoanStatusUpdateDate'].value.millis) / (1000*60*60*24)); return days"
    }
  };
  // if (Object.keys(workflowStatus)?.length) {
  //   scriptFields.workflowStatus = workflowStatus;
  // }
  appendToorvalRules(searchQuery, true);
  return new Promise((resolve, reject) => {
    publicClient
      .post(
        url,
        {
          script_fields: scriptFields,
          query: searchQuery,
          _source: false,
          size: 0,
          from: 0,
          aggs: getQueryForAggregations[key]?.(key, tbField) ?? {
            [key]: {
              terms: {
                field: tbField,
                size: 500,
                order: { _key: "asc" }
              }
            }
          }
        },
        {
          headers: {
            "Content-Type": "application/json"
            // "ignore-auth": true
          }
        }
      )
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function getFiltersData(
  column: any,
  filterSelections?: any,
  stages?: any,
  isBoolean?: any,
  toggleArr?: any[],
  timeInterval?: any,
  queryObj?: any
): any {
  const originalToggles: any = ["Purchased", "Rejected", "Withdrawn"];
  const mustNotToggle: any =
    toggleArr &&
    toggleArr?.length &&
    originalToggles.filter((item: any) => !toggleArr.includes(item));
  let searchQuery: any;
  let accountIds: any = localStorage.getItem("account_ids");
  let should = [] as any;
  // let workflowStatus: any = {};
  accountIds = JSON.parse(accountIds);
  const objForQuery = [] as any;
  if (queryObj) {
    objForQuery.push(queryObj);
  }
  if (Object.keys(filterSelections).length > 0) {
    const _searchObject = filterSelections;
    for (const key in _searchObject.filterSelections) {
      objForQuery.push({
        terms: {
          [key]: _searchObject.filterSelections[key]
        }
      });
    }
  }

  const mustnotArray: any = [];
  if (!isILP()) {
    if (mustNotToggle && mustNotToggle.length !== 0) {
      mustnotArray.push({
        terms: {
          "loan.loanState.raw": mustNotToggle
        }
      });
    } else if (toggleArr && toggleArr.length === 0)
      mustnotArray.push({
        terms: {
          "loan.loanState.raw": originalToggles
        }
      });
  }

  if (stages?.length === 2) {
    if (!isILP()) {
      setCookie("GUIDE", "notSelected");
    }
    mustnotArray.push({
      terms: {
        "loan.loanStage.raw": [LoanStage.fes]
      }
    });
  } else if (stages?.length === 1 && stages?.[0] !== "ON-HOLD") {
    setCookie("GUIDE", "selected");
    if (isLATUser()) {
      mustnotArray.push({
        terms: {
          "loan.loanState.raw": [LoanStatusEnum.DataEntry]
        }
      });
    }
    mustnotArray.push({
      terms: {
        "loan.loanStage.raw": [LoanStage.pre, LoanStage.post]
      }
    });
  } else {
    setCookie("GUIDE", "selected");
  }
  if (!isILP()) {
    if (stages?.length === 1 && stages?.[0] === "ON-HOLD") {
      objForQuery.push(onHoldQuery);
    } else {
      objForQuery.push(noOnHoldQuery);
    }
    handleRolesPrivileges(mustnotArray);
  }
  if (isILP()) {
    const appendedQueries: any = appendILPQueries(
      objForQuery,
      timeInterval,
      mustnotArray
    );
    should = appendedQueries.should;
    // workflowStatus = appendedQueries.workflowStatus;
  }
  if (getCookie("loginFlow") === "originator") {
    objForQuery.push({
      terms: {
        "loan.loanDetailId.originatorPartyId.raw": [getCookie("org_id")]
      }
    });
    if (mustnotArray.length) {
      searchQuery = {
        bool: {
          must: objForQuery,
          must_not: mustnotArray
        }
      };
    } else {
      searchQuery = {
        bool: {
          must: objForQuery
        }
      };
    }
  } else {
    if (!isILP()) {
      mustnotArray.push({
        terms: {
          "loan.loanState.raw":
            getCookie("GUIDE") === "selected" && isLATUser()
              ? [LoanStatusEnum.DataEntry, LoanStatusEnum.ExcelError]
              : [LoanStatusEnum.ExcelError]
        }
      });
      if (stages[0] !== LoanStage.tv && !matchRole(ToorvalRoles.APPRAISER)) {
        objForQuery.push({
          terms: {
            "loan.loanDetailId.originatorPartyId.raw": accountIds
          }
        });
      }
    }

    searchQuery = {
      bool: {
        must: objForQuery,
        must_not: mustnotArray
      }
    };
  }

  return async (dispatch: any) => {
    const aggregators: any = {};
    if (Object.keys(column).length > 0) {
      for (const key in column) {
        aggregators[String(key)] = {
          terms: {
            field: column[key],
            size: 500,
            order: { _key: "asc" }
          }
        };
      }
    }
    try {
      const url = `${getConfig().apiUrl}/search/${getConfig().aggregateUrl
        }/_search`;
      const scriptFields: any = {};
      scriptFields.loanDynamicStatus = {
        script: {
          source:
            "if(doc['loan.lastLoanStatusUpdateDate'].empty) { return 0} long days = ((new Date().getTime() - doc['loan.lastLoanStatusUpdateDate'].value.millis) / (1000*60*60*24)); return days"
        }
      };
      // if (Object.keys(workflowStatus)?.length) {
      //   scriptFields.workflowStatus = workflowStatus;
      // }
      if (should.length) {
        searchQuery.bool.should = should;
      }
      appendToorvalRules(searchQuery);
      const response = await publicClient.post(
        url,
        {
          track_total_hits: true,
          script_fields: scriptFields,
          query: searchQuery,
          aggs: aggregators,
          _source: false,
          size: 0,
          from: 0
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      // dispatch({
      //   type: FILTER_DATA_SUCCESS,
      //   payload: {
      //     loanLists: response.data,
      //     pageCount: 0
      //   }
      // });
      dispatch(
        filterDataSuccess({
          loanLists: response.data,
          pageCount: 0
        })
      );
    } catch (err) {
      const e: any = err;
      console.error(e);
      dispatch(
        loanTypeFailed({
          error: {
            message: e?.message
          }
        })
      );
    }
  };
}
function getGlobalResultObject(value: any, advanceSearch?: boolean) {
  const objForQueryShould = [] as any;
  if (advanceSearch === true || advanceSearch === undefined) {
    objForQueryShould.push({
      nested: {
        path: "properties",
        query: {
          multi_match: {
            query: value,
            fields: [
              "properties.propertyLocation.city",
              "properties.propertyLocation.addressLine1",
              "properties.propertyLocation.state",
              "properties.propertyLocation.postalCode"
            ],
            type: "phrase_prefix",
            analyzer: "standard"
          }
        }
      }
    });
    // objForQueryShould.push({
    //   nested: {
    //     path: "loan.loanUserMap",
    //     query: {
    //       multi_match: {
    //         query: value,
    //         fields: ["*"],
    //         type: "phrase_prefix",
    //         analyzer: "standard"
    //       }
    //     }
    //   }
    // });
    // objForQueryShould.push({
    //   nested: {
    //     path: "guarantors",
    //     query: {
    //       multi_match: {
    //         query: value,
    //         fields: ["*"],
    //         type: "phrase_prefix",
    //         analyzer: "standard"
    //       }
    //     }
    //   }
    // });
    // objForQueryShould.push({
    //   nested: {
    //     path: "borrowers",
    //     query: {
    //       multi_match: {
    //         query: value,
    //         fields: ["*"],
    //         type: "phrase_prefix",
    //         analyzer: "standard"
    //       }
    //     }
    //   }
    // });
    objForQueryShould.push({
      multi_match: {
        query: value,
        fields: [
          "loan.loanDetailId.toorakLoanId",
          "originator.accountName",
          "loan.loanInfo.primaryLoanId",
          "loan.loanDetailId.tradeId",
          "guarantorDetails.customer.firstName",
          "guarantorDetails.customer.lastName",
          "loan.loanCreatedUser.lastName",
          "loan.loanCreatedUser.firstName",
          "borrowerDetails.customer.lastName",
          "borrowerDetails.customer.firstName"
        ],
        analyzer: "standard",
        type: "phrase_prefix"
      }
    });
  } else {
    objForQueryShould.push({
      multi_match: {
        query: value,
        fields: [
          "loan.loanDetailId.toorakLoanId",
          "loan.loanInfo.primaryLoanId"
        ],
        analyzer: "standard",
        type: "phrase_prefix"
      }
    });
  }
  return objForQueryShould;
}

const getActiveInteractionsObject = (rowSourceObj: any) => {
  return {
    currentPendingAllEnquiryCount: rowSourceObj?.currentPendingAllEnquiryCount
      ? rowSourceObj.currentPendingAllEnquiryCount
      : 0,
    currentPendingAllExceptionCount:
      rowSourceObj?.currentPendingAllExceptionCount
        ? rowSourceObj.currentPendingAllExceptionCount
        : 0,
    currentPendingAllWaiverCount: rowSourceObj?.currentPendingAllWaiverCount
      ? rowSourceObj.currentPendingAllWaiverCount
      : 0,
    currentPendingExternalEnquiryCount:
      rowSourceObj?.currentPendingExternalEnquiryCount
        ? rowSourceObj.currentPendingExternalEnquiryCount
        : 0,
    currentPendingExternalExceptionCount:
      rowSourceObj?.currentPendingExternalExceptionCount
        ? rowSourceObj.currentPendingExternalExceptionCount
        : 0,
    currentPendingExternalWaiverCount:
      rowSourceObj?.currentPendingExternalWaiverCount
        ? rowSourceObj.currentPendingExternalWaiverCount
        : 0
  };
};
/** recourse value might be present in recourse(for old loans) or recourseString in back end. recourse is boolean and recourseString is string field */
// Defaulting to "Full Recourse" for 30 yr loan in case both recourse and recourseString are null.
const getRecourseValueMapping = (loanInfo: any, loanType: any) => {
  let returnValue = "";
  if (loanInfo) {
    if (
      loanType === loanTypeName[1].loanCreationName &&
      loanInfo.recourse === null &&
      loanInfo.recourseString === null
    )
      returnValue = "Full Recourse";
    else if (
      loanInfo.recourse === false ||
      loanInfo.recourseString === "N" ||
      loanInfo.recourseString === "No" ||
      loanInfo.recourseString === "Non-Recourse"
    )
      returnValue = "Non-Recourse";
    else if (
      loanInfo.recourse === true ||
      loanInfo.recourseString === "Y" ||
      loanInfo.recourseString === "Yes" ||
      loanInfo.recourseString === "Full Recourse"
    )
      returnValue = "Full Recourse";
    else returnValue = "";
  }
  return returnValue;
};

const getFirstConditionPosted = (
  firstConditionDate: any,
  filterSelections?: any
) => {
  let firstConditionPostedDate: any;
  if (filterSelections?.length) {
    // const postedDate = Math.min(
    //   ...exceptions
    //     .filter((obj: any) => obj?.type?.toLowerCase() === "exception")
    //     .map((obj: any) => obj?.createdOn)
    // );
    let firstConditionPostedDate: any = new Date(firstConditionDate);

    const date1 = `${`0${firstConditionPostedDate.getMonth() + 1}`.slice(
      -2
    )}/${`0${firstConditionPostedDate.getDate()}`.slice(
      -2
    )}/${firstConditionPostedDate.getFullYear()}`;

    const gteDate = filterSelections[0]?.gte.split("-");

    const date2 = `${gteDate[1]}/${gteDate[2]}/${gteDate[0]}`;
    const lteDate = filterSelections[0]?.lte.split("-");

    const date3 = `${lteDate[1]}/${lteDate[2]}/${lteDate[0]}`;
    if (
      new Date(date1) >= new Date(date2) &&
      new Date(date1) <= new Date(date3)
    ) {
      return firstConditionPostedDate;
    }
    firstConditionPostedDate = "";
    return firstConditionPostedDate;
  }
  firstConditionPostedDate = firstConditionDate;

  return firstConditionPostedDate;
};

export function getGlobalSearchResult(
  searchKey: any,
  filterSelections?: any,
  stages?: any,
  tabinfo?: any,
  timeInterval?: any
): any {
  return async (dispatch: any) => {
    const { value } = searchKey;
    try {
      dispatch(showLoader());
      const url = `${getConfig().apiUrl}/search/${getConfig().aggregateUrl
        }/_search`;
      const stagesParam = stages.length;
      let accountIds: any = localStorage.getItem("account_ids");
      accountIds = JSON.parse(accountIds);
      let objForQuery = [] as any;
      let should = [] as any;
      // let workflowStatus: any = {};
      if (!isILP()) {
        switch (tabinfo) {
          case "waiversPending":
            objForQuery.push(
              isLATUser()
                ? {
                  range: {
                    currentPendingAllWaiverCount: {
                      gt: 0
                    }
                  }
                }
                : {
                  range: {
                    currentPendingExternalWaiverCount: {
                      gt: 0
                    }
                  }
                }
            );
            break;
          case "errorsPending":
            objForQuery.push({
              range: {
                "loan.errorsPending": {
                  gt: 0
                }
              }
            });
            break;
          case "fieldsPending":
            objForQuery.push({
              range: {
                "loan.fieldsPending": {
                  gt: 0
                }
              }
            });
            break;
          case "exceptionsPending":
            objForQuery.push(
              isLATUser()
                ? {
                  range: {
                    currentPendingAllExceptionCount: {
                      gt: 0
                    }
                  }
                }
                : {
                  range: {
                    currentPendingExternalExceptionCount: {
                      gt: 0
                    }
                  }
                }
            );
            break;
          case "enquiriesPending":
            objForQuery.push(
              isLATUser()
                ? {
                  range: {
                    currentPendingAllEnquiryCount: {
                      gt: 0
                    }
                  }
                }
                : {
                  range: {
                    currentPendingExternalEnquiryCount: {
                      gt: 0
                    }
                  }
                }
            );
            break;
          default:
            break;
        }
      }
      if (filterSelections !== undefined) {
        const search_filter: any = [];
        const _searchObject = filterSelections;
        for (const key in _searchObject.filterSelections) {
          if (key.includes("bool")) {
            search_filter.push({ bool: _searchObject.filterSelections.bool });
          } else if (
            key.includes("_searchBox") &&
            _searchObject.filterSelections[key].length === 1 &&
            !key.includes("loanId") &&
            !key.includes("tradeId") &&
            !key.includes("primaryLoanId")
          ) {
            search_filter.push({
              match_phrase_prefix: {
                [key.replace(".raw_searchBox", "")]: {
                  query: _searchObject.filterSelections[key][0],
                  analyzer: "simple"
                }
              }
            });
          } else {
            search_filter.push({
              terms: {
                [key.replace("_searchBox", "")]:
                  _searchObject.filterSelections[key]
              }
            });
          }
        }
        if (search_filter.length)
          objForQuery = [...objForQuery, ...search_filter];
      }
      const mustnotArray: any = [];

      if (stages.length === 2) {
        mustnotArray.push({
          terms: {
            "loan.loanStage.raw": [LoanStage.fes]
          }
        });
      }
      if (stages.length === 1 && stages[0] !== "ON-HOLD") {
        mustnotArray.push({
          terms: {
            "loan.loanStage.raw": [LoanStage.pre, LoanStage.post]
          }
        });
      }
      if (getCookie("loginFlow") === "originator") {
        objForQuery.push(
          {
            terms: {
              "loan.loanDetailId.originatorPartyId.raw": [getCookie("org_id")]
            }
          },
          {
            bool: {
              should: getGlobalResultObject(value)
            }
          }
        );
      } else {
        if (!isILP()) {
          stagesParam === 1 && stages[0] !== "ON-HOLD"
            ? mustnotArray.push({
              terms: {
                "loan.loanState.raw":
                  stages[0] === LoanStage.fes && isLATUser()
                    ? [LoanStatusEnum.ExcelError, LoanStatusEnum.DataEntry]
                    : [LoanStatusEnum.ExcelError]
              }
            })
            : mustnotArray.push(
              {
                terms: {
                  "loan.loanState.raw":
                    stages[0] === "FES" && isLATUser()
                      ? [LoanStatusEnum.DataEntry, LoanStatusEnum.ExcelError]
                      : [LoanStatusEnum.ExcelError]
                }
              },
              stagesParam === 2
                ? appendToQueryForLATToggle(stagesParam)
                : stagesParam === 3
                  ? appendToQueryForLATToggle(stagesParam)
                  : "",
              appendToQueryForLAT1(),
              appendToQueryForLAT2()
            );
        }
        objForQuery.push(
          {
            terms: {
              "loan.loanDetailId.originatorPartyId.raw": accountIds
            }
          },
          {
            bool: {
              should: getGlobalResultObject(value)
            }
          }
        );
      }
      if (!isILP()) {
        if (stagesParam === 1 && stages[0] === "ON-HOLD") {
          objForQuery.push(onHoldQuery);
        } else {
          objForQuery.push(noOnHoldQuery);
        }
        handleRolesPrivileges(mustnotArray);
      }

      if (isILP()) {
        const appendedQueries: any = appendILPQueries(
          objForQuery,
          timeInterval,
          mustnotArray
        );

        should = appendedQueries.should;
        // workflowStatus = appendedQueries.workflowStatus;
      }
      let query: any = {};
      if (isLATUser()) {
        query = {
          bool: {
            must: objForQuery,
            must_not: mustnotArray
          }
        };
        if (should.length) {
          query.bool.should = should;
        }
      } else if (mustnotArray.length) {
        query = {
          bool: {
            must: objForQuery,
            must_not: mustnotArray
          }
        };
      } else {
        query = {
          bool: {
            must: objForQuery
          }
        };
      }
      if (isRole(UserRole.LAT_TREASURER)) {
        if (query.bool.must.length > 1) {
          const indexToRemove = getESIndexToDelete(query.bool.must);
          query.bool.must.splice(indexToRemove, 1);
        } else {
          delete query.bool.must;
        }
      }
      const scriptFields: any = {};
      scriptFields.loanDynamicStatus = {
        script: {
          source:
            "if(doc['loan.lastLoanStatusUpdateDate'].empty) { return 0} long days = ((new Date().getTime() - doc['loan.lastLoanStatusUpdateDate'].value.millis) / (1000*60*60*24)); return days"
        }
      };
      // if (Object.keys(workflowStatus)?.length) {
      //   scriptFields.workflowStatus = workflowStatus;
      // }
      const aggsQuery: any = {
        totalLoanAmount: {
          sum: {
            script: {
              source:
                "if (doc.containsKey('loan.loanEconomics.originalLoanAmount.raw') && doc['loan.loanEconomics.originalLoanAmount.raw'].size() > 0) {return Double.parseDouble(doc['loan.loanEconomics.originalLoanAmount.raw'].value.replace(',', '').replace('$', ''));} else {return 0;}"
            }
          }
        }
      };
      const response = await publicClient.post(
        url,
        {
          aggs: aggsQuery,
          script_fields: scriptFields,
          _source: ["*"],
          track_total_hits: true,
          size: 20,
          from: 0,
          sort: isILP()
            ? [{ "loan.loanSubmissionDate.raw": "ASC" }, "_score"]
            : [{ updatedOn: "desc" }, "_score"],
          query
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      const loanList: any = formatLoanListResponse(response?.data?.response?.hits?.hits ?? []);
      dispatch(
        loanListSuccess({
          loanLists: loanList
        })
      );

      dispatch(hideLoader());
      dispatch(
        sortLoanListSuccess({
          loanLists: loanList,
          pageCount: 0
        })
      );

      if (value?.length) {
        dispatch(
          loanListCount({
            loanLists: response?.data?.response?.hits?.total?.value,
            loanAmount:
              response?.data?.response?.aggregations?.totalLoanAmount?.value,
            tab: "allTab"
          })
        );
        dispatch(
          updateLoanListTabCount({
            tabinfo,
            count: response.data.response.hits.total.value
          })
        );
      }
    } catch (err) {
      dispatch(
        updateLoadingFlag({
          loadingFlag: false
        })
      );

      const e: any = err;
      console.error(e);
      dispatch(hideLoader());
      dispatch(
        loanTypeFailed({
          error: {
            message: e?.message
          }
        })
      );
    }
  };
}

export type loanListTypeSorted = {
  count: number;
  page: number;
  sort: string;
  sortColumn: string;
};
export type loanListType = {
  count: number;
  page: number;
  sort: string;
};

function appendILPQueries(
  objForQuery: any,
  timeInterval: string,
  mustNotArray: any
) {
  objForQuery.push(noOnHoldQuery);
  const iLPQueries = getILPQueries(timeInterval);
  const obj = iLPQueries.obj;
  const should = iLPQueries.shouldArray;
  objForQuery.push(...obj);
  objForQuery.push({
    terms: {
      "loan.loanStage.raw": [LoanStage.pre, LoanStage.post]
    }
  });
  mustNotArray.push(
    ...[
      {
        terms: {
          "loan.loanState.raw": [
            LoanStatusEnum.DataEntry,
            LoanStatusEnum.ExcelError,
            LoanStatusEnum.Rejected,
            "Withdrawn"
          ]
        }
      },
      {
        terms: {
          "loan.loanStage.raw": [LoanStage.fes]
        }
      }
    ]
  );
  if (timeInterval !== "All") {
    mustNotArray.push(
      ...[
        {
          terms: {
            "tasks.reconcile.taskStatus.raw": ["Not Applicable"]
          }
        },
        {
          terms: {
            "tasks.tag.taskStatus.raw": ["Not Applicable"]
          }
        },
        {
          terms: {
            "tasks.review.taskStatus.raw": ["Not Applicable"]
          }
        },
        {
          terms: {
            "tasks.split.taskStatus.raw": ["Not Applicable"]
          }
        }
      ]
    );
  }
  return {
    obj,
    should
    // workflowStatus: iLPQueries.workflowStatus
  };
}

function getILPQueries(timeInterval: any) {
  const owner = getCookie("person_id");
  const obj: any = [];
  const shouldArray1: any = [
    {
      range: {
        "loan.dueDilligenceDate.raw": {
          from: iLPGoLiveDate
        }
      }
    },
    {
      range: {
        "loan.initialReviewDate.raw": {
          from: loansToILPAtInitialReviewCutOff
        }
      }
    }
  ];
  const shouldArray2: any = [
    {
      exists: {
        field: "loan.dueDilligenceDate"
      }
    },
    {
      exists: {
        field: "loan.initialReviewDate"
      }
    }
  ];
  const shouldArray3: any = [];
  const shouldArray4: any = [];
  // const workflowStatus: any = {};
  const isLATApprover = isRole(InternalRolesEnum.LAT_APPROVER);
  const isLATInquiry = isRole(InternalRolesEnum.LAT_INQUIRY);
  const isLATAdmin = isRole(InternalRolesEnum.LAT_ADMIN);
  const isLATVM = isRole(InternalRolesEnum.LAT_VM);
  const isLATIR = isRole(InternalRolesEnum.LAT_IR);
  const isLATQC = isRole(InternalRolesEnum.LAT_QC);

  let shouldItemsUpdatedOn: any = [];
  if (timeInterval === "Daily") {
    shouldItemsUpdatedOn = [
      {
        range: {
          "tasks.reconcile.updatedOn": {
            from: "now-24h"
          }
        }
      },
      {
        range: {
          "tasks.tag.updatedOn": {
            from: "now-24h"
          }
        }
      },
      {
        range: {
          "tasks.split.updatedOn": {
            from: "now-24h"
          }
        }
      },
      {
        range: {
          "tasks.review.updatedOn": {
            from: "now-24h"
          }
        }
      },
      {
        range: {
          "tasks.budgetReview.updatedOn": {
            from: "now-24h"
          }
        }
      },
      {
        range: {
          "tasks.budgetApprove.updatedOn": {
            from: "now-24h"
          }
        }
      },
      {
        range: {
          "loan.lastWorkflowUpdateDate.raw": {
            from: "now-24h"
          }
        }
      }
    ];
  } else if (timeInterval === "Weekly") {
    shouldItemsUpdatedOn = [
      {
        range: {
          "tasks.reconcile.updatedOn": {
            from: "now-7d"
          }
        }
      },
      {
        range: {
          "tasks.tag.updatedOn": {
            from: "now-7d"
          }
        }
      },
      {
        range: {
          "tasks.split.updatedOn": {
            from: "now-7d"
          }
        }
      },
      {
        range: {
          "tasks.review.updatedOn": {
            from: "now-7d"
          }
        }
      },
      {
        range: {
          "tasks.budgetReview.updatedOn": {
            from: "now-7d"
          }
        }
      },
      {
        range: {
          "tasks.budgetApprove.updatedOn": {
            from: "now-7d"
          }
        }
      },
      {
        range: {
          "loan.lastWorkflowUpdateDate.raw": {
            from: "now-7d"
          }
        }
      }
    ];
  } else if (timeInterval === "Monthly") {
    shouldItemsUpdatedOn = [
      {
        range: {
          "tasks.reconcile.updatedOn": {
            from: "now-1M"
          }
        }
      },
      {
        range: {
          "tasks.tag.updatedOn": {
            from: "now-1M"
          }
        }
      },
      {
        range: {
          "tasks.split.updatedOn": {
            from: "now-1M"
          }
        }
      },
      {
        range: {
          "tasks.review.updatedOn": {
            from: "now-1M"
          }
        }
      },
      {
        range: {
          "tasks.budgetReview.updatedOn": {
            from: "now-1M"
          }
        }
      },
      {
        range: {
          "tasks.budgetApprove.updatedOn": {
            from: "now-1M"
          }
        }
      },
      {
        range: {
          "loan.lastWorkflowUpdateDate.raw": {
            from: "now-1M"
          }
        }
      }
    ];
  }
  shouldArray3.push(...shouldItemsUpdatedOn);

  if (
    !getCookie("globalSearchValue") &&
    (isLATIR || isLATQC) &&
    !(isLATAdmin || isLATVM)
  ) {
    shouldArray4.push(
      {
        terms: {
          "tasks.reconcile.assigneePartyId.raw": [owner]
        }
      },
      {
        terms: {
          "tasks.tag.assigneePartyId.raw": [owner]
        }
      },
      {
        terms: {
          "tasks.split.assigneePartyId.raw": [owner]
        }
      },
      {
        terms: {
          "tasks.review.assigneePartyId.raw": [owner]
        }
      },
      {
        terms: {
          "tasks.budgetReview.assigneePartyId.raw": [owner]
        }
      },
      {
        terms: {
          "tasks.budgetApprove.assigneePartyId.raw": [owner]
        }
      },
      {
        terms: {
          "loan.assignedTo.raw": [owner]
        }
      },
      {
        terms: {
          "loan.irCompletedBy.raw": [owner]
        }
      }
    );
    if (timeInterval === "Daily") {
      shouldArray3.push({
        range: {
          "loan.lastAssignedToUpdateDate.raw": {
            from: "now-24h"
          }
        }
      });
    } else if (timeInterval === "Weekly") {
      shouldArray3.push({
        range: {
          "loan.lastAssignedToUpdateDate.raw": {
            from: "now-7d"
          }
        }
      });
    } else if (timeInterval === "Monthly") {
      shouldArray3.push({
        range: {
          "loan.lastAssignedToUpdateDate.raw": {
            from: "now-1M"
          }
        }
      });
    }
    // if (isLATIR) {
    //   workflowStatus.script = {
    //     source: `def status = doc['loan.workflowState.raw'].value; if (status == '${InternalDDStatusEnum.ReviewInProgress}' || status == '${InternalDDStatusEnum.ReviewCleared}' || status == '${InternalDDStatusEnum.ReviewCompleted}') {return status;} for (item in params._source.loan?.loanDetailId.loanLandmarks) {if (item.createdBy == '${owner}' && item.value == '${InternalDDStatusEnum.IRCompleted}') {status = '${InternalDDStatusEnum.IRCompleted}';}} return status;`
    //   };
    // } else {
    //   shouldArray4.push({
    //     terms: {
    //       "loan.qcCompletedBy.raw": [owner]
    //     }
    //   });
    //   workflowStatus.script = {
    //     source: `def status = doc['loan.workflowState.raw'].value; if (status == '${InternalDDStatusEnum.ReviewInProgress}' || status == '${InternalDDStatusEnum.ReviewCleared}' || status == '${InternalDDStatusEnum.ReviewCompleted}') {return status;} if (doc['loan.qcCompletedBy.raw'].size() != 0 && doc['loan.qcCompletedBy.raw'].value == '${owner}') {status='${InternalDDStatusEnum.QCCompleted}'} else if (doc['loan.assignedTo.raw'].value != '${owner}' && doc['loan.irCompletedBy.raw'].size() != 0 && doc['loan.irCompletedBy.raw'].value == '${owner}') {status = '${InternalDDStatusEnum.IRCompleted}';} return status;`
    //   };
    // }
  }

  if ((isLATApprover || isLATInquiry) && !isLATAdmin && !isLATVM) {
    if (isLATInquiry) {
      obj.push(
        {
          terms: {
            "enquiries.workflowState.raw": Object.values(InquiryStatusEnum)
          }
        },
        {
          terms: {
            "loan.workflowState.raw": [
              InternalDDStatusEnum.ReviewInProgress,
              InternalDDStatusEnum.ReviewCleared,
              InternalDDStatusEnum.ReviewCompleted
            ]
          }
        }
      );
    } else {
      obj.push({
        terms: {
          "loan.workflowState.raw": [
            InternalDDStatusEnum.ApproverPending,
            InternalDDStatusEnum.ReviewInProgress,
            InternalDDStatusEnum.ReviewCleared,
            InternalDDStatusEnum.ReviewCompleted
          ]
        }
      });
    }
  }

  if (!(getCookie("globalSearchValue") !== "" && (isLATIR || isLATQC))) {
    if (timeInterval === "Daily") {
      shouldArray3.push({
        range: {
          "enquiries.lastExceptionUpdateDate.raw": {
            from: "now-24h"
          }
        }
      });
    } else if (timeInterval === "Weekly") {
      shouldArray3.push({
        range: {
          "enquiries.lastExceptionUpdateDate.raw": {
            from: "now-7d"
          }
        }
      });
    } else if (timeInterval === "Monthly") {
      shouldArray3.push({
        range: {
          "enquiries.lastExceptionUpdateDate.raw": {
            from: "now-1M"
          }
        }
      });
    }
  }

  obj.push({
    bool: {
      should: shouldArray1
    }
  });

  obj.push({
    bool: {
      should: shouldArray2
    }
  });

  if (shouldArray3.length) {
    obj.push({
      bool: {
        should: shouldArray3
      }
    });
  }
  if (shouldArray4.length) {
    obj.push({
      bool: {
        should: shouldArray4
      }
    });
  }
  return {
    obj,
    shouldArray: []
    // workflowStatus
  };
}

export function getLoanList(
  loan: loanListType,
  searchedValue: any,
  stages?: any,
  timeInterval?: any,
  toggleArr?: any[]
): any {
  const originalToggles: any = ["Purchased", "Rejected", "Withdrawn"];
  const mustNotToggle: any =
    toggleArr &&
    toggleArr?.length &&
    originalToggles.filter((item: any) => !toggleArr.includes(item));
  let searchQuery: any;
  let accountIds: any = localStorage.getItem("account_ids");
  accountIds = JSON.parse(accountIds);
  const objForQuery = [] as any;
  let should = [] as any;
  // let workflowStatus: any = {};
  if (searchedValue) {
    objForQuery.push({
      bool: {
        should: getGlobalResultObject(searchedValue)
      }
    });
  }
  const mustnotArray: any = [];
  if (!isILP()) {
    if (mustNotToggle && mustNotToggle.length !== 0) {
      mustnotArray.push({
        terms: {
          "loan.loanState.raw": mustNotToggle
        }
      });
    } else if (toggleArr && toggleArr.length === 0)
      mustnotArray.push({
        terms: {
          "loan.loanState.raw": originalToggles
        }
      });
  }

  let stagesParam = 3;
  if (stages && stages.length === 2) {
    setCookie("GUIDE", "notSelected");
    stagesParam = 2;
    mustnotArray.push({
      terms: {
        "loan.loanStage.raw": [LoanStage.fes]
      }
    });
  } else if (stages && stages.length === 1 && stages[0] !== "ON-HOLD") {
    stagesParam = 1;
    setCookie("GUIDE", "selected");
    mustnotArray.push({
      terms: {
        "loan.loanStage.raw": [LoanStage.pre, LoanStage.post]
      }
    });
  } else {
    setCookie("GUIDE", "selected");
  }
  if (getCookie("loginFlow") === "originator") {
    objForQuery.push({
      terms: {
        "loan.loanDetailId.originatorPartyId.raw": [getCookie("org_id")]
      }
    });
  } else {
    if (!isILP()) {
      stagesParam === 1 && stages[0] !== "ON-HOLD"
        ? mustnotArray.push({
          terms: {
            "loan.loanState.raw":
              getCookie("GUIDE") === "selected" && isLATUser()
                ? [LoanStatusEnum.DataEntry, LoanStatusEnum.ExcelError]
                : [LoanStatusEnum.ExcelError]
          }
        })
        : mustnotArray.push(
          {
            terms: {
              "loan.loanState.raw":
                getCookie("GUIDE") === "selected" && isLATUser()
                  ? [LoanStatusEnum.DataEntry, LoanStatusEnum.ExcelError]
                  : [LoanStatusEnum.ExcelError]
            }
          },
          stagesParam === 2
            ? appendToQueryForLATToggle(stagesParam)
            : stagesParam === 3
              ? appendToQueryForLATToggle(stagesParam)
              : "",
          appendToQueryForLAT1(),
          appendToQueryForLAT2()
        );
    }
    objForQuery.push({
      terms: {
        "loan.loanDetailId.originatorPartyId.raw": accountIds
      }
    });
  }
  if (!isILP()) {
    if (stagesParam === 1 && stages[0] === "ON-HOLD") {
      objForQuery.push(onHoldQuery);
    } else {
      objForQuery.push(noOnHoldQuery);
    }
    handleRolesPrivileges(mustnotArray);
  }
  if (isILP()) {
    const appendedQueries: any = appendILPQueries(
      objForQuery,
      timeInterval,
      mustnotArray
    );
    should = appendedQueries.should;
    // workflowStatus = appendedQueries.workflowStatus;
  }

  if (stages && stages?.length !== 0) {
    if (stages?.length !== 1 && stages[0] !== "ON-HOLD") {
      objForQuery.push({
        terms: {
          "loan.loanStage.raw": stages
        }
      });
    }
  }
  if (isLATUser()) {
    searchQuery = {
      bool: {
        must: objForQuery,
        must_not: mustnotArray
      }
    };
    if (should.length) {
      searchQuery.bool.should = should;
    }
  } else if (mustnotArray.length) {
    searchQuery = {
      bool: {
        must: objForQuery,
        must_not: mustnotArray
      }
    };
  } else {
    searchQuery = {
      bool: {
        must: objForQuery
      }
    };
  }
  if (isRole(UserRole.LAT_TREASURER)) {
    if (searchQuery.bool.must.length > 1) {
      const indexToRemove = getESIndexToDelete(searchQuery.bool.must);
      searchQuery.bool.must.splice(indexToRemove, 1);
    } else {
      delete searchQuery.bool.must;
    }
  }

  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const { count, page } = loan;
      let url = `${getConfig().apiUrl
        }/aggregate/bulk/loans?includeResults=true&includeSettlementResults=true`;
      let defaultSort: any = [{ updatedOn: "desc" }, "_score"];
      if (isILP()) {
        url = `${getConfig().apiUrl}/search/${getConfig().aggregateUrl
          }/_search`;
        defaultSort = [{ "loan.loanSubmissionDate.raw": "ASC" }, "_score"];
      }
      const aggsQuery: any = {
        totalLoanAmount: {
          sum: {
            script: {
              source:
                "if (doc.containsKey('loan.loanEconomics.originalLoanAmount.raw') && doc['loan.loanEconomics.originalLoanAmount.raw'].size() > 0) {return Double.parseDouble(doc['loan.loanEconomics.originalLoanAmount.raw'].value.replace(',', '').replace('$', ''));} else {return 0;}"
            }
          }
        }
      };
      const scriptFields: any = {};
      scriptFields.loanDynamicStatus = {
        script: {
          source:
            "if(doc['loan.lastLoanStatusUpdateDate'].empty) { return 0} long days = ((new Date().getTime() - doc['loan.lastLoanStatusUpdateDate'].value.millis) / (1000*60*60*24)); return days"
        }
      };
      // if (Object.keys(workflowStatus)?.length) {
      //   scriptFields.workflowStatus = workflowStatus;
      // }
      appendToorvalRules(searchQuery);
      const response = await publicClient.post(
        url,
        {
          aggs: aggsQuery,
          script_fields: scriptFields,
          query: searchQuery,
          _source: ["*"],
          track_total_hits: true,
          sort: defaultSort,
          size: count,
          from: page
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      const loanList: any = formatLoanListResponse(response?.data?.response?.hits?.hits ?? []);
      dispatch(hideLoader());
      dispatch(
        loanListSuccess({
          loanLists: loanList
        })
      );
      dispatch(
        loanListCount({
          loanLists: response?.data?.response?.hits?.total?.value,
          loanAmount:
            response?.data?.response?.aggregations?.totalLoanAmount?.value,
          tab: "allTab"
        })
      );
    } catch (err) {
      dispatch(
        updateLoadingFlag({
          loadingFlag: false
        })
      );

      const e: any = err;
      console.error(e);
      dispatch(hideLoader());
      dispatch(
        loanTypeFailed({
          error: {
            message: e?.message
          }
        })
      );
    }
  };
}

export function setCompactViewFilterSearch(value: any, columnName: any): any {
  return (dispatch: any) => {
    dispatch(compactSearchUpdate({ [columnName]: value }));
  };
}

export function setDocumentUplaodUrl(awsUrl: string): any {
  return (dispatch: any) => {
    dispatch(updateHasDocumentStatus({ hasDocuments: true }));
    dispatch(updateAwsDocumentUrl({ awsUrl }));
  };
}

export function updateHeaderFilterTags(tags: any, viewType: any): any {
  return async (dispatch: any) => {
    try {
      const updatedSelection = JSON.parse(JSON.stringify(tags));
      const userId = getCookie("person_id") || "userId";
      const currentFilters = JSON.parse(
        localStorage.getItem("headerFilterTag") || "{}"
      );
      let updatedFilters = currentFilters?.[userId];
      updatedFilters = {
        ...updatedFilters,
        [viewType]: updatedSelection || {}
      };
      localStorage.setItem(
        "headerFilterTag",
        JSON.stringify({ [userId]: updatedFilters })
      );
      dispatch(
        headerFiltersUpdateSuccess({ selections: updatedSelection, viewType })
      );
    } catch (err) {
      const e: any = err;
      console.error(e);
      dispatch(
        loanTypeFailed({
          error: {
            message: e?.message
          }
        })
      );
    }
  };
}

// export function updateActIntLoanList(loanId: any): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: UPDATE_ACT_INT_LOANLIST,
//       payload: loanId
//     });
//   };
// }

// export function closeActiveInteractionsModal(): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: CLOSE_ACTIVE_INTERACTIONS_MODAL
//     });
//   };
// }

export function setActiveInteractionsModal(
  modalValue: any,
  loanId: any,
  loanStage: any
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${getConfig().apiUrl}/aggregate/search/${getConfig().dashboardUrl
        }/_search/${loanId}?loanStage=${loanStage}`;
      let accountIds: any;
      const mustNotArray: ObjectType[] = [
        {
          terms: {
            "exception.status.raw": ["WAIVED", "SATISFIED", "RESCIND", "Close"]
          }
        }
      ];
      if (!isLATUser()) {
        accountIds = [getCookie("org_id")];
        mustNotArray.push({
          terms: {
            "exception.visibility.raw": ["internal"]
          }
        });
      } else {
        accountIds = localStorage.getItem("account_ids");
        accountIds = JSON.parse(accountIds);
      }
      const body = {
        query: {
          bool: {
            must: [
              {
                bool: {
                  must_not: mustNotArray
                }
              },
              {
                terms: {
                  "loanStage.raw": [loanStage]
                }
              },

              {
                terms: {
                  "resource.raw": ["waiver", "exception"]
                }
              },
              {
                terms: {
                  "loanId.raw": [loanId]
                }
              },
              {
                terms: {
                  "status.raw": [
                    "OPEN",
                    "Raised",
                    "open",
                    "REOPENED",
                    "",
                    "Open",
                    "REQUESTED",
                    "RE-REQUESTED",
                    "REBUTTAL",
                    "REBUTTALED",
                    "Inquiry Raised"
                  ]
                }
              },
              ...(!isRole(UserRole.LAT_TREASURER)
                ? [
                  {
                    terms: {
                      "originatorId.raw": accountIds
                    }
                  }
                ]
                : [])
            ],
            ...(isLATUser() && {
              must_not: [
                {
                  bool: {
                    must: [
                      {
                        terms: {
                          "exception.visibility.raw": ["internal"]
                        }
                      },
                      {
                        terms: {
                          "exception.type.raw": ["Inquiry"]
                        }
                      }
                    ]
                  }
                }
              ]
            }),
            ...(!isLATUser() && {
              must_not: [
                {
                  terms: {
                    "exception.visibility.raw": ["internal"]
                  }
                }
              ]
            })
          }
        },
        _source: ["*"],
        sort: [
          {
            updatedOn: {
              order: "desc"
            }
          }
        ],
        size: 20,
        from: 0
      };

      const response = await publicClient.post(url, body);
      dispatch(hideLoader());
      // dispatch({
      //   type: ACTIVE_INTERACTIONS_MODAL,
      //   payload: {
      //     actIntArray: response?.data?.response?.hits?.hits,
      //     actIntModal: modalValue
      //   }
      // });
      dispatch(
        activeInteractionsModal({
          actIntArray: response?.data?.response?.hits?.hits,
          actIntModal: modalValue
        })
      );
    } catch (err) {
      dispatch(hideLoader());
      const e: any = err;
      console.error(e);
      // dispatch({
      //   type: ACTIVE_INT_FAILED,
      //   error: {
      //     message: e?.message
      //   }
      // });
      dispatch(activeIntFailed());
    }
  };
}

// export function deleteWaiverFromActInt(waiverId: string): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: DELETE_WAIVER,
//       payload: { waiverId }
//     });
//   };
// }

// export function setActiveInteractionsArray(waiverId: any, loanId: any): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: UPDATE_ACTIVE_INTERACTIONS_ARRAY,
//       payload: { waiverId, loanId }
//     });
//   };
// }
export function updateSlectedFilters(value: any, viewType: string): any {
  return async (dispatch: any) => {
    try {
      const updatedSelection = JSON.parse(JSON.stringify(value));
      const userId = getCookie("person_id") || "userId";
      const currentFilters = JSON.parse(
        localStorage.getItem("filters") || "{}"
      );
      let updatedFilters = currentFilters?.[userId];
      updatedFilters = {
        ...updatedFilters,
        [viewType]: { filterSelections: updatedSelection || {} }
      };
      localStorage.setItem(
        "filters",
        JSON.stringify({ [userId]: updatedFilters })
      );

      // dispatch({
      //   type: FILTERS_UPDATE_SUCCESS,
      //   payload: {
      //     selections: { filterSelections: updatedSelection },
      //     viewType
      //   }
      // });
      dispatch(
        filtersUpdateSuccess({
          selections: { filterSelections: updatedSelection },
          viewType
        })
      );
    } catch (err) {
      const e: any = err;
      console.error(e);
      dispatch(
        loanTypeFailed({
          error: {
            message: e?.message
          }
        })
      );
    }
  };
}
// function mapOrder(a: any, order: any, key: any) {
//   // eslint-disable-next-line
//   const map = order.reduce((r: any, v: any, i: any) => ((r[v] = i), r), {});
//   return a.sort((a: any, b: any) => map[a[key]] - map[b[key]]);
// }
function getBorrowerData(rowData: any) {
  const loanUserMap = rowData?.loan?.loanUserMap || [];
  const isGroundUp = rowData?.loan?.loanInfo?.toorakProduct === ToorakProductEnum.GroundUp || rowData?.loan?.loanInfo?.loanSizerType === ToorakProductEnum.GroundUp;
  const borrowerData = loanUserMap.filter(function (el: any) {
    return el.loanUserType === "Borrower";
  });
  if (borrowerData.length) {
    // sorting in borrower data from loanusermap based on user sequence

    borrowerData.sort((a: any, b: any) => {
      return (
        parseInt(a.loanUserSequence, 10) - parseInt(b.loanUserSequence, 10)
      );
    });
    // filtering in entity borrower data from rowData.borrowers object
    const borrowerEntityData: any = (rowData?.borrowers || []).filter(function (
      el: any
    ) {
      return el.partyType === "account";
    });
    const guarantorData = loanUserMap.filter(function (el: any) {
      return el.loanUserType === "Guarantor" && el.isPrimary === true;
    });
    let guarantorsOrgData: any;
    let guarantorSpecificData: any;
    let guarantorUsermapData: any;
    let guarantorAddress: any;
    if (guarantorData.length !== 0 && rowData.guarantors.length !== 0) {
      guarantorData.sort((a: any, b: any) => {
        return (
          parseInt(a.loanUserSequence, 10) - parseInt(b.loanUserSequence, 10)
        );
      });
      guarantorUsermapData = guarantorData[0];
      guarantorsOrgData = rowData.guarantors.filter(function (el: any) {
        return el.partyId === guarantorData[0].partyId;
      });
      guarantorSpecificData = !isNullOrUndefined(guarantorsOrgData)
        ? guarantorsOrgData[0]
        : null;
      const addressList = guarantorSpecificData.addressList
        ? guarantorSpecificData.addressList[0]
        : "";
      guarantorAddress =
        addressList &&
        [
          addressList?.addressNumber,
          addressList?.addressLine1,
          addressList?.addressLine2,
          addressList?.addressLine3,
          addressList?.city,
          addressList?.state,
          addressList?.pincode,
          addressList?.country
        ]
          .filter(Boolean)
          .join(", ");
    }
    // checking for primary borrower data if entity borrower data is not available
    if (!borrowerEntityData.length) {
      // filtering out primary borrower data from loanusermap data(borrowerData)
      const primaryBorrowerData = borrowerData.filter(function (el: any) {
        return el.isPrimary === true;
      });
      if (primaryBorrowerData?.length && rowData?.borrowers?.length) {
        const borrowerLoanUserMapData = primaryBorrowerData[0];
        const borrowersArrayData = rowData.borrowers.filter(function (el: any) {
          return el.partyId === borrowerLoanUserMapData.partyId;
        });
        const addressList = borrowersArrayData[0].addressList
          ? borrowersArrayData[0].addressList[0]
          : "";
        const contactList = borrowersArrayData[0].contactList
          ? borrowersArrayData[0].contactList[0]
          : "";
        const combinedAddress =
          addressList &&
          [
            addressList?.addressNumber,
            addressList?.addressLine1,
            addressList?.addressLine2,
            addressList?.addressLine3,
            addressList?.city,
            addressList?.state,
            addressList?.pincode,
            addressList?.country
          ]
            .filter(Boolean)
            .join(", ");
        const borrowerDataArray: any = borrowersArrayData[0];
        return {
          experiance: isGroundUp ? borrowerLoanUserMapData?.borrowerGUCExperience ? (isNaN(+borrowerLoanUserMapData?.borrowerGUCExperience) ? borrowerLoanUserMapData?.borrowerGUCExperience?.replace(/[-e]/g, '') : "") : ""
            : borrowerLoanUserMapData?.experience ?? "",
          heavyRehabExperience: !isNullOrUndefined(
            borrowerLoanUserMapData.heavyRehabExperience
          )
            ? borrowerLoanUserMapData.heavyRehabExperience
            : "",
          // borrowerGUCExperience: !isNullOrUndefined(borrowerLoanUserMapData.borrowerGUCExperience)
          // ? borrowerLoanUserMapData.borrowerGUCExperience
          // : "",
          primaryEmail: borrowersArrayData?.[0]?.contactList?.[0]?.email ?? "",
          borrowerBillingAddress: addressList ? combinedAddress : "",
          borrowerBillingPhone: contactList ? contactList.contactNumber : "",
          borrowerType:
            borrowersArrayData?.[0]?.partyType === "person" ? "Individual" : "",
          borrowingEntityName:
            !isNullOrUndefined(borrowerDataArray.firstName) &&
              !isNullOrUndefined(borrowerDataArray.lastName)
              ? borrowerDataArray.firstName.concat(
                " ",
                borrowerDataArray.lastName
              )
              : !isNullOrUndefined(borrowerDataArray.firstName)
                ? borrowerDataArray.firstName
                : !isNullOrUndefined(borrowerDataArray.lastName)
                  ? borrowerDataArray.lastName
                  : "",
          originalCreditScoreMedian: !isNullOrUndefined(borrowerLoanUserMapData)
            ? borrowerLoanUserMapData?.originalCreditScoreMedian?.toString()
            : null,
          originalCreditScoreReportDate: !isNullOrUndefined(
            borrowerLoanUserMapData
          )
            ? borrowerLoanUserMapData?.originalCreditScoreReportDate
            : "",
          foreignNationalString: !isNullOrUndefined(
            borrowerDataArray?.foreignNationalString
          )
            ? borrowerDataArray?.foreignNationalString
            : "",
          ownershipofEntity: !isNullOrUndefined(guarantorUsermapData)
            ? guarantorUsermapData?.ownershipOfEntity
            : "",
          ...(borrowerLoanUserMapData?.ssNumber && {
            ssNumber: borrowerLoanUserMapData.ssNumber
          }),
          ...(borrowerLoanUserMapData?.passportExpirationDate && {
            passportExpirationDate:
              borrowerLoanUserMapData.passportExpirationDate
          })
        };
      }
    }
    if (rowData?.borrowers?.length && borrowerEntityData?.length) {
      const borrowerEntityDataMap: any = new Map(
        borrowerEntityData.map((borrower: any) => [borrower.partyId, borrower])
      );
      let order: any = borrowerData.filter((item: any) => {
        return (
          borrowerEntityDataMap.get(item?.partyId)?.partyType === "account"
        );
      });
      order = order.sort((a: any, b: any) => {
        if (a.experience === b.experience) {
          return a.userSequence - b.userSequence;
        }
        return b.experience - a.experience;
      });
      let dataToBeShown: ObjectType[] = [
        borrowerEntityDataMap.get(order?.[0]?.partyId) || {}
      ];
      //sort datatobeshown based on experience andif it's same then on userSequence
      const entityBorrowerData = borrowerData.filter(function (el: any) {
        return order?.[0]?.partyId === el?.partyId;
      });
      const addressList = dataToBeShown[0].addressList
        ? dataToBeShown[0].addressList[0]
        : "";
      const borrowerContactList = dataToBeShown[0].contactList
        ? dataToBeShown[0].contactList[0]
        : "";
      const combinedAddress =
        addressList &&
        [
          addressList?.addressNumber,
          addressList?.addressLine1,
          addressList?.addressLine2,
          addressList?.addressLine3,
          addressList?.city,
          addressList?.state,
          addressList?.pincode,
          addressList?.country
        ]
          .filter(Boolean)
          .join(", ");
      const userMapData: any = dataToBeShown[0];
      const entityBorrowerExperiance = isGroundUp ? entityBorrowerData?.[0]?.borrowerGUCExperience
        : entityBorrowerData?.[0]?.experience;
      return {
        experiance: entityBorrowerExperiance ?? guarantorUsermapData?.experience ?? "",
        heavyRehabExperience: !isNullOrUndefined(
          entityBorrowerData[0].heavyRehabExperience
        )
          ? entityBorrowerData[0].heavyRehabExperience
          : "",
        // borrowerGUCExperience : !isNullOrUndefined(entityBorrowerData[0].borrowerGUCExperience)
        //   ? entityBorrowerData[0].borrowerGUCExperience
        //   : "",
        primaryEmail: dataToBeShown[0].contactList
          ? dataToBeShown[0].contactList.length !== 0
            ? !isNullOrUndefined(dataToBeShown[0].contactList[0].email)
              ? dataToBeShown[0].contactList[0].email
              : !isNullOrUndefined(guarantorSpecificData)
                ? guarantorSpecificData?.contactList
                  ? guarantorSpecificData?.contactList.length !== 0
                    ? guarantorSpecificData?.contactList[0]?.email
                    : ""
                  : ""
                : ""
            : ""
          : "",
        borrowerBillingAddress: addressList
          ? combinedAddress
          : !isNullOrUndefined(guarantorSpecificData)
            ? guarantorAddress
            : "",
        borrowerBillingPhone: borrowerContactList
          ? borrowerContactList.contactNumber
          : "",
        borrowerType: dataToBeShown[0].partyType
          ? dataToBeShown[0].partyType === "account"
            ? "Entity"
            : ""
          : "",
        borrowingEntityName: !isNullOrUndefined(userMapData.accountName)
          ? !isNullOrUndefined(userMapData.accountName)
            ? userMapData.accountName
            : !isNullOrUndefined(guarantorSpecificData.firstName) &&
              !isNullOrUndefined(guarantorSpecificData.lastName)
              ? guarantorSpecificData.firstName.concat(
                " ",
                guarantorSpecificData.lastName
              )
              : !isNullOrUndefined(guarantorSpecificData.firstName)
                ? guarantorSpecificData.firstName
                : !isNullOrUndefined(guarantorSpecificData.lastName)
                  ? guarantorSpecificData.lastName
                  : ""
          : "",
        originalCreditScoreMedian: !isNullOrUndefined(guarantorUsermapData)
          ? guarantorUsermapData?.originalCreditScoreMedian?.toString()
          : null,
        originalCreditScoreReportDate: !isNullOrUndefined(guarantorUsermapData)
          ? guarantorUsermapData?.originalCreditScoreReportDate
          : "",
        foreignNationalString: !isNullOrUndefined(
          guarantorSpecificData?.foreignNationalString
        )
          ? guarantorSpecificData?.foreignNationalString
          : "",
        ownershipofEntity: !isNullOrUndefined(guarantorUsermapData)
          ? guarantorUsermapData?.ownershipOfEntity
          : "",

        ...(guarantorUsermapData?.ssNumber && {
          ssNumber: guarantorUsermapData.ssNumber
        }),
        ...(guarantorUsermapData?.passportExpirationDate && {
          passportExpirationDate: guarantorUsermapData.passportExpirationDate
        })
      };
    }
    if (!rowData?.borrowers?.length) {
      return {
        experiance: "",
        primaryEmail: "",
        borrowerBillingAddress: "",
        borrowerBillingPhone: "",
        borrowingEntityName: "",
        originalCreditScoreMedian: "",
        originalCreditScoreReportDate: "",
        foreignNationalString: "",
        ownershipofEntity: "",
        ssNumber: "",
        passportExpirationDate: ""
      };
    }
  }
}
function getESIndexToDelete(arrayRef: any) {
  for (let i = 0; i < arrayRef.length; i++) {
    if (
      arrayRef[i].terms &&
      JSON.stringify(arrayRef[i].terms) &&
      JSON.stringify(arrayRef[i].terms).includes("originatorPartyId")
    ) {
      return i;
    }
  }
}

const getActiveInteractions: any = (objForQuery: any, interactionsObj: any) => {
  if (isLATUser()) {
    if (Object.values(interactionsObj).includes("Open Waivers"))
      objForQuery.push({
        range: {
          currentPendingAllWaiverCount: { gte: 1 }
        }
      });
    if (Object.values(interactionsObj).includes("Open Conditions"))
      objForQuery.push({
        range: {
          currentPendingAllExceptionCount: { gte: 1 }
        }
      });
    if (Object.values(interactionsObj).includes("Open Inquiries"))
      objForQuery.push({
        range: {
          currentPendingAllEnquiryCount: { gte: 1 }
        }
      });
  } else {
    if (Object.values(interactionsObj).includes("Open Waivers"))
      objForQuery.push({
        range: {
          currentPendingExternalWaiverCount: { gte: 1 }
        }
      });
    if (Object.values(interactionsObj).includes("Open Conditions"))
      objForQuery.push({
        range: {
          currentPendingExternalExceptionCount: { gte: 1 }
        }
      });
    if (Object.values(interactionsObj).includes("Open Inquiries"))
      objForQuery.push({
        range: {
          currentPendingExternalEnquiryCount: { gte: 1 }
        }
      });
  }
  const should = [...objForQuery];
  return should?.length > 0
    ? [
      {
        bool: {
          should
        }
      }
    ]
    : [];
};

const formatLoanListResponse = (loanList: any) => {
  return loanList?.map((row: any) => {
    let exceptionsPending = isLATUser()
      ? row._source?.currentPendingAllExceptionCount
      : row._source?.currentPendingExternalExceptionCount;
    exceptionsPending = exceptionsPending || 0;

    let enquiriesPending = isLATUser()
      ? row._source?.currentPendingAllEnquiryCount
      : row._source?.currentPendingExternalEnquiryCount;
    enquiriesPending = enquiriesPending || 0;

    let waiversPending = isLATUser()
      ? row._source.currentPendingAllWaiverCount
      : row._source.currentPendingExternalWaiverCount;
    waiversPending = waiversPending || 0;
    let toorval: any = {};
    if (row._source?.appraisal?.appraisal) {
      toorval = row._source.appraisal?.appraisal;
      delete toorval?.status;
    }
    const loanTableRow = {
      ...row._source.loan,
      tasks: row._source?.tasks || [],
      /* :
          (isRole(InternalRolesEnum.LAT_IR) ||
            isRole(InternalRolesEnum.LAT_QC)) &&
          row?.fields?.workflowStatus
            ? row.fields.workflowStatus[0] */
      workflowState: row._source.loan?.workflowState,
      enquiries: row._source.enquiries,
      qcRequired: row._source.loan?.qcRequired ? "Yes" : "No",
      assignedTo: row._source.loan?.assignedTo,
      ttfAssignedTo: row._source.loan?.ttfAssignedTo || "",
      toorakLoanId: row._source.loan?.loanDetailId?.loanId,
      toorakTradeID: row._source.loan?.loanDetailId?.tradeId,
      roleOfAssignee: row._source.loan?.roleOfAssignee,
      loanProcessType: row._source.loan?.loanProcessType,
      tapeToFileStatus: row._source.loan?.tapeToFileStatus || "",
      documentsTaggingStatus: row._source.tasks?.tag?.taskStatus,
      documentsSplitStatus: row._source.tasks?.split?.taskStatus,
      ttfReviewStatus: row._source.tasks?.review?.taskStatus,
      needEnhancedReview:
        row._source.result?.loanResult?.loanCharacterisations
          ?.needEnhancedReview ??
        row._source.result?.loanResult?.loanCharacterization
          ?.needEnhancedReview,
      dataReconcilationStatus: row._source.tasks?.reconcile?.taskStatus,
      budgetReviewStatus:
        row._source.tasks?.budgetReview?.taskStatus || "Not Applicable",
      budgetApproveStatus:
        row._source.tasks?.budgetApprove?.taskStatus || "Not Applicable",
      tagAndSplitAssignedTo: row._source.tasks?.tag?.assigneePartyId,
      reviewAssignedTo: row._source.tasks?.review?.assigneePartyId,
      reconcileAssignedTo: row._source.tasks?.reconcile?.assigneePartyId,
      budgetReviewAssignedTo:
        row._source.tasks?.budgetReview?.assigneePartyId,
      budgetApproveAssignedTo:
        row._source.tasks?.budgetApprove?.assigneePartyId,
      ttfVersion:
        row._source.loan.loanDetailId?.loanConfigId?.ttfVersionId == null
          ? "Old"
          : "New",
      dueDilligenceDate: row._source.loan?.dueDilligenceDate || "",
      loanTypeName: `${row._source.loan?.loanTypeId?.loanTypeName?.split("L")[0]
        } - ${row._source.loan?.loanInfo?.toorakProduct}`,
      loanTypeDescription:
        row._source.loan?.loanTypeId?.loanTypeDescription,
      loanType: row._source.loan?.loanType,
      loanPurpose: row._source.loan?.loanInfo?.loanPurpose,
      user:
        row._source?.borrowers !== undefined
          ? row._source?.borrowers[0]?.firstName
          : "",
      loanAmount: row._source.loan?.loanEconomics?.originalLoanAmount,
      interestRate:
        row._source.loan?.loanEconomics?.interestRateAsOfCutOffDate,
      loanStage: getLoanStage(row._source.loan?.loanStage),
      loanStageRaw: row._source.loan?.loanStage,
      loanCreateDate: row._source.loan?.loanCreationDate,
      updatedOn: row._source?.updatedOn,
      status: row._source.loan?.loanState,
      bundle: row._source.bundle,
      numberOfProperties: row._source.loan?.loanInfo?.noOfProperties,
      source: row._source.loan?.loanSource,
      originalLoanId:
        row._source.loan?.loanInfo?.primaryLoanId || toorval?.loanId,
      activeInteractions: getActiveInteractionsObject(row._source),
      totalBudgetAmount:
        row._source.loan?.loanEconomics?.totalBudgetAmount,
      cashOutAmount: !isNullOrUndefined(
        row._source.loan?.loanEconomics?.cashOutAmount
      )
        ? row._source.loan?.loanEconomics?.cashOutAmount
        : "0.00",
      includeOutOfPocketBudgetInARLTV:
        row._source.loan?.loanEconomics
          ?.includeOutOfPocketBudgetInARLTV ?? true,
      totalOriginationAndDiscountPoints:
        row._source.loan?.loanEconomics
          ?.totalOriginationAndDiscountPoints,
      accrualType: row._source.loan?.loanEconomics?.accrualType,
      cutOffDateMaximumLoanAmount:
        row._source.loan?.loanEconomics?.cutOffDateMaximumLoanAmount,
      cutOffDateLoanAmount:
        row._source.loan?.loanEconomics?.cutOffDateLoanAmount,
      originalMaximumLoanAmount:
        row._source.loan?.loanEconomics?.originalMaximumLoanAmount,
      financedInterestReserve:
        row._source.loan?.loanEconomics?.financedInterestReserve,
      pledgeOfEquity:
        row._source.loan?.loanInfo?.pledgeOfEquity !== null
          ? row._source.loan?.loanInfo?.pledgeOfEquity === true
            ? "Yes"
            : "No"
          : "",
      toorakProduct: row._source.loan?.loanInfo?.toorakProduct,
      extensionOptions:
        row._source.loan?.loanInfo?.extensionOptions === null
          ? "0"
          : row._source.loan?.loanInfo?.extensionOptions,
      crossCollaterlization:
        row._source.loan?.loanInfo?.crossCollaterlization !== null
          ? row._source.loan?.loanInfo?.crossCollaterlization === true
            ? "Yes"
            : "No"
          : "",
      recourse: getRecourseValueMapping(
        row._source.loan?.loanInfo,
        row._source.loan?.loanType
      ),
      recourseString: getRecourseValueMapping(
        row._source.loan?.loanInfo,
        row._source.loan?.loanType
      ),
      originalMaturityDate:
        row._source.loan?.loanInfo?.originalMaturityDate,
      firstPaymentDateOfLoan:
        row._source.loan?.loanInfo?.firstPaymentDateOfLoan,
      originationDate: row._source.loan?.loanInfo?.originationDate,
      loanSubmissionDate: row._source.loan?.loanSubmissionDate,
      initialLoanSubmissionDate:
        row._source.loan?.initialLoanSubmissionDate,
      cutOffDate: row._source.loan?.loanInfo?.cutOffDate,
      loanStructure: row._source.loan?.loanInfo?.loanStructure,
      nameOfGuarantorsAddition:
        row._source?.guarantors !== undefined &&
          row._source?.guarantors?.length > 1
          ? row._source?.guarantors[1]?.firstName
          : "",

      nameOfGuarantors: row._source?.guarantors?.length
        ? row._source.guarantors[0].firstName
        : "",

      numberOfGuarantors: row._source.guarantors?.length,
      contactList:
        row._source.borrowers?.length > 0 &&
          row._source.borrowers[0]?.contactList !== null
          ? row._source.borrowers[0]?.contactList[0]?.contactNumber
          : "",
      userBillingAddressId:
        row._source.loan?.loanUserMap !== undefined
          ? row._source.loan?.loanUserMap[1]?.billingAddressId
          : "",
      // added new fields

      interestOnlyPeriod:
        row._source.loan?.loanEconomics?.interestOnlyPeriod,
      initialRateAdjustmentPeriod:
        row._source.loan?.loanEconomics?.initialRateAdjustmentPeriod,
      initialPeriodicCap:
        row._source.loan?.loanEconomics?.initialPeriodicCap,
      initialPayAdjustmentPeriod:
        row._source.loan?.loanEconomics?.initialPayAdjustmentPeriod,
      grossArmMargin: row._source.loan?.loanEconomics?.grossArmMargin,
      debtServiceCoverageRatio:
        row._source.result?.loanResult?.loanEconomics?.dscr,
      originalLtc:
        row._source.result?.loanResult?.loanFeatures?.originalLtc,
      originalAsIsLtv:
        row._source.result?.loanResult?.loanFeatures?.originalAsIsLtv,
      originalAsRepairedLtv:
        row._source.result?.loanResult?.loanFeatures
          ?.originalAsRepairedLtv,
      effectiveStartDate:
        row._source.ruleVersions?.rateSheet?.effectiveStartDate,
      currentLoanBalance:
        row._source.loan?.loanEconomics?.currentLoanBalance,
      costToLoanRatio: row._source.loan?.loanEconomics?.costToLoanRatio,
      armProductType: row._source.loan?.loanEconomics?.armProductType,
      ARMIndex: row._source.loan?.loanEconomics?.armIndex,
      rentalCashflowRatio:
        row._source.properties?.[0]?.propertyEconomics
          ?.rentalCashflowRatio,
      annualMarketRent:
        row._source?.properties?.[0]?.propertyUnit?.[0]
          ?.annualMarketRent ?? "",
      annualHoaFee:
        row._source.properties?.[0]?.propertyEconomics?.annualHoaFee ??
        "",
      annualFloodInsurance:
        row._source.properties?.[0]?.propertyEconomics
          ?.annualFloodInsurance ?? "",
      financedBudgetAmount: !isNullOrUndefined(
        row._source.loan?.loanEconomics?.financedBudgetAmount
      )
        ? row._source.loan?.loanEconomics?.financedBudgetAmount
        : "0.00",
      appraisalReportedNoiNcf:
        row._source.properties?.[0]?.propertyEconomics
          ?.appraisalReportedNoiNcf ?? "",
      datePurchased:
        row._source.loan?.loanDetailId?.loanConfigId?.datePurchased,
      closingDate: row._source.bundle?.closingDate,
      dateUploaded:
        row._source.loan?.loanDetailId?.loanConfigId?.dateUploaded,
      appraisal: row._source.loan?.loanDetailId?.loanConfigId?.appraisal,
      streetView:
        row._source.loan?.loanDetailId?.loanConfigId?.streetView,
      originator: row._source?.originator?.accountName,
      emailSentToOriginator:
        row._source.loan?.loanDetailId?.loanConfigId
          ?.emailSentToOriginator === true
          ? "Yes"
          : row._source.loan?.loanDetailId?.loanConfigId
            ?.emailSentToOriginator === false
            ? "No"
            : "",
      zillow: row._source.loan?.loanDetailId?.loanConfigId?.zillow,
      truliaCrime:
        row._source.loan?.loanDetailId?.loanConfigId?.truliaCrime,
      tapeToFile:
        row._source.loan?.loanDetailId?.loanConfigId?.tapeToFile,
      teamIndiaTapetoFile:
        row._source.loan?.loanDetailId?.loanSummaryId
          ?.teamIndiaTapetoFile,
      dueDiligencePartyId:
        row._source.loan?.loanDetailId?.loanConfigId?.dueDiligencePartyId,

      // Property Fields
      squareFootage:
        row._source.properties?.[0]?.propertyinfo?.squareFootage,
      postRehabSquareFootage:
        row._source.properties?.[0]?.propertyinfo
          ?.postRehabSquareFootage ||
        toorval?.propertySquareFootageProposed,
      preRehabSquareFootage:
        row._source?.properties?.[0]?.propertyinfo
          ?.preRehabSquareFootage || toorval?.propertySquareFootageAsIs,
      propertyType:
        row._source.properties?.[0]?.propertyinfo?.propertyType,
      originalAsIsAppraisalValue:
        row._source.properties?.[0]?.propertyEconomics
          ?.originalAsIsAppraisalValue,
      originalAsRepairedAppraisedValue:
        row._source.properties?.[0]?.propertyEconomics
          ?.originalAsRepairedAppraisedValue ||
        toorval.lenderCalculatedAsRenovatedValue,
      grossPotentialRent:
        row._source.properties?.[0]?.propertyEconomics
          ?.grossPotentialRent,
      assignmentFees:
        row._source.properties?.[0]?.propertyEconomics?.assignmentFees,
      decliningMarkets:
        row._source.properties?.[0]?.propertyEconomics
          ?.decliningMarkets || "No",
      propertyAcquisitionDate:
        row._source.properties?.[0]?.propertyinfo
          ?.propertyAcquisitionDate,
      changeInUseCase:
        row._source.properties?.[0]?.propertyinfo?.changeInUseCase !==
          null
          ? row._source.properties?.[0]?.propertyinfo?.changeInUseCase ===
            true
            ? "Yes"
            : row._source.properties?.[0]?.propertyinfo
              ?.changeInUseCase === false
              ? "No"
              : ""
          : "",

      propertyUnits:
        row._source.properties?.[0]?.propertyinfo?.propertyUnits,
      recentPropertyValuationDate:
        row._source.properties?.[0]?.propertyinfo
          ?.recentPropertyValuationDate,
      costBasis:
        row._source.properties?.[0]?.propertyEconomics?.costBasis,
      annualPropertyTaxes:
        row._source.properties?.[0]?.propertyEconomics
          ?.annualPropertyTaxes,
      subsequentPeriodicCap:
        row._source.loan?.loanEconomics?.subsequentPeriodicCap,
      occupiedPercentage:
        row._source.properties?.[0]?.propertyinfo?.occupiedPercentage,

      rentDescription:
        row._source.properties?.[0]?.propertyUnit?.[0]?.rentDescription ??
        "",
      rateType: row._source.loan?.loanEconomics?.rateType,
      prepaymentPenaltyMonths:
        row._source.result?.loanResult?.loanEconomics
          ?.prePaymentPenaltyMonths,
      prepayPenaltyType:
        row._source.loan?.loanEconomics?.prepayPenaltyType,
      rateAdjustmentFrequency:
        row._source.loan?.loanEconomics?.rateAdjustmentFrequency,
      paymentAdjustmentFrequency:
        row._source.loan?.loanEconomics?.paymentAdjustmentFrequency,
      originalMonthlyPiPayment:
        row._source.loan?.loanEconomics?.originalMonthlyPiPayment,
      annualHazardInsurance:
        row._source.properties?.[0]?.propertyEconomics
          ?.annualHazardInsurance,
      purchasePrice:
        row._source.properties?.[0]?.propertyEconomics?.purchasePrice,
      lifeRateCap: row._source.loan?.loanEconomics?.lifeRateCap,
      originatorPartyId: row._source.loan?.loanDetailId.originatorPartyId,
      toorakLoanTypeRehab: row._source?.result?.loanResult
        ?.loanCharacterisations?.loanCharacterisation
        ? row._source?.result?.loanResult?.loanCharacterisations
          ?.loanCharacterisation
        : "",
      toorakLoanType:
        row._source?.result?.loanResult?.loanCharacterization
          ?.toorakLoanType !== "null null null null"
          ? row._source?.result?.loanResult?.loanCharacterization
            ?.toorakLoanType
          : "",
      ...getBorrowerData(row._source),
      originalCreditScoreMedian:
        row._source?.result?.loanResult?.borrowerInfo
          ?.originalCreditScoreMedian?.toString() ?? null,
      originalCreditScoreReportDate:
        row._source?.result?.loanResult?.borrowerInfo
          ?.originalCreditScoreReportDate ?? "",
      foreignNationalString:
        row._source?.borrowerDetails?.customer?.foreignNationalString ??
        "",
      enquiriesPending,
      errorsPending: row._source.loan?.errorsPending,
      fieldsPending: row._source.loan?.fieldsPending,
      waiversPending,
      exceptionsPending,
      loanCreatedBy: row._source.loan?.loanCreatedBy,
      firstConditionPostedDate:
        row._source?.firstOpenConditionDate && !isILP()
          ? getFirstConditionPosted(
            row._source?.firstOpenConditionDate,
            []
          )
          : "",

      organisationPartyId: row._source?.originator?.partyId,
      organisation: row._source?.originator?.accountName,
      properties: row._source.properties || [],
      bundleCreatedDate: row._source.bundle?.created_on,
      guarantors: row._source.guarantors,
      loanUserMap: row._source.loan?.loanUserMap,
      originatorUser: row._source?.originator,
      lifetimeMaxRate: row._source.loan?.loanEconomics?.lifetimeMaxRate
        ? row._source.loan?.loanEconomics?.lifetimeMaxRate
        : "",
      monthlyTaxAmount:
        row._source.loan?.loanDetailId?.loanSummaryId?.monthlyTaxAmount ??
        "",
      monthlyInsuranceAmount:
        row._source.loan?.loanDetailId?.loanSummaryId
          ?.monthlyInsuranceAmount ?? "",
      taxes: row._source.loan?.loanDetailId?.loanSummaryId?.taxes ?? "",
      insurance: row._source.loan?.loanDetailId?.loanSummaryId?.insurance,
      taxesandInsuranceDue:
        row._source.loan?.loanDetailId?.loanSummaryId
          ?.taxesandInsuranceDue ?? "",
      escrowFund:
        row._source.loan?.loanDetailId?.loanSummaryId?.escrowFund ?? "",
      lastPaymentDate:
        row._source.loan?.loanDetailId?.loanSummaryId?.lastPaymentDate ??
        "",
      nextPaymentDue:
        row._source.loan?.loanDetailId?.loanSummaryId?.nextPaymentDue ??
        "",
      updatedEscrowBalance:
        row._source.loan?.loanDetailId?.loanSummaryId
          ?.updatedEscrowBalance ?? "",
      leaseStatus:
        row._source.loan?.loanDetailId?.loanSummaryId?.leaseStatus ?? "",
      seasoned:
        row._source.loan?.loanDetailId?.loanSummaryId?.seasoned ?? "",
      paymentHistoryandUPBChecked:
        row._source.loan?.loanDetailId?.loanSummaryId
          ?.paymentHistoryandUPBChecked ?? "",
      overallLoanEligibility:
        row._source.result?.loanResult?.finalCalculation
          ?.overallLoanEligibility,
      firstLoss:
        row._source.result?.loanResult?.firstLoss?.firstLossPercentage,
      holdback:
        row._source.result?.loanResult?.firstLoss
          ?.totalCreditReserveHoldback,
      rateLockedDate:
        row._source.loan.loanDetailId?.loanConfigId?.rateLockedDate,
      rateLockRequestedDate: row._source.loan.loanDetailId?.rateLocks?.[0]?.created_on,
      rateLockStatus: row._source.loan?.loanType === "30 Year Loan" ?   getRateLockRequestedStatus(row._source.loan.loanDetailId?.rateLocks?.[0]?.rateLockStatus) : "",
      master:
        row._source.loan.loanDetailId?.loanConfigId?.appraisal ===
          "Yes" &&
          row._source.loan.loanDetailId?.loanConfigId?.streetView ===
          "Yes" &&
          ((row._source.loan.loanDetailId?.loanConfigId?.ttfVersionId ==
            null &&
            row._source.loan.loanDetailId?.loanConfigId?.tapeToFile ===
            "Yes") ||
            row._source.loan.loanDetailId?.loanSummaryId
              ?.teamIndiaTapetoFile === "Yes") &&
          row._source.loan.loanDetailId?.loanConfigId?.truliaCrime ===
          "Yes" &&
          row._source.loan.loanDetailId?.loanConfigId?.zillow === "Yes"
          ? "Pass"
          : "--",
      grade:
        row._source?.grades?.find(
          (val: any) =>
            val?.loanStage === row._source.loan?.loanStage &&
            val?.name?.toUpperCase() === "SECURITIZATION_GRADE"
        )?.value ?? "",
      actualLoanType: row._source.loan?.loanTypeId?.loanTypeName,
      lastUpdatedInfo: {
        lastUpdatedToorak: row?._source.lastUpdatedToorak,
        lastUpdatedOriginator: row?._source.lastUpdatedOriginator,
        enquiries: row?._source?.enquiries,
        exceptions: row?._source?.exceptions,
        waivers: row?._source?.waivers
      },
      onHoldBy: row._source?.loan?.onHoldBy,
      riskScoresVersion: row._source?.result?.loanResult?.riskScores?.riskScoresVersion,
      riskScore: row._source?.result?.loanResult?.riskScores?.riskScoresVersion === "2.0" ?
        `${(parseFloat(row._source?.result?.loanResult?.riskScores?.totalAggregateRisk ?? "0.0") * 100).toFixed(3)}%` : row._source?.result?.loanResult?.riskScores?.totalAggregateRisk,
      inStateLocRisk: row._source?.result?.loanResult?.riskScores?.riskScoresVersion === "2.0" ?
        row._source?.result?.loanResult?.riskScoreCalculations?.inState : row._source?.result?.loanResult?.riskScores?.inStateLocRisk,
      takeoutPartner: row._source.loan?.loanInfo?.takeoutPartner,
      finalToorakYield:
        row._source?.result?.loanResult?.toorakInterests
          ?.finalToorakYield,
      pricingDiscount:
        row._source?.result?.loanResult?.toorakInterests?.pricingDiscount,
      riskBucket: row._source?.result?.loanResult?.riskScores?.riskBucket,
      onHoldDetails: row._source?.onHoldDetails,
      loanLandmarks: row._source?.loan?.loanDetailId?.loanLandmarks,
      finalToorakPrice:
        row._source?.result?.loanResult?.loanPricing?.finalToorakPrice,
      result: row._source?.result,
      ...toorval,
      toorval,
      toorakOriginatorPartyId: toorval?.originatorPartyId,
      rateLockSyncStatus: row._source?.loan?.rateLockSyncStatus
    };
    // if (getCookie("GUIDE") === "selected") loanList.push(loanTableRow);
    // else if (
    //   !(
    //     loanTableRow.status === "submit" &&
    //     loanTableRow.loanStage === "Guideline Evaluation"
    //   )
    // )
    return loanTableRow;
  });
};

const getLoanListFormatted = (requestBody: any, needRaw: boolean) => {
  const url = `${getConfig().apiUrl}/search/${getConfig().aggregateUrl
    }/_search`;
  return new Promise((resolve) => {
    publicClient
      .post(url, requestBody)
      .then((response: any) => {
        if (!response?.data?.response?.hits?.hits)
          resolve({
            loanList: [],
            loanCount: 0,
            loanAmount: 0
          });
        if (needRaw) resolve(response?.data);
        const frmtList = formatLoanListResponse(response?.data?.response?.hits?.hits ?? []);
        resolve({
          loanList: frmtList,
          loanCount: response.data.response.hits?.total?.value,
          loanAmount:
            response?.data.response?.aggregations?.totalLoanAmount?.value
        });
      })
      .catch((err) => {
        console.log(err, "error getting loan list");
        resolve({});
      });
  });
};

export function getMultiSearchedLoanList(
  searchObject: any,
  searchedValue?: any,
  advanceSearch: boolean = false,
  sourceList: string[] = [],
  stages?: any,
  timeInterval?: any,
  toggleArr?: any[],
  tabFilter?: any,
  needQuery?: boolean,
  nonDispatch?: boolean,
  sortConfig?: any,
  mustNotStates?: any[],
  loansArray: any[] = [],
  arraySize?: any,
  callBack?: any,
  loaderVal: boolean = true,
  mustQuery?: any,
  ignoreLoanIds?: string[]
): any {
  const { Purchased, Rejected, Withdrawn } = LoanStatusEnum;
  let originalToggles: any = [Purchased, Rejected, Withdrawn];
  if (mustNotStates?.length)
    originalToggles = [...originalToggles, ...mustNotStates];
  const mustNotToggle: any =
    toggleArr &&
    toggleArr?.length &&
    originalToggles.filter((item: any) => !toggleArr.includes(item));
  let stagesParam = stages.length;
  let objForQuery = [] as any;
  let should = [] as any;
  let mustNotVal: any = {};
  // let workflowStatus: any = {};
  let filter: any = {};
  // const selectionss: any = [];
  let hasTfOrLoanProcess = false;
  let hasWhOrLoanProcess = false;
  // Modifying Due Dilegence Review to Review In Progress
  const searchObjectMod = JSON.parse(
    JSON.stringify(searchObject?.filterSelections ?? {})
  );
  if (searchObject !== undefined) {
    const searchFilter: any[] = [];
    Object.keys(searchObjectMod?.filterSelections ?? {}).forEach(
      (key: string) => {
        if (key.includes("bool")) {
          searchFilter.push({ bool: searchObjectMod.filterSelections.bool });
        } else if (
          key.includes("_searchBox") &&
          searchObjectMod.filterSelections[key].length === 1 &&
          !key.includes("loanId") &&
          !key.includes("tradeId") &&
          !key.includes("primaryLoanId") &&
          !key.includes("loanCharacterisation")
        ) {
          searchFilter.push({
            match_phrase_prefix: {
              [key.replace(".raw_searchBox", "")]: {
                query: searchObjectMod.filterSelections[key][0],
                analyzer: "simple"
              }
            }
          });
        } else if (key.includes("appraisal.appraisal.appraisalType.raw")) {
        } else if (
          key.includes("properties.propertyinfo.valuationSource.raw")
        ) {
        } else if (key.includes("properties.propertyinfo.propertyType.raw")) {
          searchFilter.push({
            nested: {
              path: "properties",
              query: {
                bool: {
                  must: {
                    terms: {
                      [key.replace("_searchBox", "")]:
                        searchObjectMod.filterSelections[key]
                    }
                  }
                }
              }
            }
          });
        } else if (
          key.includes("properties.propertyinfo.propertyAcquisitionDate") ||
          key.includes("properties.propertyinfo.recentPropertyValuationDate")
        ) {
          searchFilter.push({
            nested: {
              path: "properties",
              query: {
                range: {
                  [key]: searchObjectMod.filterSelections[key][0]
                }
              }
            }
          });
        } else if (
          key.includes("loan.loanCreationDate") ||
          key.includes("updatedOn") ||
          key.includes("loan.loanInfo.originationDate") ||
          key.includes("loan.loanInfo.cutOffDate") ||
          key.includes("loan.loanInfo.firstPaymentDateOfLoan") ||
          key.includes("loan.loanInfo.originalMaturityDate") ||
          key.includes("loan.loanDetailId.loanSummaryId.lastPaymentDate") ||
          key.includes("loan.loanDetailId.loanConfigId.dateUploaded") ||
          key.includes("loan.loanSubmissionDate.raw") ||
          key.includes("loan.rateLockExpiryDate") ||
          key.includes("loan.initialLoanSubmissionDate") ||
          key.includes("ruleVersions.rateSheet.effectiveStartDate.raw") ||
          key.includes("loan.loanDetailId.rateLocks.created_on") ||
          key.includes("loan.loanDetailId.loanConfigId.rateLockedRateSheet")
        ) {
          searchFilter.push({
            range: {
              [key]: searchObjectMod.filterSelections[key][0]
            }
          });
        } else if (key.includes("loan.rateLockSyncStatus.raw")) {
          let val = [];
          if (searchObjectMod.filterSelections[key][0] === "Yes") {
            val = ["ACTIVE"];
          } else if (searchObjectMod.filterSelections[key][0] === "Requested") {
            val = ["PENDING"];
          }
          else {
            val = ["EXPIRED", "NOT_APPLIED", "REJECTED"];
          }

          searchFilter.push({
            terms: {
              [key]: val
            }
          });
          searchFilter.push({
            terms: {
              "loan.loanType.raw": ["30 Year Loan"]
            }
          });
        } else if (key.includes("loan.loanDetailId.rateLocks.rateLockStatus.raw")){
          let val : any = [];
          if (searchObjectMod.filterSelections[key][0] === "APPROVED") {
            val = ["APPROVED", "EXPIRED"];
          } else if (searchObjectMod.filterSelections[key][0] === "PENDING") {
            val = ["PENDING"];
          } else if (searchObjectMod.filterSelections[key][0] === "REJECTED") {
            val = ["REJECTED"];
          }
          searchFilter.push({
            terms: {
              [key]: val
            }
          });
          searchFilter.push({
            terms: {
              "loan.loanType.raw": ["30 Year Loan"]
            }
          });
        } else if (key.includes("activeInteractions")) {
          const interactionsObj =
            searchObjectMod.filterSelections.activeInteractions;
          objForQuery = getActiveInteractions(objForQuery, interactionsObj);
        } /*  else if (isILP() && key === "loan.workflowState.raw") {
          const selections = [...searchObjectMod.filterSelections[key]];
          if (
            isRole(InternalRolesEnum.LAT_IR) &&
            selections.includes(InternalDDStatusEnum.IRCompleted)
          ) {
            selections.push(
              ...[
                InternalDDStatusEnum.UnAssignedQC,
                InternalDDStatusEnum.QCPending,
                InternalDDStatusEnum.QCHold,
                InternalDDStatusEnum.QCInProgress,
                InternalDDStatusEnum.ApproverPending
              ]
            );
          } else if (
            isRole(InternalRolesEnum.LAT_QC) &&
            selections.includes(InternalDDStatusEnum.QCCompleted)
          ) {
            selections.push(...[InternalDDStatusEnum.ApproverPending]);
          }
          searchFilter.push({
            terms: {
              [key.replace("_searchBox", "")]: selections
            }
          });
         } */ else if (
          key.includes("loan.loanDetailId.loanConfigId.datePurchased")
        ) {
          filter = {
            filter: {
              script: {
                script: {
                  source:
                    "if(doc['loan.loanDetailId.loanConfigId.datePurchased'].size()==0 ) { return params.nullValue;} def dt = doc['loan.loanDetailId.loanConfigId.datePurchased'].value; def dte= new Date(dt); def sf = new SimpleDateFormat(params.format); def gte = sf.parse(params.gte); def lte = sf.parse(params.lte); if(dte.getTime()>=gte.getTime() && dte.getTime() <=(86399999+lte.getTime())){return true;} return false;",
                  params: searchObjectMod.filterSelections[key][0]
                }
              }
            }
          };
        } else if (
          key.includes("borrowerDetails.loanUser.originalCreditScoreReportDate")
        ) {
          filter = {
            filter: {
              script: {
                script: {
                  source:
                    "if(doc['borrowerDetails.loanUser.originalCreditScoreReportDate'].size()==0 ) { return params.nullValue;} def dt = doc['borrowerDetails.loanUser.originalCreditScoreReportDate'].value; def dte= new Date(dt); def sf = new SimpleDateFormat(params.format); def gte = sf.parse(params.gte); def lte = sf.parse(params.lte); if(dte.getTime()>=gte.getTime() && dte.getTime() <=(86399999+lte.getTime())){return true;} return false;",
                  params: searchObjectMod.filterSelections[key][0]
                }
              }
            }
          };
        }
        // else if (key.includes("exceptions.createdOn.raw")) {
        //   filter = {
        //     filter: {
        //       script: {
        //           script: {
        //               source: "if(doc['exceptions.createdOn'].size()>0 && params.satisfyingValue) { long firstDate=doc['exceptions.createdOn'][0];for(x in doc['exceptions.createdOn']){if(x<firstDate){firstDate=x.createdOn}}  def sf = new SimpleDateFormat(params.format); def gte = sf.parse(params.gte); def lte = sf.parse(params.lte); if(firstDate>=gte.getTime() && firstDate <=(86399999+lte.getTime()) && params.satisfyingValue){return true;} else {return false;}} else if(doc['exceptions.createdOn'].size()<=0){ return params.nullValue;} return false;",
        //               params: _searchObject.filterSelections[key][0]
        //           }
        //       }
        //   }
        //   };
        // }
        else if (key.includes("firstOpenConditionDate")) {
          if (
            !searchObjectMod.filterSelections[key][0]?.gte &&
            !searchObjectMod.filterSelections[key][0]?.lte
          ) {
            mustNotVal = searchObjectMod.filterSelections[key][0];
          } else {
            searchFilter.push({
              range: {
                [key]: searchObjectMod.filterSelections[key][0]
              }
            });
          }
        } else if (
          key.includes(
            "result.loanResult.loanCharacterisations.loanCharacterisation.raw_searchBox"
          )
        ) {
          searchFilter.push({
            bool: {
              should: [
                {
                  match_phrase_prefix: {
                    "result.loanResult.loanCharacterisations.loanCharacterisation":
                    {
                      query: searchObjectMod.filterSelections[key]?.[0]
                    }
                  }
                },
                {
                  match_phrase_prefix: {
                    "result.loanResult.loanCharacterization.toorakLoanType": {
                      query: searchObjectMod.filterSelections[key]?.[0]
                    }
                  }
                }
              ]
            }
          });
        } else if (
          key.includes("loan.loanDetailId.fundingType.raw") ||
          key.includes("loan.loanProcessType.raw")
        ) {
          hasTfOrLoanProcess = true;
        } else if (
          key.includes("loan.loanDetailId.isWarehouseFunded") ||
          key.includes("loan.loanProcessType.raw")
        ) {
          hasWhOrLoanProcess = true;
        } else if (key.includes("bundle.closingDate")) {
          searchFilter.push({
            range: {
              [key]: searchObjectMod.filterSelections[key][0]
            }
          });
        } else {
          if (key === "loan.loanState.raw") {
            const index =
              searchObjectMod.filterSelections[key].indexOf(
                "Review In Progress"
              );
            if (index > -1) {
              searchObjectMod.filterSelections[key][index] =
                "Due Diligence Review";
            }
          }
          if (
            key !==
            "result.loanResult.loanCharacterisations.needEnhancedReview.raw"
          ) {
            searchFilter.push({
              terms: {
                [key.replace("_searchBox", "")]:
                  searchObjectMod.filterSelections[key].filter(
                    (item: any) => item !== "Select All"
                  )
              }
            });
          } else {
            searchFilter.push({
              bool: {
                should: [
                  {
                    terms: {
                      [key]: searchObjectMod.filterSelections[key]
                    }
                  },
                  {
                    terms: {
                      "result.loanResult.loanCharacterization.needEnhancedReview.raw":
                        searchObjectMod.filterSelections[key]
                    }
                  }
                ]
              }
            });
          }
        }
      }
    );
    if (searchFilter.length) objForQuery = [...objForQuery, ...searchFilter];
  }

  /** Adding Table fund and loan process filters - Start */
  const finalQuery: ObjectType = {
    bool: {
      should: []
    }
  };

  if (hasTfOrLoanProcess) {
    if (
      searchObjectMod?.filterSelections?.["loan.loanDetailId.fundingType.raw"]
    ) {
      const tfQuery = {
        terms: {
          "loan.loanDetailId.fundingType.raw":
            searchObjectMod?.filterSelections?.[
            "loan.loanDetailId.fundingType.raw"
            ]
        }
      };
      finalQuery.bool.should.push(tfQuery);
    }

    if (searchObjectMod?.filterSelections?.["loan.loanProcessType.raw"]) {
      const loanProcessQuery = {
        terms: {
          "loan.loanProcessType.raw":
            searchObjectMod?.filterSelections?.["loan.loanProcessType.raw"]
        }
      };
      finalQuery.bool.should.push(loanProcessQuery);
    }
  }

  if (hasWhOrLoanProcess) {
    if (
      searchObjectMod?.filterSelections?.["loan.loanDetailId.isWarehouseFunded"]
    ) {
      const whQuery = {
        terms: {
          "loan.loanDetailId.isWarehouseFunded": [true]
        }
      };
      finalQuery.bool.should.push(whQuery);
    }

    if (searchObjectMod?.filterSelections?.["loan.loanProcessType.raw"]) {
      const loanProcessQuery = {
        terms: {
          "loan.loanProcessType.raw":
            searchObjectMod?.filterSelections?.["loan.loanProcessType.raw"]
        }
      };
      finalQuery.bool.should.push(loanProcessQuery);
    }
  }
  if (finalQuery.bool.should?.length) {
    objForQuery.push(finalQuery);
  }

  /** Adding Table fund and loan process filters - End */

  const includesMultiDimension = (arr: {}, str: string) =>
    arr !== undefined && JSON.stringify(arr).includes(str);
  if (!isILP()) {
    if (tabFilter) {
      if (tabFilter === "waiversPending") {
        const tabQuery = isLATUser()
          ? {
            range: {
              currentPendingAllWaiverCount: {
                gte: 1
              }
            }
          }
          : {
            range: {
              currentPendingExternalWaiverCount: {
                gte: 1
              }
            }
          };
        objForQuery.push(tabQuery);
      } else if (tabFilter === "exceptionsPending") {
        const tabQuery = isLATUser()
          ? {
            range: {
              currentPendingAllExceptionCount: {
                gte: 1
              }
            }
          }
          : {
            range: {
              currentPendingExternalExceptionCount: {
                gte: 1
              }
            }
          };
        objForQuery.push(tabQuery);
      } else if (tabFilter === "enquiriesPending") {
        const tabQuery = isLATUser()
          ? {
            range: {
              currentPendingAllEnquiryCount: {
                gte: 1
              }
            }
          }
          : {
            range: {
              currentPendingExternalEnquiryCount: {
                gte: 1
              }
            }
          };
        objForQuery.push(tabQuery);
      } else if (tabFilter === "fieldsPending") {
        const tabQuery = {
          range: {
            "loan.fieldsPending": {
              gte: 1
            }
          }
        };

        objForQuery.push(tabQuery);
      } else if (tabFilter === "errorsPending") {
        const tabQuery = {
          range: {
            "loan.errorsPending": {
              gte: 1
            }
          }
        };

        objForQuery.push(tabQuery);
      } else {
        objForQuery.push(tabFilter);
      }
    }
    setCookie("DSCR", "DscrNotSelected");
    if (
      includesMultiDimension(searchObjectMod, "30 Year Loan") &&
      includesMultiDimension(searchObjectMod, "loan.loanType.raw")
    ) {
      setCookie("DSCR", "DscrSelected");
    }
    if (!includesMultiDimension(searchObjectMod, "30 Year Loan")) {
      setCookie("DSCR", "DscrNotSelected");
    }

    setCookie("Bridge", "BridgeNotSelected");
    if (
      includesMultiDimension(searchObjectMod, "Bridge Loan") &&
      includesMultiDimension(searchObjectMod, "loan.loanType.raw")
    )
      setCookie("Bridge", "BridgeSelected");
    if (!includesMultiDimension(searchObjectMod, "Bridge Loan"))
      setCookie("Bridge", "BridgeNotSelected");
  }
  if (searchedValue) {
    objForQuery.push({
      bool: {
        // should: getGlobalResultObject(searchedValue, advanceSearch)
        should: getGlobalResultObject(searchedValue)
      }
    });
  }
  let accountIds: any = localStorage.getItem("account_ids");
  accountIds = JSON.parse(accountIds);
  if (getCookie("loginFlow") === "originator") {
    objForQuery.push({
      terms: {
        "loan.loanDetailId.originatorPartyId.raw": [getCookie("org_id")]
      }
    });
  } else if (!isRole(ToorvalRoles.APPRAISER)) {
    objForQuery.push({
      terms: {
        "loan.loanDetailId.originatorPartyId.raw": accountIds
      }
    });
  }
  const mustnotArray: any = [];
  if (!isILP()) {
    if (stagesParam === 1 && stages[0] === "ON-HOLD") {
      objForQuery.push(onHoldQuery);
    } else {
      objForQuery.push(noOnHoldQuery);
    }
    handleRolesPrivileges(mustnotArray);
  }
  if (!stages.includes("ON-HOLD")) {
    objForQuery.push({
      terms: {
        "loan.loanStage.raw": stages
      }
    });
  }
  if (isILP()) {
    const appendedQueries: any = appendILPQueries(
      objForQuery,
      timeInterval,
      mustnotArray
    );
    should = appendedQueries.should;
    // workflowStatus = appendedQueries.workflowStatus;
  }
  if (!isILP()) {
    if (mustNotToggle && mustNotToggle.length !== 0) {
      mustnotArray.push({
        terms: {
          "loan.loanState.raw": mustNotToggle
        }
      });
    } else if (toggleArr && toggleArr.length === 0)
      mustnotArray.push({
        terms: {
          "loan.loanState.raw": originalToggles
        }
      });
  }
  if (Object.keys(mustNotVal).length) {
    mustnotArray.push(mustNotVal);
  }

  let query: any = {};
  if (isLATUser()) {
    if (!isILP()) {
      mustnotArray.push(
        {
          terms: {
            "loan.loanState.raw":
              stages[0] === LoanStage.fes && isLATUser()
                ? [LoanStatusEnum.DataEntry, LoanStatusEnum.ExcelError]
                : [LoanStatusEnum.ExcelError]
          }
        },
        stagesParam === 2
          ? appendToQueryForLATToggle(stagesParam)
          : stagesParam === 3
            ? appendToQueryForLATToggle(stagesParam)
            : "",
        appendToQueryForLAT1(),
        appendToQueryForLAT2()
      );
    }
    const must_not =
      stages.length === 1 && stages[0] !== "ON-HOLD"
        ? [
          {
            terms: {
              "loan.loanState.raw":
                stages[0] === LoanStage.fes
                  ? [LoanStatusEnum.ExcelError, LoanStatusEnum.DataEntry]
                  : [LoanStatusEnum.ExcelError]
            }
          },
          {
            terms: {
              "loan.loanStage.raw": [LoanStage.pre, LoanStage.post]
            }
          }
        ]
        : mustnotArray;
    query = {
      bool: {
        must: objForQuery,
        must_not
      }
    };
    if (should.length) {
      query.bool.should = should;
    }
  } else {
    let must_not =
      stages.length === 1 && stages[0] !== "ON-HOLD"
        ? [
          {
            terms: {
              "loan.loanStage.raw": [LoanStage.pre, LoanStage.post]
            }
          }
        ]
        : mustnotArray;
    if (ignoreLoanIds) {
      must_not = [
        ...must_not,
        {
          terms: {
            "loan.loanDetailId.toorakLoanId.raw": ignoreLoanIds
          }
        },
        {
          terms: {
            "loan.loanDetailId.loanId.raw": ignoreLoanIds
          }
        }
      ];
    }
    query = {
      bool: {
        must: objForQuery,
        must_not
      }
    };
  }

  if (mustQuery?.length) {
    query = {
      bool: {
        ...query.bool,
        must: [...query.bool.must, ...mustQuery]
      }
    };
  }

  if (isRole(UserRole.LAT_TREASURER)) {
    const indexToRemove = getESIndexToDelete(query.bool.must);
    query.bool.must.splice(indexToRemove, 1);
  }
  const searchFes = {
    terms: {
      "loan.loanStage.raw": [LoanStage.fes]
    }
  };
  if (!isILP()) {
    if (stages && stages.length === 2) {
      setCookie("GUIDE", "notSelected");
      stagesParam = 2;
      mustnotArray.push({
        terms: {
          "loan.loanStage.raw": [LoanStage.fes]
        }
      });
    } else if (stages && stages.length === 1 && stages[0] !== "ON-HOLD") {
      stagesParam = 1;
      setCookie("GUIDE", "selected");
      mustnotArray.push({
        terms: {
          "loan.loanStage.raw": [LoanStage.pre, LoanStage.post]
        }
      });
    } else {
      setCookie("GUIDE", "selected");
    }
    const stringifySearchFes = JSON.stringify(searchFes);
    if (includesMultiDimension(objForQuery, stringifySearchFes))
      setCookie("GUIDE", "selected");
    else setCookie("GUIDE", "notSelected");
  }

  const scriptFields: any = {};
  scriptFields.loanDynamicStatus = {
    script: {
      source:
        "if(doc['loan.lastLoanStatusUpdateDate'].empty) { return 0} long days = ((new Date().getTime() - doc['loan.lastLoanStatusUpdateDate'].value.millis) / (1000*60*60*24)); return days"
    }
  };
  // if (Object.keys(workflowStatus)?.length) {
  //   scriptFields.workflowStatus = workflowStatus;
  // }
  const aggsQuery: any = {
    totalLoanAmount: {
      sum: {
        script: {
          source:
            "if (doc.containsKey('loan.loanEconomics.originalLoanAmount.raw') && doc['loan.loanEconomics.originalLoanAmount.raw'].size() > 0) {return Double.parseDouble(doc['loan.loanEconomics.originalLoanAmount.raw'].value.replace(',', '').replace('$', ''));} else {return 0;}"
        }
      }
    }
  };

  if (Object.keys(filter).length) {
    Object.assign(query.bool, filter);
  }

  const { count, page, sort, sortColumn } = sortConfig;
  const defaultSortQuery = isILP()
    ? [{ "loan.loanSubmissionDate.raw": "ASC" }, "_score"]
    : [{ updatedOn: "desc" }, "_score"];
  appendToorvalRules(query);
  const requestBody = {
    aggs: aggsQuery,
    script_fields: scriptFields,
    query,
    track_total_hits: true,
    sort:
      sort && sort !== "NONE"
        ? getQueryForSort(sort, sortColumn)
        : defaultSortQuery,
    size: arraySize || count || 20,
    _source: sourceList?.length ? sourceList : ["*"],
    from: page || 0
  };
  if (needQuery) {
    return requestBody;
  }
  if (nonDispatch) {
    return getLoanListFormatted(requestBody, false);
  }
  return (dispatch: any) => {
    loaderVal && dispatch(showLoader());
    getLoanListFormatted(requestBody, !!callBack).then((response: any) => {
      if (response && callBack) {
        callBack(response, page || 0);
        loaderVal && dispatch(hideLoader());
      } else {
        dispatch(
          loanListMultiDispatch({
            loanLists: [...loansArray, ...(response?.loanList || [])],
            pageCount: page || 0,
            loanCount: response?.loanCount,
            loanAmount: response?.loanAmount,
            tab: tabFilter
          })
        );
        dispatch(
          setRawLoanListData({
            loanLists: response?.hits?.hits,
            pageCount: page || 0
          })
        );
        loaderVal && dispatch(hideLoader());
      }
    });
  };
}

// export const loanListMultiDispatch = (payload: {
//   loanLists: any[];
//   pageCount: number;
//   loanCount: any;
//   loanAmount: any;
//   tab: string;
// }) => {
//   return (dispatch: any) => {
//     dispatch({
//       type: LOAN_LIST_MULTI_DISPATCH,
//       payload
//     });
//   };
// };

export function getTags(
  loanType: string,
  loanStage: string,
  loanState: string
): any {
  return async (dispatch: any) => {
    try {
      // dispatch({ type: TAGS_LOADING });
      dispatch(setLoading());
      const url = `${getConfig().apiUrl
        }/tagdetails/loanType/${loanType}/loanStage/${loanStage}/loanState/${loanState}`;
      const response = await publicClient.get(url);
      // dispatch({
      //   type: TAGS_SUCCESS,
      //   payload: {
      //     tags: response.data.length > 0 ? response.data[0] : [],
      //     loanState,
      //     loanStage
      //   }
      // });

      dispatch(
        tagsSuccess({
          tags: {
            tags: response.data.length > 0 ? response.data[0] : [],
            loanState,
            loanStage
          }
        })
      );
    } catch (err) {
      const e: any = err;
      dispatch(
        loanTypeFailed({
          error: {
            message: e?.message
          }
        })
      );
    }
  };
}

export function getAllSubmitTags(
  loanState: string = "SUBMIT",
  activeIntVal?: any
): any {
  return async (dispatch: any) => {
    try {
      const reqArrays: any[] = [];
      if (!activeIntVal) dispatch(setLoading());
      let url = `${getConfig().apiUrl
        }/tagdetails/loanType/30_YR_Loan/loanStage/PRE/loanState/${loanState}`;
      reqArrays.push(publicClient.get(url));
      url = `${getConfig().apiUrl
        }/tagdetails/loanType/30_YR_Loan/loanStage/POST/loanState/${loanState}`;
      reqArrays.push(publicClient.get(url));
      url = `${getConfig().apiUrl
        }/tagdetails/loanType/BRIDGE/loanStage/PRE/loanState/${loanState}`;
      reqArrays.push(publicClient.get(url));
      url = `${getConfig().apiUrl
        }/tagdetails/loanType/BRIDGE/loanStage/POST/loanState/${loanState}`;
      reqArrays.push(publicClient.get(url));
      Promise.all(reqArrays).then(async (response: any) => {
        // dispatch({
        //   type: DASHBOARD_TAGS_SUCCESS,
        //   payload: {
        //     InvestorDSCRPRESUBMIT:
        //       response[0].data.length > 0 ? response[0].data[0] : [],
        //     InvestorDSCRPOSTSUBMIT:
        //       response[1].data.length > 0 ? response[1].data[0] : [],
        //     BridgeLoanPRESUBMIT:
        //       response[2].data.length > 0 ? response[2].data[0] : [],
        //     BridgeLoanPOSTSUBMIT:
        //       response[3].data.length > 0 ? response[3].data[0] : []
        //   }
        // });
        dispatch(
          dashboardTagsSuccess({
            dashboardTags: {
              InvestorDSCRPRESUBMIT:
                response[0].data.length > 0 ? response[0].data[0] : [],
              InvestorDSCRPOSTSUBMIT:
                response[1].data.length > 0 ? response[1].data[0] : [],
              BridgeLoanPRESUBMIT:
                response[2].data.length > 0 ? response[2].data[0] : [],
              BridgeLoanPOSTSUBMIT:
                response[3].data.length > 0 ? response[3].data[0] : []
            }
          })
        );
      });
    } catch (err) {
      const e: any = err;
      // dispatch({
      //   type: TAGS_FAILED,
      //   error: {
      //     message: e?.message
      //   }
      // });
      dispatch(
        loanTypeFailed({
          error: {
            message: e?.message
          }
        })
      );
    }
  };
}

export function getMandatoryChecks(
  topic: "MANDATORY_DOCUMENTS" | "MANDATORY_RESULTS",
  body: any
): any {
  return async (dispatch: any) => {
    try {
      // const dispatchTopicList =
      //   topic === "MANDATORY_DOCUMENTS"
      //     ? [
      //         GET_MANDATORY_DOCUMENTS_LOADING,
      //         GET_MANDATORY_DOCUMENTS_SUCCESS,
      //         GET_MANDATORY_DOCUMENTS_FAILED
      //       ]
      //     : [
      //         GET_MANDATORY_RESULTS_LOADING,
      //         GET_MANDATORY_RESULTS_SUCCESS,
      //         GET_MANDATORY_RESULTS_FAILED
      //       ];
      const isMandatory_Docs = topic === "MANDATORY_DOCUMENTS";
      // dispatch({ type: dispatchTopicList[0] });    // not getting used
      const url = `${getConfig().apiUrl}/custom/${topic}/values/fetch`;
      const response = await publicClient.post(url, body);
      // dispatch({
      //   type: dispatchTopicList[1],
      //   payload: response.data,
      //   docType: body.loanState
      // });
      if (isMandatory_Docs) {
        dispatch(
          setMandatoryDocumentsSuccess({
            response: response.data,
            docType: body.loanState
          })
        );
      } else {
        dispatch(
          setMandatoryResultsSuccess({
            docType: body.loanState,
            data: response.data
          })
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
}

export function updateLoanStatusbyLoanId(
  loanId: string,
  body: any
): Promise<any> {
  const url = `${getConfig().apiUrl}/aggregate/loans/${loanId}/status`;
  return new Promise((resolve, reject) => {
    publicClient
      .put(url, body)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function updateDocsTrackingbyDocsId(body: any[]): Promise<any> {
  const url = `${getConfig().apiUrl}/documentdetails/documents/addtrackingid`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, body)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function deleteLoan(loanId: string, loanStage: any): Promise<any> {
  return new Promise((resolve, reject) => {
    publicClient
      .delete(
        `${getConfig().apiUrl}/aggregate/loans/${loanId}?loanStage=${loanStage}`
      )
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function checkLoanAccess(loanId: string): Promise<any> {
  const url = `${getConfig().apiUrl}/aggregate/loans/${loanId}/hasaccess`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url, {
        errorConfig: getErrorConfigObject({ skipErrorHandling: true })
      } as any)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export const fetchAndDispatchLoan = (
  _loanId: any,
  _loanStage: LoanStage,
  _keepTab: boolean = false
): any => {
  return async (dispatch: any) => {
    try {
      const newLoanData = await getLoanById(_loanId, _loanStage);
      updateLoanDetailsInRedux(newLoanData.data, dispatch);
      const formattedLoanData: any =
        _loanStage === LoanStage.fes
          ? formatFesLoanDetailsForRedux(newLoanData.data)
          : formatLoanDetailsForRedux(newLoanData.data, "");
      // console.log({ formattedLoanData });
      // dispatch({
      //   type: GET_LOAN_BY_ID_SUCCESS,
      //   payload: {
      //     loan: formattedLoanData,
      //     keepTab: _keepTab
      //   }
      // });
      dispatch(
        setLoanByIdSuccess({
          loan: formattedLoanData,
          keepTab: true
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
};

export function getLoanById(
  loanId: string,
  loanStage: LoanStage | string,
  withId?: boolean
): Promise<any> {
  if (!(loanId && loanStage)) return Promise.reject();
  let url = `${getConfig().apiUrl
    }/aggregate/loans/${loanId}?loanStage=${loanStage}`;
  if (withId) {
    url = `${getConfig().apiUrl}/aggregate/loans/${loanId}`;
  }
  return new Promise((resolve, reject) => {
    publicClient
      .get(url, {
        errorConfig: getErrorConfigObject({
          errorPriority: ErrorPriority.critical,
          customErrorMessage: {
            heading: errorMessageJson.getLoan.heading,
            body: errorMessageJson.getLoan.message
          }
        })
      } as any)
      .then((res: any) => {
        sessionStorage.setItem(
          "originatorPartyIdNew",
          res.data?.loanDetailId?.originatorPartyId
        );
        resolve(res);
      })
      .catch(reject);
  });
}
export function getUserDetails(emailId: string): Promise<any> {
  const url = `${getConfig().apiUrl}/aggregate/customer/${emailId}`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url, {
        errorConfig: getErrorConfigObject({ skipErrorHandling: true })
      } as any)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}
export function prepareObjectForTemplateMapping(
  tpid: any,
  data: any,
  dispatch: any,
  infoIconType?: any,
  objWithPayload?: boolean,
  loanStage?: any,
  loanId?: any,
  loanType?: any
) {
  // return (dispatch: any) => {
  try {
    const arrayObjForFieldInfo: any = [];
    let sectionType = "loan";
    const sectionNameArrLoan = ["loanInfo", "loanEconomics", "loanUserMap"];
    const sectionNameArrProperty = [
      "propertyLocation",
      "propertyEconomics",
      "propertyInfo"
    ];
    let sectionNamesArr: any = sectionNameArrLoan;
    if (infoIconType === "property") {
      sectionNamesArr = sectionNameArrProperty;
      sectionType = "properties";
    }
    const loanTypeMapping =
      loanType === `${loanTypeName[0].displayValue}` ? "bridge" : loanType;
    sectionNamesArr.forEach((sectionNameVal: any) => {
      let sectionName = sectionNameVal;
      if (infoIconType === "property") {
        if (!objWithPayload) {
          sectionName =
            sectionNameVal === "propertyInfo" ? "propertyinfo" : sectionNameVal;
        } else {
          sectionName += "Id";
        }
        Object.keys(data[sectionName]).forEach((key: any) => {
          arrayObjForFieldInfo.push(
            `fieldInfo.${loanTypeMapping}.${loanStage}.${sectionType}.${sectionNameVal}.${key}`
          );
        });
        if (loanType === loanTypeName[1].displayValue) {
          const unitInfo = data && data.propertyUnit;
          if (unitInfo?.length) {
            Object.keys(unitInfo[0]).forEach(function (key) {
              arrayObjForFieldInfo.push(
                `fieldInfo.${loanTypeMapping}.${loanStage}.${sectionType}.propertyUnit.${key}`
              );
            });
          }
        }
      } else if (infoIconType === "loan") {
        if (sectionName === "loanUserMap") {
          if (data[sectionName].length === 0) {
            const customerLevelMap = [
              "primaryEmail",
              "firstName",
              "lastName",
              "accountName",
              "foreignNationalString"
            ];
            const sectionLvlMap = [
              "loanUserType",
              "originalCreditScoreReportDate",
              "originalCreditScoreMedian",
              "ownershipOfEntity",
              "experience",
              "borrowerGUCExperience",
              "heavyRehabExperience"
            ];
            customerLevelMap.forEach((item: string) => {
              arrayObjForFieldInfo.push(
                `fieldInfo.${loanTypeMapping}.${loanStage}.${sectionType}.${sectionName}.customer.${item}`
              );
            });
            sectionLvlMap.forEach((item: string) => {
              arrayObjForFieldInfo.push(
                `fieldInfo.${loanTypeMapping}.${loanStage}.${sectionType}.${sectionName}.${item}`
              );
            });

            arrayObjForFieldInfo.push(
              `fieldInfo.${loanTypeMapping}.${loanStage}.${sectionType}.${sectionName}.customer.addressList[0].addressLine1`
            );
            arrayObjForFieldInfo.push(
              `fieldInfo.${loanTypeMapping}.${loanStage}.${sectionType}.${sectionName}.customer.contactList[0].contactNumber`
            );
          } else {
            data[sectionName].forEach((sectionNameValue: any) => {
              Object.keys(sectionNameValue).forEach((key: any) => {
                arrayObjForFieldInfo.push(
                  `fieldInfo.${loanTypeMapping}.${loanStage}.${sectionType}.${sectionName}.${key}`
                );
              });
              Object.keys(sectionNameValue.customer).forEach((key: any) => {
                arrayObjForFieldInfo.push(
                  `fieldInfo.${loanTypeMapping}.${loanStage}.${sectionType}.${sectionName}.customer.${key}`
                );
              });
              arrayObjForFieldInfo.push(
                `fieldInfo.${loanTypeMapping}.${loanStage}.${sectionType}.${sectionName}.customer.addressList[0].addressLine1`
              );
              arrayObjForFieldInfo.push(
                `fieldInfo.${loanTypeMapping}.${loanStage}.${sectionType}.${sectionName}.customer.contactList[0].contactNumber`
              );
            });
          }
        } else {
          Object.keys(data[sectionName]).forEach((key: any) => {
            arrayObjForFieldInfo.push(
              `fieldInfo.${loanTypeMapping}.${loanStage}.${sectionType}.${sectionName}.${key}`
            );
          });
        }
      }
    });
    if (infoIconType === "result") {
      sectionType = "result";
      if (data?.loanResult?.[loanId]) {
        const loanResultArr: any = Object.keys(data?.loanResult?.[loanId]);
        if (loanResultArr?.length) {
          loanResultArr.forEach(function (parentkey: any) {
            if (loanResultArr?.[parentkey]) {
              const parentkeyArr: any = Object.keys(loanResultArr[parentkey]);
              if (parentkeyArr?.length) {
                parentkeyArr.forEach(function (childKey: string) {
                  arrayObjForFieldInfo.push(
                    `fieldInfo.${loanTypeMapping}.${loanStage}.${infoIconType}.loanResult.${parentkey}.${childKey}`
                  );
                });
              }
            }
          });
        }
      }

      if (data?.propertiesResults?.[1]) {
        const propertiesResultsArr: any = Object.keys(
          data.propertiesResults[1]
        );
        if (propertiesResultsArr?.length) {
          propertiesResultsArr.forEach(function (parentkey: string) {
            if (propertiesResultsArr?.[parentkey]) {
              const parentkeyArr: any = Object.keys(
                propertiesResultsArr[parentkey]
              );
              if (parentkeyArr?.length) {
                Object.keys(parentkeyArr).forEach(function (childKey) {
                  arrayObjForFieldInfo.push(
                    `fieldInfo.${loanTypeMapping}.${loanStage}.${infoIconType}.propertyResults.${parentkey}.${childKey}`
                  );
                });
              }
            }
          });
        }
      }
    }
    if (tpid || data?.loanInfo?.takeoutPartner)
      getInputInfo(
        arrayObjForFieldInfo,
        sectionType,
        tpid || data.loanInfo.takeoutPartner,
        dispatch
      );
  } catch (error) {
    console.error(error);
  }
  // };
}

function getInputInfo(
  templateKeys: any,
  infoType: any,
  takeoutPartner: any,
  dispatch: any
) {
  return async () => {
    const templateKeysObject = { templateKeys };
    const url = `${getConfig().apiUrl
      }/template/fetch?locale=NA&countryCode=NA&partialProcess=true&tpId=${takeoutPartner}`;

    const response = await publicClient.post(url, templateKeysObject);
    if (response.status === 200) {
      // dispatch({
      //   type: FETCH_INFO_ICON_CONTENT,
      //   payload: {
      //     infoContent: getValidInputInfo(response.data),
      //     infoType
      //   }
      // });
      dispatch(
        fetchInfoIconContent({
          infoContent: getValidInputInfo(response.data),
          infoType
        })
      );
    }
  };
}

// function getInputInfo(templateKeys: any, infoType?: any) {
//   return async (dispatch: any) => {
//     const templateKeysObject = { templateKeys };
//     const url = `${
//       getConfig().apiUrl
//     }/template/fetch?locale=NA&countryCode=NA&partialProcess=true`;

//     const response = await publicClient.post(url, templateKeysObject);
//     if (response.status === 200) {
//       // dispatch({
//       //   type: FETCH_INFO_ICON_CONTENT,
//       //   payload: {
//       //     infoContent: getValidInputInfo(response.data),
//       //     infoType
//       //   }
//       // });
//       dispatch(
//         fetchInfoIconContent({
//           infoContent: getValidInputInfo(response.data),
//           infoType
//         })
//       );
//     }
//   };
// }

// clear out the empty value from the input info response
function getValidInputInfo(data: any) {
  const checkValue = [null, undefined, "``", " ", ""];
  for (const key in data) {
    if (checkValue.includes(data[key])) {
      delete data[key];
    }
  }
  return data;
}

export function previousLoanState(
  dataObj: any,
  key: string,
  propertyData: boolean
): any {
  return async (dispatch: any) => {
    try {
      // dispatch({
      //   type: SET_LOAN_PREVIOUS_STATE,
      //   payload: {
      //     dataObj,
      //     keyInfo: key,
      //     propertyData
      //   }
      // });
      dispatch(
        setLoanPreviousState({
          dataObj,
          keyInfo: key,
          propertyData
        })
      );
    } catch (err) {
      console.error("error in get loan by id", err);
    }
  };
}

export function bundleDetails(loanId: string) {
  const url2 = `${getConfig().apiUrl}/bundles/loans/${loanId}`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url2, {
        errorConfig: getErrorConfigObject({ skipForStatus: [404] })
      } as any)
      .then((res: any) => resolve(res))
      .catch((err: any) => {
        console.error(err);
        resolve({});
      });
  });
}

export function getDrawDocs(settlementIden: string, loanId: string) {
  const docUrl1: string = `${getConfig().apiUrl
    }/documentdetails/${settlementIden}/${loanId}/documents`;
  return publicClient.get(docUrl1);
}

export function getPurchaseAdviseDocs(
  settlementIden: string,
  bundleId: string,
  loanId: string
) {
  const docUrl2: string = `${getConfig().apiUrl
    }/aggregate/documentdetails/${settlementIden}/${bundleId}/documents?loanId=${loanId}`;
  return publicClient.get(docUrl2);
}

export async function getDocuments(
  loanId: string,
  secondaryIden: string = "",
  secondaryIdenValue: string = "",
  loanState: string = "",
  prePurchaseCallForTTFFromILP?: any,
  yetToStartTTF?: boolean
) {
  return new Promise(async (resolve, reject) => {
    try {
      let url;
      const isDocsTabForLATUser =
        !secondaryIden.includes("prePurchase") && (isILP() || isLATUser());
      if (
        isDocsTabForLATUser ||
        (prePurchaseCallForTTFFromILP && secondaryIden.includes("prePurchase"))
      ) {
        url = `${getConfig().apiUrl
          }/automaticTaggedDocumentDetails/LOAN/${loanId}/documents`;
      } else {
        url = `${getConfig().apiUrl}/documentdetails/LOAN/${loanId}/documents`;
      }
      if (secondaryIden) {
        url += `?includeAssets=true&secondary_identifier_name=${secondaryIden}&secondary_identifier_value=${secondaryIdenValue}`;
        if (yetToStartTTF) {
          url += "&setPrimaryFlags=true";
        }
      }
      const response = await publicClient.get(url);
      if (
        prePurchaseCallForTTFFromILP &&
        secondaryIden.includes("prePurchase")
      ) {
        resolve({
          response
        });
        return;
      }
      /* Do not change this logic */
      let drawDocsList: any = [];
      let purchasedDocList: any = [];
      if (
        !secondaryIden.includes("postClose") &&
        postApprovalStates.includes(loanState as LoanStatusEnum)
      ) {
        const bundleResp: any = await bundleDetails(loanId);
        if (bundleResp.data) {
          const settlementIden = "SETTLEMENT";
          const drawDocs: any = await getDrawDocs(settlementIden, loanId);
          drawDocsList = drawDocs.data;
          // drawDocsList.forEach((pDocs:any)=>{
          //   pDocs.topicId =  loanId;
          //   pDocs.topic = settlementIden;
          // })
          const bundleId: string = bundleResp.data?.bundleDetails?.bundleId;
          if (bundleId) {
            const purchasedDoc: any = await getPurchaseAdviseDocs(
              settlementIden,
              bundleId,
              loanId
            );
            purchasedDocList = purchasedDoc.data;
            purchasedDocList.forEach((pDocs: any) => {
              pDocs.topicId = bundleId;
              pDocs.topic = settlementIden;
            });
          }
        }
      }
      const docsList: any[] = response.data.concat(
        drawDocsList,
        purchasedDocList
      );
      // const resp = getDocsReduxData(docsList, isDocsTabForLATUser);
      // resolve(resp);
      const docsArray: any = [];
      const taggedArray: any[] = [];
      const confidenceScoreMap: any = {};
      docsList.forEach((item: any) => {
        const tags: string[] = [];
        const tagCategories: string[] = [];
        const tagList = isDocsTabForLATUser ? item.TagsInternal : item.tags;
        let categoryTag: string = "";
        tagList.forEach((tagsItem: any) => {
          if (tagsItem.tag.name !== "junk") {
            if (tagsItem.tag.tagType === "DOCUMENT") {
              tags.push(tagsItem.tag.name);
            } else {
              categoryTag = tagsItem.tag.code;
            }
            if (!tagCategories.includes(tagsItem.tag.tagCategory)) {
              tagsItem.tag.tagCategory !== null
                ? tagCategories.push(tagsItem.tag.tagCategory)
                : tagCategories.push("Others");
            }
            const index = taggedArray.findIndex(
              (el) => el.code === tagsItem.tag.code
            );
            if (index !== -1) {
              const prevTag = taggedArray[index];
              taggedArray.splice(index, 1);
              taggedArray.push({ ...prevTag, count: prevTag.count + 1 });
            } else {
              taggedArray.push({
                code: tagsItem.tag.code,
                count: 1,
                description: tagsItem.tag.description,
                isMandatory: "true",
                name: tagsItem.tag.name,
                tagCategory: tagsItem.tag.tagCategory,
                tagType: tagsItem.tag.tagType
              });
            }
          }
        });
        const obj = {
          categoryTag,
          inQueue: false,
          lastModified: "",
          lastModifiedDate: "",
          name: item.name,
          percentCompleted: 100,
          path: item.path,
          size: "4290",
          tag: tags,
          tagCategory: tagCategories,
          type: item.name,
          docsId: item.id,
          createdOn: item.createdOn,
          uploaded: true,
          uploading: false,
          webkitRelativePath: "",
          trackingId: item.trackingId || "",
          uniqueId: uuidV4(),
          topicId: item.topicId || "",
          topic: item.topic || "",
          split_source_id: item.split_source_id,
          assets: item.assets || null,
          isPurchasePrimary: item.isPurchasePrimary || null,
          isLatest: item.isLatest || null,
          visibility: item.visibility || ""
        };
        docsArray.push(obj);
        if (isDocsTabForLATUser) {
          confidenceScoreMap[item.id] =
            tagList.length > 0 ? tagList[0].confidence_score : null;
        }
      });
      resolve({
        docs: docsArray,
        taggedArray,
        failedUploads: 0,
        successfulUploads: docsArray.length,
        confidenceScoreMap,
        response
      });
    } catch (error) {
      reject(error);
    }
  });
}
export function getDocsByLoanId(
  loanId: string,
  secondaryIden: string = "",
  secondaryIdenValue: string = "",
  loanState: string = "",
  setDocsReviewBtn?: Function,
  yetToStartTagging?: boolean
): any {
  return async (dispatch: any) => {
    try {
      // dispatch({ type: GET_DOCS_BY_ID_LOADING });
      const payload: any = await getDocuments(
        loanId,
        secondaryIden,
        secondaryIdenValue,
        loanState,
        setDocsReviewBtn,
        yetToStartTagging
      );
      if (setDocsReviewBtn) {
        dispatch(
          getAutomaticTaggedDocuments({
            isLoading: false,
            data: payload?.response?.data
          })
        );
        dispatch(
          getAutomaticPLTaggedDocuments({
            isLoading: false,
            data: payload?.response?.data
          })
        );
        setDocsReviewBtn();
      }
      delete payload?.response;
      if (Object.keys(payload)?.length > 1) {
        // dispatch({
        //   type: GET_DOCS_BY_ID_SUCCESS,
        //   payload,
        //   currentSection: secondaryIden
        // });
        dispatch(
          setDocsByIdSuccess({
            ...payload,
            currentSection: secondaryIden
          })
        );
      }
    } catch (err) {
      const e: any = err;
      console.error(e);
      // dispatch({
      //   type: GET_DOCS_BY_ID_FAILED,
      //   error: {
      //     message: e?.message
      //   }
      // });
    }
  };
}

export function getTermSheetByLoanId(
  loanId: string,
  secondaryIden: string = "",
  secondaryIdenValue: string = ""
): any {
  return async (dispatch: any) => {
    try {
      const data: any = await getDocuments(
        loanId,
        secondaryIden,
        secondaryIdenValue
      );
      // dispatch({
      //   type: GET_TERMSHEET_BY_ID_SUCCESS,
      //   payload,
      //   currentSection: secondaryIden
      // });
      dispatch(
        setTermsheetByIdSuccess({
          ...data,
          currentSection: secondaryIden
        })
      );
    } catch (err) {
      const e: any = err;
      console.error(e);
    }
  };
}

// export const updateCurrentFilesUploaded = (
//   currentTab: string,
//   isClear: boolean
// ) => {
//   return {
//     type: DOCS_CURRENT_UPLOAD_SUCCEESS,
//     payload: {
//       currentTab,
//       isClear
//     }
//   };
// };

export function getPropertyOriginalValuesByLoanId(
  loanId: string,
  loanStage: LoanStage | string
) {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/loan_property/${loanId}/properties?stage=${loanStage}`;
    publicClient
      .get(url, {
        errorConfig: getErrorConfigObject({
          errorPriority: ErrorPriority.critical,
          customErrorMessage: {
            heading: errorMessageJson.getProperty.heading,
            body: errorMessageJson.getProperty.message
          }
        })
      } as any)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}
export function updatePropertyUnitsInfo(
  unitId: number,
  activePropertyId: string,
  unitInformation: any
) {
  return updatePropertyUnits({ unitId, unitInformation, activePropertyId });
  // type: UPDATE_PROPERTY_UNITS,
  // payload: {
  //   unitId,
  //   unitInformation,
  //   activePropertyId
  // }
}

// export function deletePropertyUnits(unitId: string, activePropertyId: string) {
//   return {
//     type: DELETE_PROPERTY_UNITS,
//     payload: { unitId, activePropertyId }
//   };
// }

export function validateExcel(requestBody: any): Promise<any> {
  const url = `${getConfig().apiUrl}/aggregate/excel/validate`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, requestBody)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function createLoanUsingExcel(
  loanType: string,
  loanStage: LoanStage,
  cloudFilePath: {
    BucketName: string;
    Bucketkey: string;
  },
  duplicateProperties: any[] = []
): Promise<any> {
  const url = `${getConfig().apiUrl}/aggregate/excel/import`;
  let requestBody: any = {
    originatorId: getCookie("org_id"),
    loanState: LoanStatusEnum.DataEntry,
    loanType,
    cloudFilePath,
    loanStage,
    fundingType: "normal"
  };
  const addConvertLoanData = () => {
    requestBody.convertLoanList = duplicateProperties;
    return requestBody;
  };
  requestBody = duplicateProperties.length ? addConvertLoanData() : requestBody;

  return new Promise((resolve, reject) => {
    publicClient
      .post(url, requestBody)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

/** This function will be used to reset all redux stores */
export function resetAllStores(): any {
  return async (dispatch: any) => {
    // dispatch({
    //   type: RESET_LOAN_STORE
    // });
    // dispatch({             //not grtting used in create-loan
    //   type: RESET_EVALUATION_STORE
    // });
    // dispatch({
    //   type: RESET_EXCEPTION_STORE
    // });
    // dispatch({
    //   type: RESET_WAIVER_STORE
    // });
    // dispatch({
    //   type: RESET_DISCUSSION_STORE
    // });
    // dispatch(resetLoanStore());
    dispatch(resetEvaluationStore());
    dispatch(resetExceptionStore());
    dispatch(resetWaiverStore());
    dispatch(resetDiscussionStore());
    dispatch(resetLoanStore());
    dispatch(resetAppraisalStore());
  };
}

/** Create loan using excel upload */

// export function updateCreateLoanStoreForSelectedLoan(
//   data: any,
//   fullReplace?: boolean
// ): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPLOAD_EXCEL_LOAN_CHANGE,
//       payload: { data, fullReplace }
//     });
//   };
// }

/** Helper functions */
export function getLocationObject(propertyLocation: any) {
  return {
    errors: null,
    payload: {
      address: {
        city: propertyLocation.city,
        entries: 0,
        secondary: "",
        state: propertyLocation.state,
        street_line: propertyLocation.addressLine1,
        zipcode: propertyLocation.postalCode,
        duplicateLoanIds: propertyLocation.duplicateLoanIds,
        duplicatePropertyInfo: propertyLocation.duplicatePropertyInfo,
        locationValidationStatus: propertyLocation.locationValidationStatus,
        invalidAddress:
          propertyLocation.locationValidationStatus !== "COMPLETE" &&
          propertyLocation.locationValidationStatus !== "OVERRIDDEN"
      },
      city: propertyLocation.city,
      state: propertyLocation.state,
      zipCode: propertyLocation.postalCode,
      duplicateLoanIds: propertyLocation.duplicateLoanIds,
      duplicatePropertyInfo: propertyLocation.duplicatePropertyInfo,
      locationValidationStatus: propertyLocation.locationValidationStatus,
      invalidAddress:
        propertyLocation.locationValidationStatus !== "COMPLETE" &&
        propertyLocation.locationValidationStatus !== "OVERRIDDEN"
    }
  };
}
export function formatPropertyForRedux(data: any[], loanType: string) {
  const response = data.sort(function (a: any, b: any) {
    if (
      !isEmptyValue(a.loanPropertyOrder) &&
      !isEmptyValue(b.loanPropertyOrder)
    ) {
      return a.loanPropertyOrder - b.loanPropertyOrder;
    }
    return a.loanPropertyId - b.loanPropertyId;
  });
  const property: any[] = [];
  response.forEach((item: any) => {
    let useCase = "";
    if (item.propertyinfo.changeInUseCase === true) {
      useCase = "Y";
    } else if (item.propertyinfo.changeInUseCase === false) {
      useCase = "N";
    }
    const unitsInfo: any = [];
    item.propertyUnit?.map((ele: any, index: number) => {
      unitsInfo.push({
        errors: null,
        unitId: ele.propertyUnitId ? ele.propertyUnitId : index + 1,
        payload: {
          leaseEndDate: ele.leaseEndDate || null,
          leaseStartDate: ele.leaseStartDate || null,
          leaseStatus: ele.leaseStatus,
          currentLeaseTerm: ele.currentLeaseTermMonthly || "",
          marketRentMonthly: ele.marketRentMonthly
            ? formatValueByType(ele.marketRentMonthly, "currency")
            : "",
          inPlaceLeaseRent: ele.inPlaceLeaseRentMonthly
            ? formatValueByType(ele.inPlaceLeaseRentMonthly, "currency")
            : "",
          recentLeaseRent: ele.mostRecentLeaseRentMonthly
            ? formatValueByType(ele.mostRecentLeaseRentMonthly, "currency")
            : ""
        }
      });
    });
    let propertySummaryObj;
    const { propertySummary } = item;
    if (propertySummary) {
      const {
        monthlyTaxAmount,
        monthlyInsuranceAmount,
        escrowTaxes,
        monthsOfTaxes,
        escrowInsurance,
        monthsOfInsurance
      } = propertySummary;
      propertySummaryObj = {
        ...propertySummary,
        monthlyTaxAmount: monthlyTaxAmount
          ? formatValueByType(monthlyTaxAmount.replace(/,/g, ""), "currency")
          : "",
        monthlyInsuranceAmount: monthlyInsuranceAmount
          ? formatValueByType(
            monthlyInsuranceAmount.replace(/,/g, ""),
            "currency"
          )
          : "",
        escrowTaxes: escrowTaxes
          ? formatValueByType(escrowTaxes.replace(/,/g, ""), "currency")
          : "",
        monthsOfTaxes,
        escrowInsurance: escrowInsurance
          ? formatValueByType(escrowInsurance.replace(/,/g, ""), "currency")
          : "",
        monthsOfInsurance
      };
    }
    const hoaFee = item?.propertyEconomics?.annualHoaFee || item?.propertyEconomics?.annualHOAFee
    const propertyObj = {
      propertyId: item.loanPropertyId,
      loanPropertyOrder: item.loanPropertyOrder,
      propertyLocation: getLocationObject(item.propertyLocation),
      propertyInformation: {
        errors: null,
        payload: {
          propertyType: item.propertyinfo.propertyType,
          numberOfUnits: !isNullOrUndefined(item.propertyinfo.propertyUnits)
            ? formatNumberToCommaSeparated(item.propertyinfo.propertyUnits)
            : "",
          propertyValuationDate: item.propertyinfo.recentPropertyValuationDate,
          // originalPropertyValuationDate is alias of propertyValidationDate - new field used only in TTF
          originalPropertyValuationDate:
            item.propertyinfo.propertyValuationDate,
          propertyAcquisitionDate: item.propertyinfo.propertyAcquisitionDate,
          changeInUseCase: useCase,
          isPropertyPurchasedLast2Years:
            item.propertyinfo.isPropertyPurchasedLast2Years,
          squareFootage:
            formatNumberToCommaSeparated(item.propertyinfo.squareFootage) || "",
          preRehabSquareFootage:
            formatNumberToCommaSeparated(
              item.propertyinfo.preRehabSquareFootage
            ) || "",
          toorvalId: item.propertyinfo.toorvalId,
          postRehabSquareFootage:
            formatNumberToCommaSeparated(
              item.propertyinfo.postRehabSquareFootage
            ) || "",
          percentageOccupied:
            formatValueByType(
              item.propertyinfo.occupiedPercentage,
              "percentageWithout100x"
            ) ||
            getDefaultValue(loanType, "percentageOccupied") ||
            ""
        }
      },
      propertyEconomics: {
        errors: null,
        payload: {
          originalAppraisalValue: item.propertyEconomics
            .originalAsIsAppraisalValue
            ? formatValueByType(
              item.propertyEconomics.originalAsIsAppraisalValue,
              "currency"
            )
            : "",
          thirdPartyValuation: item.propertyEconomics.thirdPartyValuation
            ? formatValueByType(
              item.propertyEconomics.thirdPartyValuation,
              "currency"
            )
            : "",
          originalRepairedAppraisalValue: item.propertyEconomics
            .originalAsRepairedAppraisedValue
            ? formatValueByType(
              item.propertyEconomics.originalAsRepairedAppraisedValue,
              "currency"
            )
            : "",
          purchasePrice: item.propertyEconomics.purchasePrice
            ? formatValueByType(
              item.propertyEconomics.purchasePrice,
              "currency"
            )
            : "",
          costBasis: item.propertyEconomics.costBasis
            ? formatValueByType(item.propertyEconomics.costBasis, "currency")
            : getDefaultValue(loanType, "costBasis") || "",
          grossPotentialRent: item.propertyEconomics.grossPotentialRent
            ? formatValueByType(
              item.propertyEconomics.grossPotentialRent,
              "currency"
            )
            : getDefaultValue(loanType, "grossPotentialRent") || "",
          assignmentFees: item.propertyEconomics.assignmentFees
            ? formatValueByType(
              item.propertyEconomics.assignmentFees,
              "currency"
            )
            : getDefaultValue(loanType, "assignmentFees") || "",
          annualPropertyTaxes: item.propertyEconomics.annualPropertyTaxes
            ? formatValueByType(
              item.propertyEconomics.annualPropertyTaxes,
              "currency"
            )
            : getDefaultValue(loanType, "annualPropertyTaxes") || "",
          insurance: item.propertyEconomics.insurance
            ? formatValueByType(item.propertyEconomics.insurance, "currency")
            : getDefaultValue(loanType, "insurance") || "",
          rentalCashflowRatio: item.propertyEconomics.rentalCashflowRatio
            ? formatValueByType(
              item.propertyEconomics.rentalCashflowRatio,
              "percentageWithout100x"
            )
            : "",
          annualHazardInsurance: item.propertyEconomics.annualHazardInsurance
            ? formatValueByType(
              item.propertyEconomics.annualHazardInsurance,
              "currency"
            )
            : "",
          annualHoaFee: hoaFee
            ? formatValueByType(hoaFee, "currency")
            : "",
          annualFloodInsurance: item.propertyEconomics.annualFloodInsurance
            ? formatValueByType(
              item.propertyEconomics.annualFloodInsurance,
              "currency"
            )
            : "",
          appraisalReported: item.propertyEconomics.appraisalReportedNoiNcf
            ? formatValueByType(
              item.propertyEconomics.appraisalReportedNoiNcf,
              "currency"
            )
            : getDefaultValue(loanType, "appraisalReported") || "",
          decliningMarkets: item?.propertyEconomics?.decliningMarkets || "No"
        }
      },
      unitsInformation: unitsInfo.length ? unitsInfo : getEmptyUnitInfo(),
      propertySummary: propertySummaryObj
    };
    property.push(propertyObj);
  });

  return property;
}

export function checkDuplicateLoan(
  reqObj: any,
  stage: LoanStage
): Promise<any> {
  const originatorId = getCookie("org_id");
  let originatorIds: any = [];
  originatorIds.push(originatorId);
  if (isLATUser()) {
    let accountIds: any = localStorage.getItem("account_ids");
    accountIds = JSON.parse(accountIds);
    originatorIds = [...originatorIds, ...accountIds];
  }
  const reqBody = {
    duplicateBody: reqObj,
    loanStage: stage,
    originatorIds
  }
  const url = `${getConfig().apiUrl
    }/aggregate/v2/properties/location/hasduplicate`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, reqBody, {
        errorConfig: getErrorConfigObject({
          skipForStatus: [400]
        })
      } as any)
      .then((response: any) => resolve(response))
      .catch(reject);
  });
}

export function checkDuplicateLoanInExcel(
  reqObj: any,
  stage: any
): Promise<any> {
  const originatorId = getCookie("org_id");
  let originatorIds: any = [];
  originatorIds.push(originatorId);
  if (isLATUser()) {
    let accountIds: any;
    accountIds = localStorage.getItem("account_ids");
    accountIds = JSON.parse(accountIds);
    originatorIds = [...originatorIds, ...accountIds];
  }
  const url = `${getConfig().apiUrl
    }/aggregate/properties/location/hasduplicate/excel?originatorIds=${originatorIds}&loanStage=${stage}`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, reqObj)
      .then((response: any) => resolve(response))
      .catch(reject);
  });
}

export function updateDuplicateLoan(
  reqObj: any,
  stage: LoanStage
): Promise<any> {
  const originatorId = getCookie("org_id");
  const url = `${getConfig().apiUrl
    }/aggregate/properties/location/updateduplicate?originatorIds=${originatorId}&loanStage=${stage}`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, reqObj)
      .then((response: any) => resolve(response))
      .catch(reject);
  });
}

export function formatLoanDetailsForRedux(
  loanData: any,
  loanBundleData: any = null,
  ddId?: any,
  currentTab?: string | null
) {
  const loan: any = JSON.parse(JSON.stringify(loanData));
  const guarantors: any[] = [];
  const borrowers: any[] = [];
  let activeTab = currentTab || "summary";
  if (
    !isLATUser() ||
    loan.loanState === LoanStatusEnum.DataEntry ||
    loan.loanState === LoanStatusEnum.ExcelError
  ) {
    activeTab = "loanDetails";
  }
  if (loanBundleData) activeTab = "settlement";
  let returnValue: any;
  if (
    loan?.loanDetailId?.loanConfigId["appraisal"] === "Yes" &&
    loan?.loanDetailId?.loanConfigId["streetView"] === "Yes" &&
    loan?.loanDetailId?.loanConfigId["zillow"] === "Yes" &&
    loan?.loanDetailId?.loanConfigId["truliaCrime"] === "Yes" &&
    ((loan?.loanDetailId?.loanConfigId?.ttfVersionId == null &&
      loan?.loanDetailId?.loanConfigId?.tapeToFile === "Yes") ||
      loan?.loanDetailId?.loanSummaryId?.teamIndiaTapetoFile === "Yes")
  ) {
    loan.loanDetailId.loanConfigId.master = "Pass";
  } else {
    loan.loanDetailId.loanConfigId.master = "--";
  }
  let loanConfig = loan.loanDetailId?.loanConfigId;
  if (ddId) {
    loanConfig = { ...loanConfig, dueDiligencePartyId: ddId };
  }
  const users = loan?.loanUserMap?.sort(function (a: any, b: any) {
    return Number(a.loanUserSequence) - Number(b.loanUserSequence);
  });
  users?.forEach((item: any) => {
    if (item.loanUserType === "Guarantor") {
      const foreignFlag = item?.customer?.foreignNationalString;
      const guarantorObj = {
        guarantorId: uuidV4(),
        errors: null,
        payload: {
          partyId: item?.customer?.partyId,
          isPrimaryGuarantor: item.isPrimary,
          guarantorType:
            item?.customer?.partyType === "account" ? "Entity" : "Individual",
          creditScore: item.originalCreditScoreMedian ?? null,
          guarantorEmail:
            (item?.customer?.contactList &&
              item?.customer?.contactList?.[0]?.email) ||
            "",
          loanUserMapId: item.loanUserMapId,
          guarantorExperience: item.experience,
          netWorthCurrency: item?.netWorthCurrency,
          liquidityCurrency: item?.liquidityCurrency,
          guarantorLoanUserSequence: item?.loanUserSequence,
          guarantorLastName: isNullOrUndefined(item.customer?.lastName)
            ? ""
            : item.customer?.lastName,
          guarantorMiddleName: isNullOrUndefined(item.customer?.lastName)
            ? ""
            : item.customer?.MiddleName,
          guarantorFirstName: isNullOrUndefined(item.customer?.firstName)
            ? ""
            : `${item.customer?.firstName}`,
          originalCreditReportDate: item.originalCreditScoreReportDate || null,
          pOEntity: isNullOrUndefined(item.ownershipOfEntity)
            ? ""
            : formatValueByType(
              item.ownershipOfEntity,
              "percentageWithout100x"
            ),
          ssNumber: item.customer?.ssNumber,
          passportExpirationDate: item?.customer?.passportExpirationDate,
          foreignNationalString: foreignFlag
        }
      };
      guarantors.push(guarantorObj);
    } else {
      const borrower = item;
      let borrowerExp: string | number = "";
      if (borrower?.experience && typeof borrower.experience === "object") {
        if (Object.keys(borrower?.experience).length === 0) borrowerExp = 0;
        else borrowerExp = borrower?.experience?.years || "";
      } else {
        borrowerExp = isNullOrUndefined(borrower?.experience)
          ? null
          : borrower?.experience;
      }
      const heavyRehabExperience = isNullOrUndefined(
        borrower?.heavyRehabExperience
      )
        ? null
        : borrower?.heavyRehabExperience;
      const borrowerGUCExperience = isNullOrUndefined(
        borrower?.borrowerGUCExperience
      )
        ? null
        : borrower?.borrowerGUCExperience;
      const foreignFlag = borrower?.customer?.foreignNationalString ?? "";
      const borrowerObj = {
        errors: null,
        borrowerId: uuidV4(),
        payload: {
          partyId: borrower?.customer?.partyId,
          borrowerEmail:
            borrower?.customer?.primaryEmail ||
            borrower?.customer?.contactList[0]?.email ||
            "",
          borrowingEntityName:
            borrower?.customer?.firstName ||
            borrower?.customer?.accountName ||
            "",
          loanUserMapId: item.loanUserMapId,
          netWorthCurrency: item?.netWorthCurrency,
          liquidityCurrency: item?.liquidityCurrency,
          borrowerType:
            borrower?.customer?.partyType === "account"
              ? "Entity"
              : "Individual",
          billingAddress:
            borrower?.customer?.addressList?.[0]?.addressLine1 ?? "",
          city: borrower?.customer?.addressList?.[0]?.city ?? "",
          state: borrower?.customer?.addressList?.[0]?.state ?? "",
          pincode: borrower?.customer?.addressList?.[0]?.pincode ?? "",
          locationValidationStatus:
            borrower?.customer?.addressList?.[0]?.locationValidationStatus ??
            "",
          billingPhoneNumber:
            borrower?.customer?.contactList?.[0]?.contactNumber ?? "",
          borrowerExperience: borrowerExp,
          heavyRehabExperience,
          borrowerGUCExperience,
          borrowerLoanUserSequence: borrower?.loanUserSequence,
          isPrimaryBorrower: borrower?.isPrimary || false,
          firstName: borrower?.customer?.firstName,
          lastName: borrower?.customer?.lastName,
          eiNumber: borrower?.customer?.eiNumber,
          ssNumber: borrower?.customer?.ssNumber,
          passportExpirationDate: borrower?.customer?.passportExpirationDate,
          foreignNationalString: foreignFlag,
          originalCreditScoreMedian:
            borrower?.originalCreditScoreMedian?.toString() ?? null,
          originalCreditReportDate: borrower?.originalCreditScoreReportDate
        }
      };
      borrowers.push(borrowerObj);
    }
  });
  if (borrowers?.length === 0) {
    const loanType =
      loan.loanTypeId.loanTypeName === "Bridge Loan"
        ? loanTypeName[0].displayValue
        : loanTypeName[1].displayValue;
    borrowers.push(getEmptyBorrower(loanType));
  }
  let selectedRecourseValue = "";
  if (loan?.loanInfo?.recourse) {
    selectedRecourseValue = "Full Recourse";
  } else if (loan?.loanInfo?.recourse === false) {
    selectedRecourseValue = "Non-Recourse";
  }
  if (loan.loanInfo?.recourseString) {
    if (
      loan.loanInfo?.recourseString === "Yes" ||
      loan.loanInfo?.recourseString === "Full Recourse" ||
      loan.loanInfo?.recourseString === "Y"
    ) {
      selectedRecourseValue = "Full Recourse";
    } else if (
      loan.loanInfo?.recourseString === "No" ||
      loan.loanInfo?.recourseString === "Non-Recourse" ||
      loan.loanInfo?.recourseString === "N"
    ) {
      selectedRecourseValue = "Non-Recourse";
    }
  }
  const getfundingChannelValue = (key: string) => {
    if (key === "normal" || key === null) return "Normal";
    else if (key === "advance_funding") return "Advance Funding";
    else return "Table Funding";
  };

  let selectedFundingChannelValue;
  if (loan?.loanDetailId?.isWarehouseFunded === true) {
    selectedFundingChannelValue = "Warehouse Funding";
  } else {
    selectedFundingChannelValue = getfundingChannelValue(
      loan.loanDetailId.fundingType
    );
  }
  const loanSummary = loan.loanDetailId?.loanSummaryId || {};
  const {
    updatedInsuranceEscrowBalance,
    updatedTaxEscrowBalance,
    monthlyTaxAmount,
    MonthlyInsuranceAmount,
    escrowTaxes,
    monthsOfTaxes,
    escrowInsurance,
    monthsOfInsurance,
    percentageStepUp
  } = loanSummary;
  const loanSummaryObj = {
    ...loanSummary,
    percentageStepUp: percentageStepUp
      ? formatValueByType(percentageStepUp, "percentageWithout100x")
      : "",
    monthlyTaxAmount: monthlyTaxAmount
      ? formatValueByType(monthlyTaxAmount.replace(/,/g, ""), "currency")
      : "",
    updatedInsuranceEscrowBalance: updatedInsuranceEscrowBalance
      ? formatValueByType(
        updatedInsuranceEscrowBalance.replace(/,/g, ""),
        "currency"
      )
      : "",
    updatedTaxEscrowBalance: updatedTaxEscrowBalance
      ? formatValueByType(updatedTaxEscrowBalance.replace(/,/g, ""), "currency")
      : "",
    MonthlyInsuranceAmount: MonthlyInsuranceAmount
      ? formatValueByType(MonthlyInsuranceAmount.replace(/,/g, ""), "currency")
      : "",
    escrowTaxes: escrowTaxes
      ? formatValueByType(escrowTaxes.replace(/,/g, ""), "currency")
      : "",
    monthsOfTaxes,
    escrowInsurance: escrowInsurance
      ? formatValueByType(escrowInsurance.replace(/,/g, ""), "currency")
      : "",
    monthsOfInsurance
  };
  if (loan.loanTypeId.loanTypeName === "Bridge Loan") {
    let selectedCrossValue;
    if (loan.loanInfo?.crossCollaterlization) {
      selectedCrossValue = "Y";
    } else if (loan.loanInfo?.crossCollaterlization === false) {
      selectedCrossValue = "N";
    } else {
      selectedCrossValue = "";
    }
    returnValue = {
      loanDetails: {
        rateLockExpiryDate: loan.rateLockExpiryDate,
        sizerRateLockExpiryDate: loan.sizerRateLockExpiryDate,
        loanId: loan?.loanDetailId?.loanId,
        activePropertyId: 1,
        activeTab,
        originatorInfo: loan.originatorInfo,
        loanState: loan.loanState,
        loanStage: loan.loanStage,
        loanConfig,
        loanSummary: loanSummaryObj,
        loanDetailId: loan.loanDetailId,
        loanCredit: loan.loanCredit,
        dueDiligencePartyInfo: loan?.dueDiligencePartyInfo,
        bridgeLoanGuarantorInformation: guarantors,
        loanType: loan.loanTypeId.loanTypeName,
        bridgeLoanBorrowerInformation: borrowers,
        bridgeLoanInformation: {
          errors: null,
          payload: {
            loanState: loan.loanState,
            primaryLoanID: loan?.loanInfo?.primaryLoanId || "",
            pricingPaymentDueDate: loan.loanInfo?.pricingPaymentDueDate,
            selectedLoanStructure:
              loan.loanInfo?.toorakProduct === ToorakProductEnum.Rental
                ? LoanStructureTypes.singleDraw
                : loan.loanInfo?.loanStructure,
            loanPurpose: loan.loanInfo?.loanPurpose,
            cutOffDate: loan.loanInfo?.cutOffDate
              ? loan.loanInfo?.cutOffDate
              : toISOLocalDateString(new Date()),
            originationDate: loan.loanInfo?.originationDate
              ? loan.loanInfo?.originationDate
              : null,
            loanSubmissionDate: loan.loanSubmissionDate
              ? loan.loanSubmissionDate
              : null,
            firstPaymentDate: loan.loanInfo?.firstPaymentDateOfLoan
              ? loan.loanInfo?.firstPaymentDateOfLoan
              : null,
            maturityDate: loan.loanInfo?.originalMaturityDate
              ? loan.loanInfo?.originalMaturityDate
              : null,
            defaultRate: loan.loanInfo?.defaultRate
              ? loan.loanInfo?.defaultRate
              : null,
            defaultRateCurable: loan.loanInfo?.defaultRateCurable
              ? loan.loanInfo?.defaultRateCurable
              : null,
            selectedRecourse: selectedRecourseValue,
            selectedCross: selectedCrossValue,
            extensionOption:
              !isNullOrUndefined(loan.loanInfo?.extensionOptions) &&
                loan.loanInfo?.extensionOptions !== "N"
                ? loan.loanInfo?.extensionOptions
                : 0,
            selectedToorakProduct: loan.loanInfo?.toorakProduct,
            selectedFundingChannel: selectedFundingChannelValue,
            pledgeOfEquity: "N", // this is needed in back end..send it as "N" by default
            numberOfProperties: !isNullOrUndefined(
              loan.loanInfo?.noOfProperties
            )
              ? loan.loanInfo?.noOfProperties
              : "",
            servicerName: loan?.loanDetailId?.loanConfigId?.servicerName,
            servicerPartyId: loan?.loanDetailId?.loanConfigId?.servicerPartyId,
            sellerPartyId: loan?.loanDetailId?.loanConfigId?.sellerPartyId,
            originatorCategoryBucket:
              loan.loanDetailId?.originatorCategoryBucket
          }
        },
        bridgeLoanEconomics: {
          errors: null,
          payload: {
            financialInterestReserve: loan.loanEconomics
              ?.financedInterestReserve
              ? formatValueByType(
                loan.loanEconomics?.financedInterestReserve.replace(/,/g, ""),
                "currency"
              )
              : "",
            loanAmount: loan.loanEconomics?.originalLoanAmount
              ? formatValueByType(
                loan.loanEconomics?.originalLoanAmount.replace(/,/g, ""),
                "currency"
              )
              : "",
            maxLoanAmount: loan.loanEconomics?.originalMaximumLoanAmount
              ? formatValueByType(
                loan.loanEconomics?.originalMaximumLoanAmount.replace(
                  /,/g,
                  ""
                ),
                "currency"
              )
              : "",
            cutOffLoanAmount: loan.loanEconomics?.cutOffDateLoanAmount
              ? formatValueByType(
                loan.loanEconomics?.cutOffDateLoanAmount.replace(/,/g, ""),
                "currency"
              )
              : "",
            cutOffMaxLoanAmount: loan.loanEconomics?.cutOffDateMaximumLoanAmount
              ? formatValueByType(
                loan.loanEconomics?.cutOffDateMaximumLoanAmount.replace(
                  /,/g,
                  ""
                ),
                "currency"
              )
              : "",
            insuranceAndAmortization: loan.loanEconomics
              ?.insuranceAndAmortization
              ? formatValueByType(
                loan.loanEconomics?.insuranceAndAmortization.replace(
                  /,/g,
                  ""
                ),
                "currency"
              )
              : "",
            interestRate: isEmptyValue(
              loan.loanEconomics?.interestRateAsOfCutOffDate
            )
              ? ""
              : formatValueByType(
                loan.loanEconomics?.interestRateAsOfCutOffDate,
                "percentageWithout100x"
              ),
            accrualType: loan.loanEconomics?.accrualType,
            discountPoints: loan.loanEconomics
              ?.totalOriginationAndDiscountPoints
              ? formatValueByType(
                loan.loanEconomics?.totalOriginationAndDiscountPoints,
                "percentageWithout100x"
              )
              : "",
            budgetAmount: loan.loanEconomics?.totalBudgetAmount
              ? formatValueByType(
                loan.loanEconomics?.totalBudgetAmount.replace(/,/g, ""),
                "currency"
              )
              : "",
            cashOutAmount: loan.loanEconomics?.cashOutAmount
              ? formatValueByType(
                loan.loanEconomics?.cashOutAmount.replace(/,/g, ""),
                "currency"
              )
              : "$0.00",
            financedBudgetAmount: loan.loanEconomics?.financedBudgetAmount
              ? formatValueByType(
                loan.loanEconomics?.financedBudgetAmount,
                "currency"
              )
              : "$0.00",
            originalMonthlyPiPayment: loan.loanEconomics
              ?.originalMonthlyPiPayment
              ? formatValueByType(
                loan.loanEconomics?.originalMonthlyPiPayment,
                "currency"
              )
              : "",
            rateType: loan?.loanEconomics?.rateType ?? "",
            interestOnly: loan.loanEconomics?.interestOnly,
            armIndex: loan?.loanEconomics?.armIndex ?? "",
            grossArmMargin: loan?.loanEconomics?.grossArmMargin ?? "",
            initialRateAdjustmentPeriod:
              loan?.loanEconomics?.initialRateAdjustmentPeriod ?? "",
            initialPayAdjustmentPeriod:
              loan?.loanEconomics?.initialPayAdjustmentPeriod ?? "",
            initialInterestRateDown:
              loan?.loanEconomics?.initialInterestRateDown ?? "",
            initialInterestRateUp:
              loan?.loanEconomics?.initialInterestRateUp ?? "",
            lifetimeMaxRate: loan?.loanEconomics?.lifetimeMaxRate ?? "",
            lifetimeMinRate: loan?.loanEconomics?.lifetimeMinRate ?? "",
            subsequentInterestRateDown:
              loan?.loanEconomics?.subsequentInterestRateDown ?? "",
            subsequentInterestRateUp:
              loan?.loanEconomics?.subsequentInterestRateUp ?? "",
            paymentAdjustmentFrequency:
              loan?.loanEconomics?.paymentAdjustmentFrequency ?? "",
            rateAdjustmentFrequency:
              loan?.loanEconomics?.rateAdjustmentFrequency ?? "",
            armLookBackDays: loan?.loanEconomics?.armLookBackDays ?? null,
            armRoundFlag: loan?.loanEconomics?.armRoundFlag ?? "",
            armRoundingFactor: loan?.loanEconomics?.armRoundingFactor ?? "",
            includeOutOfPocketBudgetInARLTV:
              loan?.loanEconomics?.includeOutOfPocketBudgetInARLTV ?? true
          }
        },
        thirtyYearBorrowerInformation: {
          errors: null,
          payload: {
            borrowerType: "",
            borrowerEmail: "",
            borrowingEntityName: "",
            billingAddress: "",
            billingPhoneNumber: ""
          }
        },
        thirtyYearLoanInformation: {
          errors: null,
          payload: {
            primaryLoanID: "",
            loanPurpose: "",
            selectedToorakProduct: "",
            cutOffDate: null,
            originationDate: null,
            loanSubmissionDate: null,
            firstPaymentDate: null,
            maturityDate: null,
            selectedRecourse: "",
            selectedFundingChannel: "",
            selectedCross: "",
            numberOfProperties: "",
            condoEligibility: "",
            calculatedCondoEligibility: "",
            sellerPartyId: "",
            originatorCategoryBucket: "",
            takeOutPartnerId: "",
            takeoutPartnerStatus: "",
            eligibleTakeoutPartners: [],
            creditEvent: "No"
          }
        },
        thirtyYearLoanEconomics: {
          errors: null,
          payload: {
            financialInterestReserve: "",
            loanAmount: "",
            currentLoanBalance: "",
            interestRate: "",
            rateType: "",
            interestOnly: "",
            accrualType: "",
            discountPoints: "",
            cashOutAmount: "",
            armProductType: "",
            armIndex: "",
            grossArmMargin: "",
            interestOnlyPeriod: "",
            originalMonthlyPiPayment: "",
            initialRateAdjustmentPeriod: "",
            initialPayAdjustmentPeriod: "",
            initialPeriodicCap: "",
            lifeRateCap: "",
            subsequentPeriodicCap: "",
            paymentAdjustmentFrequency: "",
            rateAdjustmentFrequency: "",
            prepaymentPenaltyMonths: "",
            prepayPenaltyType: "",
            costToLoanRatio: "",
            debtServiceCoverageRatio: "",
            borrowerLiquidity: "",
            originalQualifyingPaymentMonthly: "",
            qualifyingPaymentInReservesMonthly: "",
            lifetimeMinRate: "",
            subordinateFinancing: "",
            businessPurposeOccupancy: "",
            lifetimeMaxRate: "",
            totalOriginationAndDiscountPoints: "",
            includeOutOfPocketBudgetInARLTV: true
          }
        },
        thirtyYearGuarantorInformation: [
          {
            guarantorId: "b17ba8ae-cbeb-4207-89d5-0fdeaef7661d",
            errors: null,
            payload: {
              guarantorLastName: "",
              guarantorMiddleName: "",
              guarantorFirstName: "",
              ssNumber: "",
              passportExpirationDate: "",
              foreignNationalString: "",
              creditScore: "",
              originalCreditReportDate: "",
              guarantorEmail: "",
              pOEntity: "",
              isPrimaryGuarantor: true
            }
          }
        ],
        workflowState: loan.workflowState,
        loanProcessType: loan.loanProcessType,
        tapeToFileStatus: loan.tapeToFileStatus,
        assignedTo: loan.assignedTo,
        ttfAssignedTo: loan.ttfAssignedTo,
        roleOfAssignee: loan.roleOfAssignee,
        qcRequired: loan.qcRequired,
        dueDiligenceDate: loan.dueDiligenceDate,
        isTapeToFileSubmitted: loan.isTapeToFileSubmitted,
        ddReportStatus: loan.ddReportStatus,
        loanLandmarks: loan.loanDetailId?.loanLandmarks,
        loanFees: loan.loanFees,
        achData: loan.achData
      }
    };
  } else {
    selectedRecourseValue = "Full Recourse"; // default value should be Y
    if (loan?.loanInfo?.recourse === false) {
      selectedRecourseValue = "Non-Recourse";
    }
    if (loan.loanInfo?.recourseString) {
      if (
        loan.loanInfo?.recourseString === "Yes" ||
        loan.loanInfo?.recourseString === "Full Recourse" ||
        loan.loanInfo?.recourseString === "Y"
      ) {
        selectedRecourseValue = "Full Recourse";
      } else if (
        loan.loanInfo?.recourseString === "No" ||
        loan.loanInfo?.recourseString === "Non-Recourse" ||
        loan.loanInfo?.recourseString === "N"
      ) {
        selectedRecourseValue = "Non-Recourse";
      }
    }
    let selectedCrossValue;
    if (loan.loanInfo?.crossCollaterlization === true) {
      selectedCrossValue = "Y";
    } else if (loan.loanInfo?.crossCollaterlization === false) {
      selectedCrossValue = "N";
    } else {
      selectedCrossValue = "";
    }
    let subordinateFinancing = "No"; // default to N
    let businessPurposeOccupancy = "Yes"; // default to Y
    if (loan.loanEconomics?.subordinateFinancing === true) {
      subordinateFinancing = "Yes";
    }
    if (loan.loanEconomics?.businessPurposeOccupancy === false) {
      businessPurposeOccupancy = "No";
    }
    let currentLoanBalance = null;
    if (!isEmptyValue(loan.loanEconomics?.currentLoanBalance)) {
      currentLoanBalance = loan.loanEconomics?.currentLoanBalance
        ? formatValueByType(
          loan.loanEconomics?.currentLoanBalance.toString().replace(/,/g, ""),
          "currency"
        )
        : "";
    } else {
      currentLoanBalance = loan.loanEconomics?.originalLoanAmount
        ? formatValueByType(
          loan.loanEconomics?.originalLoanAmount.toString().replace(/,/g, ""),
          "currency"
        )
        : "";
    }
    returnValue = {
      loanDetails: {
        rateLockExpiryDate: loan.rateLockExpiryDate,
        sizerRateLockExpiryDate: loan.sizerRateLockExpiryDate,
        rateLockExtensions: loan?.loanDetailId?.rateLockExtensions,
        loanId: loan?.loanDetailId?.loanId,
        activePropertyId: 1,
        activeTab,
        loanConfig,
        loanSummary: loanSummaryObj,
        loanDetailId: loan.loanDetailId,
        loanCredit: loan.loanCredit,
        dueDiligencePartyInfo: loan?.dueDiligencePartyInfo,
        loanState: loan.loanState,
        originatorInfo: loan.originatorInfo,
        thirtyYearGuarantorInformation: guarantors,
        loanType: loan.loanTypeId.loanTypeName,
        bridgeLoanBorrowerInformation: borrowers,
        bridgeLoanGuarantorInformation: guarantors,
        bridgeLoanInformation: {
          errors: null,
          payload: {
            primaryLoanID: "",
            numberOfProperties: "",
            extensionOption: "",
            loanPurpose: "",
            selectedLoanStructure: "",
            selectedRecourse: "",
            selectedCross: "",
            selectedToorakProduct: "",
            selectedFundingChannel: "",
            cutOffDate: null,
            originationDate: null,
            loanSubmissionDate: null,
            firstPaymentDate: null,
            maturityDate: null,
            pledgeOfEquity: "",
            sellerPartyId: "",
            originatorCategoryBucket: ""
          }
        },
        bridgeLoanEconomics: {
          errors: null,
          payload: {
            interestRate: "",
            accrualType: "",
            financialInterestReserve: "",
            loanAmount: "",
            maxLoanAmount: "",
            cutOffLoanAmount: "",
            cutOffMaxLoanAmount: "",
            budgetAmount: "",
            cashOutAmount: "",
            discountPoints: "",
            insuranceAndAmortization: "",
            rateType: "",
            armIndex: "",
            grossArmMargin: "",
            initialRateAdjustmentPeriod: "",
            initialPayAdjustmentPeriod: "",
            initialInterestRateDown: "",
            initialInterestRateUp: "",
            lifetimeMaxRate: "",
            lifetimeMinRate: "",
            subsequentInterestRateDown: "",
            subsequentInterestRateUp: "",
            paymentAdjustmentFrequency: "",
            rateAdjustmentFrequency: "",
            armLookBackDays: null,
            armRoundFlag: "",
            armRoundingFactor: "",
            includeOutOfPocketBudgetInARLTV: true
          }
        },
        thirtyYearBorrowerInformation: {
          errors: null,
          payload: {
            borrowerType: "",
            borrowerEmail: "",
            borrowingEntityName: "",
            billingAddress: "",
            billingPhoneNumber: ""
          }
        },
        thirtyYearLoanInformation: {
          errors: null,
          payload: {
            loanState: loan.loanState,
            rateLockedRateSheet:
              loan?.loanDetailId?.loanConfigId?.rateLockedRateSheet,
            rateLockPeriod: loan.loanDetailId?.loanConfigId?.rateLockPeriod,
            sizerRateLockPeriod:
              loan.loanDetailId?.loanConfigId?.sizerRateLockPeriod,
            rateLockExtensions: loan?.loanDetailId?.rateLockExtensions,
            servicerName: loan.loanDetailId?.loanConfigId?.servicerName,
            servicerPartyId: loan.loanDetailId?.loanConfigId?.servicerPartyId,
            primaryLoanID: loan.loanInfo?.primaryLoanId,
            loanPurpose: loan.loanInfo?.loanPurpose,
            cutOffDate: loan.loanInfo?.cutOffDate,
            originationDate: loan.loanInfo?.originationDate
              ? loan.loanInfo?.originationDate
              : null,
            loanSubmissionDate: loan.loanSubmissionDate
              ? loan.loanSubmissionDate
              : null,
            defaultRate: loan.loanInfo?.defaultRate
              ? loan.defaultRate || loan.loanInfo?.defaultRate
              : null,
            defaultRateCurable: loan.loanInfo?.defaultRateCurable
              ? loan.loanInfo?.defaultRateCurable
              : null,
            firstPaymentDate: loan.loanInfo?.firstPaymentDateOfLoan
              ? loan.loanInfo?.firstPaymentDateOfLoan
              : null,
            maturityDate: loan.loanInfo?.originalMaturityDate
              ? loan.loanInfo?.originalMaturityDate
              : null,
            selectedRecourse: selectedRecourseValue,
            selectedCross: selectedCrossValue,
            selectedToorakProduct: loan.loanInfo?.toorakProduct,
            selectedFundingChannel: selectedFundingChannelValue,
            numberOfProperties: loan.loanInfo?.noOfProperties,
            condoEligibility: loan.loanInfo?.condoEligibility,
            calculatedCondoEligibility:
              loan.loanInfo?.calculatedCondoEligibility,
            sellerPartyId: loan?.loanDetailId?.loanConfigId?.sellerPartyId,
            originatorCategoryBucket:
              loan.loanDetailId?.originatorCategoryBucket,
            takeoutPartnerId: loan.loanInfo?.takeoutPartner,
            takeoutPartnerStatus: loan.loanInfo?.takeoutPartnerStatus,
            eligibleTakeoutPartners: loan.eligibleTakeoutPartners,
            creditEvent: loan.loanInfo?.creditEvent || "No"
          }
        },
        thirtyYearLoanEconomics: {
          errors: null,
          payload: {
            financialInterestReserve: loan.loanEconomics
              ?.financedInterestReserve
              ? formatValueByType(
                loan.loanEconomics?.financedInterestReserve
                  .toString()
                  .replace(/,/g, ""),
                "currency"
              )
              : "$0.00",
            loanAmount: loan.loanEconomics?.originalLoanAmount
              ? formatValueByType(
                loan.loanEconomics?.originalLoanAmount
                  .toString()
                  .replace(/,/g, ""),
                "currency"
              )
              : "",
            interestRate: isEmptyValue(
              loan.loanEconomics?.interestRateAsOfCutOffDate
            )
              ? ""
              : formatValueByType(
                loan.loanEconomics?.interestRateAsOfCutOffDate,
                "percentageWithout100x"
              ),
            accrualType: loan.loanEconomics?.accrualType || "30/360",
            discountPoints: loan.loanEconomics
              ?.totalOriginationAndDiscountPoints
              ? formatValueByType(
                loan.loanEconomics?.totalOriginationAndDiscountPoints,
                "percentageWithout100x"
              )
              : "",
            cashOutAmount: loan.loanEconomics?.cashOutAmount
              ? formatValueByType(
                loan.loanEconomics?.cashOutAmount
                  .toString()
                  .replace(/,/g, ""),
                "currency"
              )
              : "",
            armIndex: loan.loanEconomics?.armIndex
              ? loan.loanEconomics?.armIndex
              : "0",

            armProductType: loan.loanEconomics?.armProductType
              ? loan.loanEconomics?.armProductType
              : "",
            costToLoanRatio: !isEmptyValue(loan.loanEconomics?.costToLoanRatio)
              ? formatValueByType(
                loan.loanEconomics?.costToLoanRatio,
                "percentageWithout100x"
              )
              : "",
            currentLoanBalance,
            debtServiceCoverageRatio: "", // lpp-5116
            grossArmMargin: loan.loanEconomics?.grossArmMargin
              ? sanitizePercentage(loan.loanEconomics?.grossArmMargin)
              : sanitizePercentage(0),
            initialPayAdjustmentPeriod: loan.loanEconomics
              ?.initialPayAdjustmentPeriod
              ? loan.loanEconomics?.initialPayAdjustmentPeriod
              : "0",
            initialPeriodicCap: loan.loanEconomics?.initialPeriodicCap
              ? formatValueByType(
                loan.loanEconomics?.initialPeriodicCap,
                "decimal"
              )
              : "0",
            initialRateAdjustmentPeriod: loan.loanEconomics
              ?.initialRateAdjustmentPeriod
              ? loan.loanEconomics?.initialRateAdjustmentPeriod
              : "0",
            interestOnlyPeriod: loan?.loanEconomics?.interestOnlyPeriod ?? "0",
            lifeRateCap: loan.loanEconomics?.lifeRateCap
              ? loan.loanEconomics?.lifeRateCap
              : "",
            originalMonthlyPiPayment: loan.loanEconomics
              ?.originalMonthlyPiPayment
              ? formatValueByType(
                loan.loanEconomics?.originalMonthlyPiPayment.replace(
                  /,/g,
                  ""
                ),
                "currency"
              )
              : "",
            paymentAdjustmentFrequency: loan.loanEconomics
              ?.paymentAdjustmentFrequency
              ? loan.loanEconomics?.paymentAdjustmentFrequency
              : "0",
            prepayPenaltyType: loan.loanEconomics?.prepayPenaltyType
              ? loan.loanEconomics?.prepayPenaltyType
              : "",
            prepaymentPenaltyMonths: loan.loanEconomics?.prepaymentPenaltyMonths
              ? loan.loanEconomics?.prepaymentPenaltyMonths
              : "",
            rateAdjustmentFrequency: loan.loanEconomics?.rateAdjustmentFrequency
              ? loan.loanEconomics?.rateAdjustmentFrequency
              : "0",
            rateType: loan.loanEconomics?.rateType
              ? loan.loanEconomics?.rateType
              : "Fixed",
            interestOnly: loan.loanEconomics?.interestOnly,
            subsequentPeriodicCap: loan.loanEconomics?.subsequentPeriodicCap
              ? formatValueByType(
                loan.loanEconomics?.subsequentPeriodicCap,
                "decimal"
              )
              : "0.00",
            borrowerLiquidity: loan.loanEconomics?.borrowerLiquidity
              ? formatValueByType(
                loan.loanEconomics?.borrowerLiquidity.replace(/,/g, ""),
                "currency"
              )
              : "",
            originalQualifyingPaymentMonthly: loan.loanEconomics
              ?.originalQualifyingPaymentMonthly
              ? formatValueByType(
                loan.loanEconomics?.originalQualifyingPaymentMonthly.replace(
                  /,/g,
                  ""
                ),
                "currency"
              )
              : "",
            qualifyingPaymentInReservesMonthly: loan.loanEconomics
              ?.qualifyingPaymentInReservesMonthly
              ? loan.loanEconomics?.qualifyingPaymentInReservesMonthly
              : "",
            lifetimeMinRate: isEmptyValue(loan.loanEconomics?.lifetimeMinRate)
              ? "0.0000"
              : formatValueByType(
                loan.loanEconomics?.lifetimeMinRate,
                "percentageWithout100x"
              ),
            subordinateFinancing,
            businessPurposeOccupancy,
            lifetimeMaxRate: isEmptyValue(loan.loanEconomics?.lifetimeMaxRate)
              ? "0.0000"
              : formatValueByType(
                loan.loanEconomics?.lifetimeMaxRate,
                "percentageWithout100x"
              ),
            subsequentInterestRateDown:
              loan?.loanEconomics?.subsequentInterestRateDown ?? "",
            subsequentInterestRateUp:
              loan?.loanEconomics?.subsequentInterestRateUp ?? "",
            armLookBackDays: loan?.loanEconomics?.armLookBackDays ?? null,
            armRoundFlag: loan?.loanEconomics?.armRoundFlag ?? "",
            armRoundingFactor: loan?.loanEconomics?.armRoundingFactor ?? "",
            initialInterestRateDown:
              loan?.loanEconomics?.initialInterestRateDown ?? "",
            initialInterestRateUp:
              loan?.loanEconomics?.initialInterestRateUp ?? ""
          }
        },
        fesLoanCondo: {
          monthlyHOAFee: loan?.loanCondominium?.monthlyHOAFee,
          hoaDuesAboveFifteen: !isNullOrUndefined(
            loan?.loanCondominium?.hoaDuesAboveFifteen
          )
            ? loan?.loanCondominium?.hoaDuesAboveFifteen !== true
              ? "Yes"
              : "No"
            : null,
          hoaDuesAboveTwenty: !isNullOrUndefined(
            loan?.loanCondominium?.hoaDuesAboveTwenty
          )
            ? loan?.loanCondominium?.hoaDuesAboveTwenty === true
              ? "Yes"
              : "No"
            : null,
          reserveFundsForOneYear: !isNullOrUndefined(
            loan?.loanCondominium?.reserveFundsForOneYear
          )
            ? loan?.loanCondominium?.reserveFundsForOneYear === true
              ? "Yes"
              : "No"
            : null,
          reserveFundsForTwoYears: !isNullOrUndefined(
            loan?.loanCondominium?.reserveFundsForTwoYears
          )
            ? loan?.loanCondominium?.reserveFundsForTwoYears === true
              ? "Yes"
              : "No"
            : null,
          isProjectCompleted: !isNullOrUndefined(
            loan?.loanCondominium?.isProjectCompleted
          )
            ? loan?.loanCondominium?.isProjectCompleted === true
              ? "Yes"
              : "No"
            : null,
          subjectToAdditionalPhasing: !isNullOrUndefined(
            loan?.loanCondominium?.subjectToAdditionalPhasing
          )
            ? loan?.loanCondominium?.subjectToAdditionalPhasing === true
              ? "Yes"
              : "No"
            : null,
          soldPercentage: !isNullOrUndefined(
            loan?.loanCondominium?.soldPercentage
          )
            ? JSON.stringify(loan?.loanCondominium?.soldPercentage * 100)
            : null,
          hoaUnderOwnerControl: !isNullOrUndefined(
            loan?.loanCondominium?.hoaUnderOwnerControl
          )
            ? loan?.loanCondominium?.hoaUnderOwnerControl === true
              ? "Yes"
              : "No"
            : null,
          simpleEstateOwnership: !isNullOrUndefined(
            loan?.loanCondominium?.simpleEstateOwnership
          )
            ? loan?.loanCondominium?.simpleEstateOwnership === true
              ? "Yes"
              : "No"
            : null,
          ownerOccupiedUnits: loan?.loanCondominium?.ownerOccupiedUnits,
          renterOccupiedUnits: loan?.loanCondominium?.renterOccupiedUnits,
          renterOccupiedPercentage: !isNullOrUndefined(
            loan?.loanCondominium?.renterOccupiedPercentage
          )
            ? JSON.stringify(
              loan?.loanCondominium?.renterOccupiedPercentage * 100
            )
            : null,
          individualOwnershipAboveTwentyFive: !isNullOrUndefined(
            loan?.loanCondominium?.individualOwnershipAboveTwentyFive
          )
            ? loan?.loanCondominium?.individualOwnershipAboveTwentyFive === true
              ? "Yes"
              : "No"
            : null,
          masterAssurancePolicy: !isNullOrUndefined(
            loan?.loanCondominium?.masterAssurancePolicy
          )
            ? loan?.loanCondominium?.masterAssurancePolicy === true
              ? "Yes"
              : "No"
            : null,
          projectHasMobileHomes: !isNullOrUndefined(
            loan?.loanCondominium?.projectHasMobileHomes
          )
            ? loan?.loanCondominium?.projectHasMobileHomes === true
              ? "Yes"
              : "No"
            : null,
          otherSourcesIncomeMoreThanTwenty: !isNullOrUndefined(
            loan?.loanCondominium?.isProjectCompleted
          )
            ? loan?.loanCondominium?.otherSourcesIncomeMoreThanTwenty === true
              ? "Yes"
              : "No"
            : null,
          commercialPurposesAreaMoreThanForty: !isNullOrUndefined(
            loan?.loanCondominium?.commercialPurposesAreaMoreThanForty
          )
            ? loan?.loanCondominium?.commercialPurposesAreaMoreThanForty ===
              true
              ? "Yes"
              : "No"
            : null,
          restrictOwnerToRent: !isNullOrUndefined(
            loan?.loanCondominium?.restrictOwnerToRent
          )
            ? loan?.loanCondominium?.restrictOwnerToRent === true
              ? "Yes"
              : "No"
            : null,
          documentsWithSEC: !isNullOrUndefined(
            loan?.loanCondominium?.documentsWithSEC
          )
            ? loan?.loanCondominium?.documentsWithSEC === true
              ? "Yes"
              : "No"
            : null,
          hoawithLitOrBankruptcy: !isNullOrUndefined(
            loan?.loanCondominium?.hoawithLitOrBankruptcy
          )
            ? loan?.loanCondominium?.hoawithLitOrBankruptcy === true
              ? "Yes"
              : "No"
            : null,
          ownerControlStartDate: loan?.loanCondominium?.ownerControlStartDate,
          indivdualOwnedUnits: loan?.loanCondominium?.indivdualOwnedUnits,
          hoawithLitOrBankruptcyDesc:
            loan?.loanCondominium?.hoawithLitOrBankruptcyDesc,
          restrictOwnerToRentOutDesc:
            loan?.loanCondominium?.restrictOwnerToRentOutDesc,
          condoProjectName: loan?.loanCondominium?.condoProjectName,
          streetAddressLine1: loan?.loanCondominium?.streetAddressLine1,
          noOfUnits: loan?.loanCondominium?.noOfUnits,
          state: loan?.loanCondominium?.state,
          city: loan?.loanCondominium?.city,
          postalCode: loan?.loanCondominium?.postalCode
        },
        workflowState: loan.workflowState,
        loanProcessType: loan.loanProcessType,
        tapeToFileStatus: loan.tapeToFileStatus,
        dueDiligenceDate: loan.dueDiligenceDate,
        assignedTo: loan.assignedTo,
        ttfAssignedTo: loan.ttfAssignedTo,
        roleOfAssignee: loan.roleOfAssignee,
        qcRequired: loan.qcRequired,
        isTapeToFileSubmitted: loan.isTapeToFileSubmitted,
        ddReportStatus: loan.ddReportStatus,
        loanLandmarks: loan.loanDetailId?.loanLandmarks
      }
    };
  }
  if (loan.loanRuleVersions) {
    returnValue.loanDetails = {
      ...returnValue.loanDetails,
      loanRuleVersions: loan.loanRuleVersions
    };
  }
  return returnValue;
}

export function getDocDetailsGeneric(
  pIden: string,
  pIdenVal: string,
  sIden: string = "",
  sIdenVal: string = ""
) {
  let url = `${getConfig().apiUrl
    }/documentdetails/${pIden}/${pIdenVal}/documents`;
  if (sIden && sIdenVal) {
    url += `?secondary_identifier_name=${sIden}&secondary_identifier_value=${sIdenVal}`;
  }
  return publicClient.get(url);
}

export function getNameFromPartyId(
  partyIds: any,
  customerObj: boolean = false
): any {
  // let tempParameter = `partyIds=${partyIds[0]}`;
  // if (partyIds.length > 1) {
  //   for (let i = 1; i < partyIds.length; i++) {
  //     tempParameter = `${tempParameter}&partyIds=${partyIds[i]}`;
  //   }
  // }
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/customer/fetch`;
    const resp = await publicClient.post(url, { partyIds }, {
      errorConfig: getErrorConfigObject({
        skipErrorHandling: true
      })
    } as any);
    if (customerObj) {
      // dispatch({
      //   type: CUSTOMER_INFO,
      //   payload: resp.data
      // });
      dispatch(
        customerInfo({
          customerInfo: resp.data
        })
      );
    } else {
      // dispatch({
      //   type: FETCH_PARTYID,
      //   payload: resp.data
      // });
      dispatch(fetchPartyId(resp.data));
    }
  };
}

export function getLoanListCount(
  stages: any,
  searchKey?: any,
  filterSelections?: any,
  timeInterval?: any,
  toggleArr?: any[],
  tabFilter?: any
): any {
  const originalToggles: any = ["Purchased", "Rejected", "Withdrawn"];
  const mustNotToggle: any =
    toggleArr &&
    toggleArr?.length &&
    originalToggles.filter((item: any) => !toggleArr.includes(item));
  const stagesParam = stages?.length;
  let searchQuery: any;
  const value = searchKey;
  let accountIds: any = localStorage.getItem("account_ids");
  accountIds = JSON.parse(accountIds);
  let objForQueryMod = [] as any;
  let should = [] as any;
  if (filterSelections !== undefined) {
    const search_filter: any = [];
    const _searchObject = filterSelections;
    for (const key in _searchObject.filterSelections) {
      if (key.includes("bool")) {
        search_filter.push({ bool: _searchObject.filterSelections.bool });
      } else if (
        key.includes("_searchBox") &&
        _searchObject.filterSelections[key].length === 1 &&
        !key.includes("loanId") &&
        !key.includes("tradeId") &&
        !key.includes("primaryLoanId")
      ) {
        search_filter.push({
          match_phrase_prefix: {
            [key.replace(".raw_searchBox", "")]: {
              query: _searchObject.filterSelections[key][0],
              analyzer: "simple"
            }
          }
        });
      } else {
        search_filter.push({
          terms: {
            [key.replace("_searchBox", "")]: _searchObject.filterSelections[key]
          }
        });
      }
    }
    if (search_filter.length)
      objForQueryMod = [...objForQueryMod, ...search_filter];
  }
  // if (
  //   getCookie("globalSearchValue") !== null &&
  //   getCookie("globalSearchValue") !== ""
  // ) {
  //   objForQueryShould = getGlobalResultObject(getCookie("globalSearchValue"));
  // }

  const objForQuery = JSON.parse(JSON.stringify(objForQueryMod));
  for (let i = 0; i < Object.keys(objForQuery).length; i++) {
    const checkLoanState = objForQuery[i]
      ? objForQuery[i].terms
        ? objForQuery[i].terms[`loan.loanState.raw`]
          ? objForQuery[i].terms[`loan.loanState.raw`]
          : -1
        : -1
      : -1;
    if (checkLoanState !== -1) {
      const index = objForQuery[i].terms[`loan.loanState.raw`].findIndex(
        (ele: any) => ele === "Review In Progress"
      );
      if (index !== -1) {
        objForQuery[i].terms[`loan.loanState.raw`][index] =
          LoanStatusEnum.DueDiligenceReview;
        break;
      }
    }
  }
  const mustnotArray: any = [];
  if (!isILP()) {
    if (mustNotToggle && mustNotToggle.length !== 0) {
      mustnotArray.push({
        terms: {
          "loan.loanState.raw": mustNotToggle
        }
      });
    } else if (toggleArr && toggleArr.length === 0)
      mustnotArray.push({
        terms: {
          "loan.loanState.raw": originalToggles
        }
      });
  }

  if (stages?.length === 2) {
    mustnotArray.push({
      terms: {
        "loan.loanStage.raw": [LoanStage.fes]
      }
    });
  }
  if (stages?.length === 1 && stages[0] !== "ON-HOLD") {
    mustnotArray.push({
      terms: {
        "loan.loanStage.raw": [LoanStage.pre, LoanStage.post]
      }
    });
  }
  if (value || value !== "") {
    objForQuery.push({
      bool: {
        should: getGlobalResultObject(value)
      }
    });
  }

  if (getCookie("loginFlow") === "originator") {
    objForQuery.push({
      terms: {
        "loan.loanDetailId.originatorPartyId.raw": [getCookie("org_id")]
      }
    });
  } else {
    if (!isILP()) {
      stagesParam === 1 && stages[0] !== "ON-HOLD"
        ? mustnotArray.push({
          terms: {
            "loan.loanState.raw":
              stages[0] === LoanStage.fes && isLATUser()
                ? [LoanStatusEnum.ExcelError, LoanStatusEnum.DataEntry]
                : [LoanStatusEnum.ExcelError]
          }
        })
        : mustnotArray.push(
          {
            terms: {
              "loan.loanState.raw":
                getCookie("GUIDE") === "selected" && isLATUser()
                  ? [LoanStatusEnum.DataEntry, LoanStatusEnum.ExcelError]
                  : [LoanStatusEnum.ExcelError]
            }
          },
          stagesParam === 2
            ? appendToQueryForLATToggle(stagesParam)
            : stagesParam === 3
              ? appendToQueryForLATToggle(stagesParam)
              : "",
          appendToQueryForLAT1(),
          appendToQueryForLAT2()
        );
    }
    objForQuery.push({
      terms: {
        "loan.loanDetailId.originatorPartyId.raw": accountIds
      }
    });
  }
  if (!isILP()) {
    if (stagesParam === 1 && stages[0] === "ON-HOLD") {
      objForQuery.push(onHoldQuery);
    } else {
      objForQuery.push(noOnHoldQuery);
    }
    handleRolesPrivileges(mustnotArray);
  }

  if (!stages?.includes("ON-HOLD")) {
    objForQuery.push({
      terms: {
        "loan.loanStage.raw": stages
      }
    });
  }

  if (isILP()) {
    const appendedQueries: any = appendILPQueries(
      objForQuery,
      timeInterval,
      mustnotArray
    );
    should = appendedQueries.should;
  }
  objForQuery.forEach((element: any, index: any, arr: any) => {
    arr[index] = element;
    if (arr[index]?.terms?.["loan.loanDetailId.isWarehouseFunded"]) {
      arr[index].terms["loan.loanDetailId.isWarehouseFunded"] = [true];
    }
  });
  if (isLATUser()) {
    searchQuery = {
      bool: {
        must: objForQuery,
        must_not: mustnotArray
      }
    };
    if (should.length) {
      searchQuery.bool.should = should;
    }
  } else if (mustnotArray.length) {
    searchQuery = {
      bool: {
        must: objForQuery,
        must_not: mustnotArray
      }
    };
  } else {
    searchQuery = {
      bool: {
        must: objForQuery
      }
    };
  }
  if (isRole(UserRole.LAT_TREASURER)) {
    if (searchQuery.bool.must.length > 1) {
      const indexToRemove = getESIndexToDelete(searchQuery.bool.must);
      searchQuery.bool.must.splice(indexToRemove, 1);
    } else {
      delete searchQuery.bool.must;
    }
  }
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${getConfig().apiUrl}/search/${getConfig().aggregateUrl
        }/_search`;
      const exceptionsPending = isLATUser()
        ? {
          range: {
            field: "currentPendingAllExceptionCount",
            ranges: [
              {
                from: 1
              }
            ]
          }
        }
        : {
          range: {
            field: "currentPendingExternalExceptionCount",
            ranges: [
              {
                from: 1
              }
            ]
          }
        };

      const enquiriesPending = isLATUser()
        ? {
          range: {
            field: "currentPendingAllEnquiryCount",
            ranges: [
              {
                from: 1
              }
            ]
          }
        }
        : {
          range: {
            field: "currentPendingExternalEnquiryCount",
            ranges: [
              {
                from: 1
              }
            ]
          }
        };

      const waiversPending = isLATUser()
        ? {
          range: {
            field: "currentPendingAllWaiverCount",
            ranges: [
              {
                from: 1
              }
            ]
          }
        }
        : {
          range: {
            field: "currentPendingExternalWaiverCount",
            ranges: [
              {
                from: 1
              }
            ]
          }
        };

      const scriptFields: any = {};
      scriptFields.loanDynamicStatus = {
        script: {
          source:
            "if(doc['loan.lastLoanStatusUpdateDate'].empty) { return 0} long days = ((new Date().getTime() - doc['loan.lastLoanStatusUpdateDate'].value.millis) / (1000*60*60*24)); return days"
        }
      };
      const response = await publicClient.post(
        url,
        {
          script_fields: scriptFields,
          query: searchQuery,
          aggs: {
            totalLoanAmount: {
              sum: {
                script: {
                  source:
                    "if (doc.containsKey('loan.loanEconomics.originalLoanAmount.raw') && doc['loan.loanEconomics.originalLoanAmount.raw'].size() > 0) {return Double.parseDouble(doc['loan.loanEconomics.originalLoanAmount.raw'].value.replace(',', '').replace('$', ''));} else {return 0;}"
                }
              }
            },
            party: {
              terms: {
                field: "loan.loanDetailId.originatorPartyId.raw",
                size: 50
              }
            },
            ...(!isILP() && {
              waiversPending,
              errorsPending: {
                range: {
                  field: "loan.errorsPending",
                  ranges: [
                    {
                      from: 1
                    }
                  ]
                }
              },
              fieldsPending: {
                range: {
                  field: "loan.fieldsPending",
                  ranges: [
                    {
                      from: 1
                    }
                  ]
                }
              },
              exceptionsPending,
              enquiriesPending
            })
          },
          size: 0
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      const loanCountData: any = response?.data?.response?.aggregations;
      for (const key in loanCountData) {
        if (!loanCountData.hasOwnProperty(key)) continue;
        const obj = loanCountData[key];
        for (const prop in obj) {
          if (!obj.hasOwnProperty(prop)) continue;
          key !== "party" &&
            // dispatch({
            //   type: LOAN_LIST_COUNT,
            //   payload: {
            //     loanLists: obj[prop][0]?.doc_count,
            //     tab: key
            //   }
            // });
            dispatch(
              loanListCount({
                loanLists: obj[prop][0]?.doc_count,
                tab: key
              })
            );
        }
      }
      // dispatch({
      //   type: LOAN_LIST_COUNT,
      //   payload: {
      //     loanLists: response?.data?.response?.hits?.total?.value,
      //     loanAmount:
      //       response?.data?.response?.aggregations?.totalLoanAmount?.value,
      //     tab: "allTab"
      //   }
      // });
      dispatch(
        loanListCount({
          loanLists: response?.data?.response?.hits?.total?.value,
          loanAmount:
            response?.data?.response?.aggregations?.totalLoanAmount?.value,
          tab: "allTab"
        })
      );

      dispatch(hideLoader());
    } catch (err) {
      const e: any = err;
      console.error(e);
      dispatch(hideLoader());
      dispatch(
        loanTypeFailed({
          error: {
            message: e?.message
          }
        })
      );
    }
  };
}

export function deleteResponseAndUpdateArr(
  loanStage: string,
  loanId: string,
  exceptionId: string,
  reponseId: string,
  chatSequenceId: string,
  waiverId: any
): any {
  return async (dispatch: any) => {
    try {
      const url = `${getConfig().apiUrl
        }/exceptionservice/${loanId}/exceptions/${exceptionId}/responses/${reponseId}?loanStage=${loanStage}`;
      await publicClient.delete(url);
      // dispatch({
      //   type: DELETE_ACT_INT_ASKQUEST,
      //   payload: {
      //     chatSequenceId,
      //     exceptionId,
      //     waiverId
      //   }
      // });
      dispatch(
        deleteActIntAskQuest({
          chatSequenceId,
          exceptionId,
          waiverId
        })
      );
    } catch (e) {
      console.error("deleteResponseByResponseId", e);
    }
  };
}

export const getChatsAndResponse: any = (
  loanId: any,
  loanStage: any,
  visibility: any,
  id: any,
  cardType: any,
  type?: any,
  waiverId?: any
) => {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader(true));
      const visibilityVal = isLATUser() ? "internal,external" : visibility;
      let scrollPosPopup = 0;
      const activeIntContainer = document.getElementById("activeIntContainer");
      if (activeIntContainer) {
        scrollPosPopup = activeIntContainer.scrollTop;
      }
      const url = `${getConfig().apiUrl
        }/aggregate/loans/${loanId}/resource/${id}?resourceType=${cardType}&secondaryIdentifierName=LOAN_STAGE&secondaryIdentifierValue=${loanStage}&includeHistory=true&loanStage=${loanStage}&visibility=${visibilityVal}`;
      const response = await publicClient.get(url);
      // dispatch({
      //   type: ADD_RESPONSE_TO_ACTIVEINT_ARRAY,
      //   payload: { data: response.data, id, type, waiverId }
      // });
      dispatch(
        addResponseToActiveIntArray({
          data: response.data,
          id,
          type,
          waiverId
        })
      );

      if (activeIntContainer) {
        activeIntContainer.scrollTop = scrollPosPopup;
        setIntervalX(
          () => {
            const loaderEle: any = document.querySelector(
              '[data-testid="loader"]'
            );
            if (activeIntContainer && loaderEle?.style?.opacity === "1") {
              activeIntContainer.scrollTop = scrollPosPopup;
            }
          },
          250,
          14
        );
      }
      dispatch(hideLoader(true));
    } catch (err) {
      dispatch(hideLoader(true));
      const e: any = err;
      console.error(e);
    }
  };
};

/**
 *Loan Conversion section starts
 *onlyImport:  used to import the documents not convert loan
 *  */

export function convertLoan(
  loanId: string,
  currentStage: LoanStage,
  newStage: LoanStage,
  isImport: boolean,
  onlyImport: boolean = false,
  updateValue?: boolean,
  body?: any,
  tableFundingStatus?: string,
  carryForwardLoanState?: boolean,
  fundingType?: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    let url = `${getConfig().apiUrl}/aggregate/${onlyImport ? "documents/LOAN" : "loan"
      }/${loanId}/convert?fromStage=${currentStage}&toStage=${newStage}&waiverCarryForward=true`;
    if (updateValue) {
      url = `${url}&updateValue=true`;
    }
    if (isImport) {
      url = `${url}&updateDocs=true`;
    }
    if (carryForwardLoanState) {
      url = `${url}&carryForwardLoanState=true`;
    }
    if (currentStage === LoanStage.tv) {
      url = `${url}&toorvalId=${loanId}`;
    }
    if (
      currentStage === LoanStage.fes &&
      (newStage === LoanStage.pre || newStage === LoanStage.post)
    ) {
      url = `${url}&carryForwardRateLockFES=true`;
    }
    const fundingTypeParmas =
      tableFundingStatus === "Yes" ? fundingType : "normal";
    url = `${url}&fundingType=${fundingTypeParmas}`;

    publicClient
      .put(url, body || {})
      .then(async (res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

/**
 * Function to copy loan details from one loanLd to another loanId in same stage
 */
export function copyLoan(
  toLoanId: string,
  fromLoanId: string,
  stage: LoanStage
): Promise<any> {
  return new Promise((resolve, reject) => {
    const originatorId = getCookie("org_id");
    const url = `${getConfig().apiUrl
      }/aggregate/loan/copy?originatorIds=${originatorId}&getLoanId=${toLoanId}&putLoanId=${fromLoanId}&stage=${stage}`;

    publicClient
      .put(url)
      .then(async (res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function convertProperty(
  loanId: string,
  currentStage: LoanStage,
  newStage: LoanStage
): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/loan_property/${loanId}/properties/copy?fromStage=${currentStage}&toStage=${newStage}`;
    publicClient
      .post(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function updatePreviousStage(
  getLoanResponse: any,
  loanStage: LoanStage,
  loanId: string
): any {
  return async (dispatch: any) => {
    const loanLandmarks: any[] =
      getLoanResponse?.loanDetailId?.loanLandmarks || [];
    const availableLoanStage: LoanStage[] = [];
    // sort it in this order so that in createLoan component availableLoanStage[0] always gives you the latest stage of loan
    const loanStageOrder: { [key: string]: number } = {
      TOORVAL: 3,
      FES: 3,
      PRE: 2,
      POST: 1
    };
    if (loanLandmarks.length) {
      const deletedLoanStages: any[] = [];
      loanLandmarks.forEach((element) => {
        if (element.name === "loan_delete") {
          deletedLoanStages.push(element.value);
        }
      });
      loanLandmarks.forEach((element) => {
        if (
          element.name === "loan_creation" ||
          element.name === "loan_conversion"
        ) {
          const delIndex = deletedLoanStages.indexOf(element.value);
          if (delIndex > -1) {
            deletedLoanStages.splice(delIndex, 1);
          } else {
            availableLoanStage.push(element.value);
          }
        }
      });
      availableLoanStage.sort((a, b) =>
        loanStageOrder[a] > loanStageOrder[b]
          ? 1
          : loanStageOrder[b] > loanStageOrder[a]
            ? -1
            : 0
      );
      const cookieValue = getCookie("availableLoanStage");
      const availableLoanStageInCookie = cookieValue
        ? JSON.parse(cookieValue)
        : {};
      const newAvailableStages = {
        ...availableLoanStageInCookie,
        ...{ [loanId]: availableLoanStage }
      };
      setCookie("availableLoanStage", JSON.stringify(newAvailableStages));
      const viewMode = isViewOnly(loanStage, loanId);
      // For toorval Loans it is handled in getLoanAppraisalInformation()  at
      // web-app/src/stores/appraisal/appraisal.action.ts
      if (!availableLoanStage.includes(LoanStage.tv)) {
        // dispatch({
        //   type: UPDATE_PREVIOUS_STAGE,
        //   payload: { viewMode, availableLoanStage }
        // });
        dispatch(
          updatePreviousStageRedux({
            viewMode,
            availableLoanStage
          })
        );
      }
    }
  };
}
/**
 *Loan Conversion section ends
 *  */

export function getExceptionChatID(loanId: string, loanStage: string) {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/exceptionservice/${loanId}?loanStage=${loanStage}`;
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function getChatIDStatus(chatID: string) {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/aggregate/chatservice/${chatID}/lines`;
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function triggerBoxUpload(
  loanId: string,
  exceptionId: any
): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/documentaggregatorservice/${loanId}/${exceptionId}/uploadexception?exceptionResponseType=SATISFY_EXCEPTION`;
    publicClient
      .post(url)
      .then(async (res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function getOriginatorAccounts(): any {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/customer/fetch/ORIGINATOR`;
    publicClient.post(url).then((res: any) => {
      const { data } = res;
      let elasticSearchHits: any = [];
      for (let item of data) {
        elasticSearchHits.push({ _source: { ...item } });
      }
      resolve({ data: { response: { hits: { hits: elasticSearchHits } } } });
    });
  });
}

export function getDueDiligenceData(body: any): any {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/aggregate/custom/ORIGINATOR_DD_LIST/value/fetch`;

    publicClient.post(url, body).then((res: any) => {
      console.error(res);
      resolve(res);
    });
  });
}

export function getDueDiligenceDataForSupport(body: any): any {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/custom/ORIGINATOR_DD_LIST/value/fetch`;

    publicClient.post(url, body).then((res: any) => {
      console.error(res);
      resolve(res);
    });
  });
}

export function getLoanUserMap(loanId: string, loanStage: LoanStage): any {
  return async (dispatch: any) => {
    try {
      const url = `${getConfig().apiUrl
        }/aggregate/loans/${loanId}?loanStage=${loanStage}`;
      const resp: any = await publicClient.get(url);
      // dispatch({
      //   type: LOAN_USERMAP_SUCCESS,
      //   payload: resp?.data?.loanUserMap
      // });
      dispatch(
        loanUserMapSuccess({
          loanUserMap: resp?.data?.loanUserMap
        })
      );
    } catch (err) {
      console.error(err);
    }
  };
}
export function formatResponse(response: any): any {
  const rqstBody = response;
  rqstBody?.loanUserMap?.forEach((item: any) => {
    if (item?.customer && !Object.keys(item?.customer)?.includes("accountType")) {
      item.customer.accountType = "";
    }
  });
  return rqstBody;
}

export function updateLoanStagesProceed(loanStage: any): any {
  const userId = getCookie("person_id") || "userId";
  const currentFilters = JSON.parse(localStorage.getItem("filters") || "{}");
  let updatedFilters = currentFilters?.[userId];
  updatedFilters = { ...updatedFilters, loanStage };
  localStorage.setItem("filters", JSON.stringify({ [userId]: updatedFilters }));

  return updateLoanStage(loanStage);
  // {
  //   type: UPDATE_LOAN_STAGE,
  //   payload: loanStage
  // };
}

// export function updateTimeInterval(timeInterval: any): any {
//   return {
//     type: UPDATE_TIME_INTERVAL,
//     payload: timeInterval
//   };
// }
// export function updateCompactTab(tabinfo: any): any {
//   return {
//     type: UPDATE_COMPACT_TAB,
//     payload: tabinfo
//   };
// }
// export function updateLoanlistView(viewtype: any): any {
//   return {
//     type: UPDATE_LOAN_LIST_VIEW,
//     payload: viewtype
//   };
// }
// export function updateCompactTabIndex(index: any): any {
//   return {
//     type: UPDATE_COMPACT_TAB_INDEX,
//     payload: index
//   };
// }
export function exportLoanListLoans(loanDetails: any) {
  const url = `${getConfig().apiUrl}/aggregate/export/loans`;
  return publicClient.post(url, loanDetails);
}
export function updateselectedLoans(
  loanIds: any,
  selectedLoanDetails: any[]
): any {
  return (dispatch: any) => {
    dispatch(
      updateSelectedLoansRedux({
        loanIds,
        selectedLoanDetails
      })
    );
  };
}
export function downloadExportedTape(uri: string) {
  const link = document.createElement("a");
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function updateSearchValue(value: any): any {
  return (dispatch: any) => {
    // type: UPDATE_LOAN_LIST_SEARCH,
    // payload: value
    dispatch(
      updateLoanListSearch({
        value
      })
    );
  };
}

// export function setSearchType(val: boolean): any {
//   return {
//     type: SET_SEARCH_TYPE,
//     payload: val
//   };
// }
export function redirectToLoanPage(
  loanType: any,
  loanStage: any,
  loanId: any,
  navigate: any,
  from?: string
): any {
  const loanTypePath = loanType.includes("30")
    ? loanTypeName[1].displayValue
    : loanType;
  return async (dispatch: any) => {
    dispatch(resetAllStores());
    const fromQuery = from ? `?from=${from}` : "";
    const subURL = isILP() ? "internal/" : "";
    navigate(
      `/${subURL}loan/createLoan/${loanTypePath}/${loanStage}/${loanId}${fromQuery}`
    );
  };
}

export function redirectToLoanPageInNewTab(
  loanType: any,
  loanStage: any,
  loanId: any
) {
  if (isILP()) {
    return window.open(
      `${getConfig().redirectUrl
      }internal/loan/createLoan/${loanType}/${loanStage}/${loanId}`
    );
  }
  return window.open(
    `${getConfig().redirectUrl
    }loan/createLoan/${loanType}/${loanStage}/${loanId}`
  );
}
// export function updateLoadingFlag(value: boolean): any {
//   return {
//     type: UPDATE_LOADING_FLAG,
//     payload: value
//   };
// }

export const keepCreditEvalSync: any = (startSync: boolean) => {
  return async (dispatch: any) => {
    // dispatch({
    //   type: SET_CREDIT_SYNC_FLAG,
    //   payload: { startSync }
    // });
    dispatch(setCreditSyncFlag({ startSync }));
  };
};
export function insertQueryDocs(
  reqBody: any,
  exceptionResponseId: any,
  chatSequenceId: any
): Promise<string> {
  return new Promise((resolve, reject) => {
    if (reqBody?.length) {
      reqBody.forEach((item: any) => (item.chatDocument = "true"));
    }
    const url = `${getConfig().apiUrl
      }/docs/CHAT/${exceptionResponseId}/documents?secondary_identifier_name=CHAT_SEQUENCE&secondary_identifier_value=${chatSequenceId}`;

    const header = {
      headers: { "Content-Type": "text/plain" }
    };
    publicClient
      .post(url, reqBody, header)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

// To show the summary checkList Modal from list view
// export function showSummaryCheckListModal(): any {
//   return {
//     type: SHOW_SUMMARY_CHECK_LIST_MODAL
//   };
// }
// To hide the summary checkList Modal from list view
// export function hideSummaryCheckListModal(): any {
//   return {
//     type: HIDE_SUMMARY_CHECK_LIST_MODAL
//   };
// }

// export function singleLoanRowDetails(value: any): any {
//   return {
//     type: SINGLE_LOAN_ROW,
//     payload: value
//   };
// }

export const callExportResults = (
  payload: { loanId: string; loanStage: string }[],
  loanType: string
) => {
  const url: string = `${getConfig().apiUrl}/bulkupload/loans/${loanType}`;
  return publicClient.post(url, payload);
};

function handleRolesPrivileges(mustnotArray: any) {
  if (isRoleBridge()) {
    mustnotArray.push({
      terms: {
        "loan.loanType.raw": ["30 Year Loan"]
      }
    });
  } else if (isRoleDSCR()) {
    mustnotArray.push({
      terms: {
        "loan.loanType.raw": ["Bridge Loan"]
      }
    });
  }
}

// const handleAssignToBasedOnRoleAndViceVersa = (
//   column: any,
//   objForQuery: any,
//   filterSelections: any
// ) => {
//   if (
//     column.key === "assignedTo" &&
//     filterSelections?.filterSelections?.["loan.roleOfAssignee.raw"]?.length > 0
//   ) {
//     objForQuery.push({
//       terms: {
//         "loan.roleOfAssignee.raw":
//           filterSelections.filterSelections["loan.roleOfAssignee.raw"]
//       }
//     });
//   }
//   if (
//     column.key === "roleOfAssignee" &&
//     filterSelections?.filterSelections?.["loan.assignedTo.raw"]?.length > 0
//   ) {
//     objForQuery.push({
//       terms: {
//         "loan.assignedTo.raw":
//           filterSelections?.filterSelections["loan.assignedTo.raw"]
//       }
//     });
//   }
// };

export function exportDdDocument(
  loanId: string,
  loanStage: string
): Promise<any> {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/aggregate/exceptionservice/exportDdDocument/${loanId}?loanStage=${loanStage}`;
    publicClient
      .get(url)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

export const tagDocuments = (reqObj: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/adddoctag/multitag`;
    publicClient
      .post(url, reqObj)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
};

export const addInternalTag = (
  docId: any,
  reqObj: any,
  csScoreObj?: any
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/addComprehendTags/documents/${docId}/tags`;
    publicClient
      .post(url, reqObj)
      .then((res: any) =>
        resolve({
          data: res.data,
          csScoreObj
        })
      )
      .catch(reject);
  });
};

// export function getVersionDate(overlaysVersion: any, loanType: string) {
//   return async (dispatch: any) => {
//     try {
//       const loantype =
//         loanType === `${loanTypeName[0].displayValue}` ? "bridge" : "30year";
//       const url = `${getConfig().apiUrl}/ratesheet/${loantype}/OVERLAYS`;
//       const resp: any = await publicClient.get(url);
//       dispatch({
//         type: SET_VERSION_DATE,
//         response: resp?.data?.data,
//         overlaysVersion: overlaysVersion
//       });
//     } catch (err) {
//       console.log(err);
//     }
//   };
// }
export function updatePreviousLoanStateAction(
  propertyDetails: any,
  activePropertyId: number,
  propertySection: any
) {
  for (let i = 0; i < propertyDetails.length; i++) {
    if (propertyDetails[i].propertyId === activePropertyId) {
      return JSON.parse(JSON.stringify(propertyDetails[i][propertySection]));
    }
  }
}

/**
 *
 * @param {*} reqBody
 * @param {Array<[]>} selectedDocs
 * @returns
 */
export const downloadMultipleDocs = (reqBody: any) => {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/documentaggregatorservice/documentdetails/multidownload`;
    publicClient
      .post(url, reqBody)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
};

/**
 *
 * @param {*} reqBody
 * @returns
 */
export const deleteMultipleDocs = (reqBody: any) => {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/documentaggregatorservice/deletedoc/multidelete`;
    publicClient
      .delete(url, { data: reqBody })
      .then((res: any) => resolve(res))
      .catch(reject);
  });
};
export function updateToggleSelections(toggleSelections: any) {
  const userId = getCookie("person_id") || "userId";
  const currentFilters = JSON.parse(localStorage.getItem("filters") || "{}");
  let updatedFilters = currentFilters?.[userId];
  updatedFilters = { ...updatedFilters, toggleSelections };
  localStorage.setItem("filters", JSON.stringify({ [userId]: updatedFilters }));
  return updateToggleSelectionsRedux({
    toggleSelections
  });
}
// return {
//   type: UPDATE_TOGGLE_SELECTIONS,
//   payload: toggleSelections
// };

export const getOriginatorList = () => {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/aggregate/custom/LAT_LOAN_LIST/values/fetch?sort=+accountName`;
    const data = { userId: getCookie("person_id") };
    // console.log(data);
    publicClient
      .post(url, data)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
};

export const getOriginatorListByPartyId = (person_id: any) => {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/aggregate/custom/LAT_LOAN_LIST/values/fetch`;
    const data = { userId: person_id };
    // console.log("orgList:" + data);
    publicClient
      .post(url, data)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
};

// set loan to on-hold or remove the on-hold from loan
export function onHoldLoan(
  action: "ADD" | "REMOVE",
  loanId: string,
  comment: string
): Promise<string> {
  return new Promise((resolve, reject) => {
    const role = isLATUser() ? "LAT" : "ORIGINATOR";
    // changes as LPP-8143
    const url = `${getConfig().apiUrl
      }/aggregate/loans/${loanId}/hold?stage=POST`;
    const body = {
      onHoldBy: action === "ADD" ? role : "NONE",
      comments: comment
    };
    publicClient
      .put(url, body)
      .then((res: any) => resolve(res))
      .catch(reject);
  });
}

export function updateSelectedVersion(
  sectionName: string,
  selectedVersion: string,
  reset?: boolean
): any {
  return (dispatch: any) => {
    dispatch(
      updateSelectedVersionRedux({
        sectionName,
        selectedVersion,
        reset
      })
    );
  };
}

export function updateLoanRuleVersions(
  loanRuleVersions: any[],
  loanId: string
): any {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl
      }/aggregate/loans/${loanId}/ruleversion?resetOverriddenRateSheetResults=true`;
    const data = loanRuleVersions;
    publicClient.post(url, data).then(resolve).catch(reject);
  });
}

// export function updateSelectedLoanRuleVersions(
//   loanRuleVersions: any[],
//   loanId: string
// ): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_SELECTED_LOAN_RULE_VERSIONS,
//       loanRuleVersions
//     });
//   };
// }

export function getLoanLandmarks(
  loanId: string,
  loanStage: LoanStage
): Promise<any> {
  const url = `${getConfig().apiUrl
    }/aggregate/loans/${loanId}?loanStage=${loanStage}`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function setOnHoldLoan(data: any): any {
  return async (dispatch: any) => {
    // dispatch({
    //   type: UPDATE_ON_HOLD_INFO,
    //   payload: { data }
    // });
    dispatch(
      updateOnHoldInfo({
        onHoldInfo: { data }
      })
    );
  };
}

export function setLoanLandMark(data: any): any {
  return async (dispatch: any) => {
    dispatch(
      updateLoanLandmark({
        data
      })
    );
  };
}
export function getLoanDetails(loanId: string): Promise<any> {
  const url = `${getConfig().apiUrl}/loans/${loanId}/details`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

export const filterDecisionDocs: any = (docList: any[]) => {
  const decisionDocuments = [
    "Waiver Approved",
    "Waiver Approved Conditionally",
    "Waiver Rejected",
    "WAPP",
    "WAPC",
    "WREJ"
  ];
  let filteredList: any[] = [];
  if (docList.length) {
    docList.forEach((doc: any) => {
      if (doc.tag && doc.tag.length) {
        filteredList = doc.tag.filter(
          (value: any) => !decisionDocuments.includes(value)
        );
      } else {
        filteredList.push(doc);
      }
    });
  }
  return filteredList;
};

/*
  --------------Column Customization Related------------------
*/
export const LoanListColumnsPrefProps = {
  pageId: "Main",
  viewId: "all",
  tabId: "LoanList",
  filterName: "filter"
};

export const ILPLoanListColumnsPrefProps = {
  pageId: "ILP-Main",
  viewId: "all",
  tabId: "LoanList",
  filterName: "filter"
};

export const saveUserPreferenceFilter = (
  partyId: string,
  pageId: string,
  viewId: string,
  tabId: string,
  columnReOrdered: any
) => {
  const reqBody: any = {
    partyId,
    page: pageId,
    view: viewId,
    tab: tabId,
    filterName: "filter",
    isDefault: true,
    filter: columnReOrdered,
    sort: {}
  };
  const url = `${getConfig().apiUrl
    }/prefservice/${partyId}/page/${pageId}/view/${viewId}/tab/${tabId}/filters`;
  return publicClient.post(url, reqBody);
};

export const getUserPrefrenceFilter: any = (
  partyId: string,
  pageId: string,
  viewId: string,
  tabId: string
) => {
  const url = `${getConfig().apiUrl
    }/prefservice/${partyId}/page/${pageId}/view/${viewId}/tab/${tabId}/filters`;
  return publicClient.get(url);
};
/*
  ----------------------------------------------------------------------------
*/

export const bulkExportLoans: any = (
  size: any,
  from: any,
  searchObject: any,
  searchedValue?: any,
  stages?: any,
  timeInterval?: any,
  toggleArr?: any[],
  tabsConfig?: any,
  exportType?: string,
  excludeLoans: string[] = []
) => {
  const { DataEntry, Submit, ExcelError } = LoanStatusEnum;
  const mustNotStates = [DataEntry, Submit, ExcelError];
  let elasticSearchQuery: any = getMultiSearchedLoanList(
    { filterSelections: searchObject },
    searchedValue,
    false,
    [],
    stages,
    timeInterval,
    toggleArr,
    tabsConfig,
    true,
    false,
    {},
    mustNotStates
  );
  elasticSearchQuery = // reafactor
    excludeLoans.length > 0
      ? {
        ...elasticSearchQuery,
        query: {
          ...elasticSearchQuery?.query,
          bool: {
            ...elasticSearchQuery?.query.bool,
            must_not: [
              ...elasticSearchQuery?.query.bool.must_not,
              {
                terms: {
                  "loan.loanDetailId.toorakLoanId.raw": excludeLoans
                }
              },
              {
                terms: {
                  "loan.loanDetailId.loanId.raw": excludeLoans
                }
              }
            ]
          }
        }
      }
      : elasticSearchQuery;
  const url = `${getConfig().apiUrl}/aggregate/export/loans`;
  return publicClient.post(
    url,
    {
      searchQuery: { ...elasticSearchQuery, size, from },
      loanType: searchObject?.filterSelections?.["loan.loanType.raw"]?.[0],
      fileName: exportType || "" // default export is loan data tape
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
};

// export const setActiveIntActionsClicked = (isAskAQuestClicked,isSatisfyExceptionClicked,isWaiverReqClicked,exceptionId) => {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_ACTIVE_INTERACTIONS_ACTIONS,
//       payload: {askAQuestion:isAskAQuestClicked,
//       satisfyException:isSatisfyExceptionClicked,
//     waiverReq:isWaiverReqClicked,
//   exceptionId}
//     });
//   };
// }
export const fetchAutoCompleteDropDownData: any = (payload: any) => {
  const url = `${getConfig().apiUrl}/search/${getConfig().aggregateUrl
    }/_search`;

  return publicClient.post(url, payload, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};

// export const updateSortConfig = (data: any): any => {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_SORT_CONFIG,
//       payload: data
//     });
//   };
// };

// export function updateTTFSubmitState(data: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_TTF_SUBMIT,
//       payload: data
//     });
//   };
// }

export const fetchLoanLandmarks = (loanId: string) => {
  const url = `${getConfig().apiUrl}/aggregate/loans/${loanId}/landmarks`;
  return publicClient.get(url);
};

export const uploadDocsBulkUpload = (loanType: string, data: any) => {
  const url = `${getConfig().apiUrl}/bulkupload/mapData/${loanType}`;
  return publicClient.post(url, data, {
    errorConfig: getErrorConfigObject({
      skipForStatus: [400]
    })
  } as any);
};

// export function updateBridgeLoanGroundUp(data: boolean): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: IS_GROUNDUP_BRIDGE,
//       payload: data
//     });
//   };
// }

// export function setLoanTableFunding(data: { [index: string]: string }): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_LOAN_TABLE_FUNDING,
//       payload: data
//     });
//   };
// }
// export function setWarehouseFunding(data: { [index: string]: string }): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_WAREHOUSE_FUNDING,
//       payload: data
//     });
//   };
// }

// For Support UI
export function searchSupportOriginatorNames(sellerName: string) {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/search/${getConfig().orgUser}/_search`;
    publicClient
      .post(url, {
        query: {
          bool: {
            must: [
              {
                match_phrase_prefix: {
                  sellerName: {
                    query: sellerName
                  }
                }
              },
              {
                terms: {
                  "partyType.raw": ["account"]
                }
              },
              {
                terms: {
                  "accountType.raw": ["ORIGINATOR"]
                }
              }
              //   {
              //     exists: {
              //         field: "accountSequence"
              //     }
              // }
            ]
          }
        },
        _source: ["*"],
        size: 500,
        from: 0
      })
      .then((res: any) => {
        console.error(res);
        resolve(res);
      });
  });
}
export function searchSupportUiToorakUsers(
  emailOrActId: string = "",
  partyId: string,
  offSet: number = 0,
  size: number = 500
) {
  const mustArr: any = [
    {
      terms: {
        "access.accountId.raw": [partyId]
      }
    }
  ];

  if (emailOrActId !== "") {
    mustArr.push({
      match_phrase_prefix: {
        "customer.primaryEmail": {
          query: emailOrActId
        }
      }
    });
  }

  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/search/${getConfig().orgUser
      }_aggregate/_search`;
    publicClient
      .post(url, {
        query: {
          bool: {
            must: mustArr
          }
        },
        _source: ["*"],

        size,

        from: offSet
      })
      .then((res: any) => {
        console.error(res);
        resolve(res);
      });
  });
}

export function searchSupportUiOriginatorUsers(
  emailOrActId: string = "",
  partyId: string,
  offSet: number = 0,
  size: number = 500
) {
  const mustArr: any = [
    {
      terms: {
        "access.accountId.raw": [partyId]
      }
    }
  ];

  if (emailOrActId !== "") {
    mustArr.push({
      match_phrase_prefix: {
        "customer.primaryEmail": {
          query: emailOrActId
        }
      }
    });
  }

  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/search/${getConfig().orgUser
      }_aggregate/_search`;
    publicClient
      .post(url, {
        query: {
          bool: {
            must: mustArr
          }
        },
        _source: ["*"],

        size,

        from: offSet
      })
      .then((res: any) => {
        console.error(res);
        resolve(res);
      });
  });
}

export function getOriginatorAccountsNew(partyId: string, offset: number): any {
  return new Promise((resolve, reject) => {
    const url = `${getConfig().apiUrl}/search/${getConfig().orgUser
      }_aggregate/_search`;
    publicClient
      .post(url, {
        query: {
          bool: {
            must: [
              {
                terms: {
                  "access.accountId.raw": [partyId]
                }
              }
            ]
          }
        },
        _source: ["*"],

        size: 10,

        from: offset
      })
      .then((res: any) => {
        console.error(res);
        resolve(res);
      });
  });
}

// export const setIsLoanTableFunded = (isLoanTableFunded: boolean): any => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_TABLE_FUNDING_FLAG,
//       payload: isLoanTableFunded
//     });
//   };
// };

// export const setIsWarehouseFunded = (isWarehousefunded: boolean): any => {
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_WAREHOUSE_FUNDING_FLAG,
//       payload: isWarehousefunded
//     });
//   };
// };

export const getPrimaryGuarantorInfo: any = (
  payload: any,
  loanType: string,
  productType: string
) => {
  const url = `${getConfig().apiUrl
    }/ruleevaluation/loanUserInfo/${loanType}?toorakProduct=${productType}`;
  return new Promise((resolve) => {
    publicClient
      .post(url, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch(() => {
        resolve({});
      });
  });
};
