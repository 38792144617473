/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { LoanList } from "@toorak/tc-common-fe-sdk";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getLoanType } from "../../config/config";
import { getBusinessDatesCount, isTruthy } from "../../masterView/common";
import { convertToMillions, getRateLockRequestedStatus } from "../../utils/utils";
// import {
//   GET_LOAN_PIPELINE_LIST,
//   UPDATE_MASTER_TABLE_FILTERS,
//   UPDATE_MASTER_TABLE_SORT_OPTIONS,
//   UPDATE_MASTER_TABLE_SEARCH_OPTIONS,
//   SET_MASTERVIEW_TYPE,
//   SET_SELECTED_LOANS,
//   UPDATE_SEARCH_FIELD,
//   CHANGE_FILTER_PANEL_VISIBILITY,
//   UPDATE_SIDEPANEL_FILTER_DATA,
//   UPDATE_FILTEROBJ_PAYLOAD,
//   SET_FILTER_PAYLOAD,
//   SAVE_FILTERS_RECEIVED,
//   TRIGGER_REFRESH,
//   GET_ORDER_BEFORE_SORT,
//   UPDATE_AGGREGATE_LOAN_AMOUNT,
//   UPDATE_SAVE_PAYLOAD,
//   UPDATE_ES_QUERY,
//   SET_CUSTOM_COLUMN_LIST,
//   SET_RAW_LOAN_LIST_DATA,
//   SET_PAGE_BLOCK
// } from "./masterView.action";
import { ToorakProductEnum } from "../../create-loan/constants/loanCreationDropDownValues";

export interface MasterViewStore {
  masterViewResult: any[];
  tableFilterConfig?: {};
  tableSortConfig?: {};
  tableSearchConfig?: {};
  savePayload: any[];
  globalSearchKey: string;
  masterViewType: string;
  selectedLoans: any;
  masterViewEdit: boolean;
  showMenu: boolean;
  sidePanelFilterDataFromElasticSearch: any;
  sideFilterObjPayload: any;
  customColumnList: any;
  saveFiltersReceived: any;
  triggerRefresh: boolean;
  orderBeforeSort: any;
  aggregateLoanAmount: string;
  esQuery: any;
  rawLoanListData: LoanList[];
  isPageBlocked?: boolean;
}

const initialState: MasterViewStore = {
  masterViewResult: [],
  masterViewType: "bridge",
  tableFilterConfig: undefined,
  tableSortConfig: undefined,
  tableSearchConfig: undefined,
  selectedLoans: [],
  masterViewEdit: false,
  savePayload: [],
  globalSearchKey: "",
  showMenu: false,
  sidePanelFilterDataFromElasticSearch: {},
  sideFilterObjPayload: {
    bridge: {},
    dscr: {}
  },
  saveFiltersReceived: {
    bridge: false,
    dscr: false
  },
  customColumnList: {
    bridge: [],
    dscr: []
  },
  triggerRefresh: false,
  orderBeforeSort: [],
  aggregateLoanAmount: "0",
  esQuery: {},
  rawLoanListData: [],
  isPageBlocked: false
};

const masterViewSlice = createSlice({
  name: "masterView",
  initialState,
  reducers: {
    updateMasterDataInRedux: (state, action: PayloadAction<any>) => {
      state.masterViewResult = action.payload;
    },
    getOrderBeforeSort: (state, action: PayloadAction<any>) => {
      state.orderBeforeSort = action.payload;
    },
    updateMasterTableFilters: (state, action: PayloadAction<any>) => {
      if (!action.payload?.keepSavedFilter) {
        localStorage.setItem(
          `filter-${state.masterViewType}`,
          JSON.stringify(action.payload?.data || {})
        );
      }
      state.tableFilterConfig = action.payload?.data;
    },
    updateMasterTableSortOptions: (state, action: PayloadAction<any>) => {
      state.tableSortConfig = action.payload;
    },
    updateMasterTableSearchOptions: (state, action: PayloadAction<any>) => {
      state.tableSearchConfig = action.payload;
    },
    setSelectedLoansMasterView: (state, action: PayloadAction<any>) => {
      state.selectedLoans = action.payload;
    },
    updateSavePayload: (state, action: PayloadAction<any>) => {
      state.savePayload = action.payload;
    },
    setMasterViewType: (state, action: PayloadAction<any>) => {
      state.masterViewType = action.payload;
    },
    updateGlobalSearchKey: (state, action: PayloadAction<any>) => {
      state.globalSearchKey = action.payload;
    },
    changeFilterPanelVisibility: (state, action: PayloadAction<any>) => {
      state.showMenu = action.payload;
    },
    updateSidepanelFilterData: (state, action: PayloadAction<any>) => {
      const { masterViewType, response } = action.payload;
      state.sidePanelFilterDataFromElasticSearch[masterViewType] = response;
    },
    updateFilterObjPayload: (state, action: PayloadAction<any>) => {
      const { data, id, viewType } = action.payload;
      state.sideFilterObjPayload[viewType][id] = data;
    },
    setFilterPayload: (state, action: PayloadAction<any>) => {
      const { data, viewType } = action.payload;
      state.sideFilterObjPayload[viewType] = data;
    },
    setSaveFiltersReceived: (state, action: PayloadAction<any>) => {
      state.saveFiltersReceived[action.payload] = true;
    },
    setTriggerRefresh: (state) => {
      state.triggerRefresh = !state.triggerRefresh;
    },
    updateAggregateInitialLoanAmount: (state, action: PayloadAction<any>) => {
      state.aggregateLoanAmount = convertToMillions(action.payload);
    },
    updateESQuery: (state, action: PayloadAction<any>) => {
      const query = action.payload;
      delete query.aggs;
      delete query.size;
      state.esQuery = query;
    },
    setCustomColumns: (state, action: PayloadAction<any>) => {
      const { data, viewType } = action.payload;
      state.customColumnList[viewType] = data;
    },
    setRawLoanListData: (state, action: PayloadAction<any>) => {
      const { loanLists } = action.payload;
      state.rawLoanListData = loanLists;
    },
    setPageBlockMaster: (state, action: PayloadAction<any>) => {
      state.isPageBlocked = action.payload;
    }
  }
});

// Export actions and reducer
export const {
  updateMasterDataInRedux,
  getOrderBeforeSort,
  updateMasterTableFilters,
  updateMasterTableSortOptions,
  updateMasterTableSearchOptions,
  setSelectedLoansMasterView,
  updateSavePayload,
  setMasterViewType,
  updateGlobalSearchKey,
  changeFilterPanelVisibility,
  updateSidepanelFilterData,
  updateFilterObjPayload,
  setFilterPayload,
  setSaveFiltersReceived,
  setTriggerRefresh,
  updateAggregateInitialLoanAmount,
  updateESQuery,
  setCustomColumns,
  setRawLoanListData,
  setPageBlockMaster
} = masterViewSlice.actions;

export const MasterViewReducer = masterViewSlice.reducer;

// export const MasterViewReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case GET_LOAN_PIPELINE_LIST: {
//       return produce(state, (draftState) => {
//         draftState.masterViewResult = action.payload;
//       });
//     }
//     case GET_ORDER_BEFORE_SORT: {
//       return produce(state, (draftState) => {
//         draftState.orderBeforeSort = action.payload;
//       });
//     }
//     case UPDATE_MASTER_TABLE_FILTERS: {
//       return produce(state, (draftState) => {
//         if (!action.keepSavedFilter) {
//           localStorage.setItem(
//             `filter-${draftState.masterViewType}`,
//             JSON.stringify(action.payload || {})
//           );
//         }
//         draftState.tableFilterConfig = action.payload;
//       });
//     }
//     case UPDATE_MASTER_TABLE_SORT_OPTIONS: {
//       return produce(state, (draftState) => {
//         draftState.tableSortConfig = action.payload;
//       });
//     }
//     case UPDATE_MASTER_TABLE_SEARCH_OPTIONS: {
//       return produce(state, (draftState) => {
//         draftState.tableSearchConfig = action.payload;
//       });
//     }

//     case SET_SELECTED_LOANS: {
//       return produce(state, (draftState) => {
//         draftState.selectedLoans = action.payload;
//       });
//     }

//     case UPDATE_SAVE_PAYLOAD: {
//       return produce(state, (draftState) => {
//         draftState.savePayload = action.payload;
//       });
//     }

//     case SET_MASTERVIEW_TYPE: {
//       return produce(state, (draftState) => {
//         draftState.masterViewType = action.payload;
//       });
//     }

//     case UPDATE_SEARCH_FIELD: {
//       return produce(state, (draftState) => {
//         draftState.globalSearchKey = action.payload;
//       });
//     }
//     case CHANGE_FILTER_PANEL_VISIBILITY: {
//       return produce(state, (draftState) => {
//         draftState.showMenu = action.payload;
//       });
//     }
//     case UPDATE_SIDEPANEL_FILTER_DATA: {
//       return produce(state, (draftState) => {
//         const { masterViewType, response } = action.payload;
//         draftState.sidePanelFilterDataFromElasticSearch[masterViewType] =
//           response;
//       });
//     }
//     case UPDATE_FILTEROBJ_PAYLOAD: {
//       return produce(state, (draftState) => {
//         const { data, id, viewType } = action.payload;
//         draftState.sideFilterObjPayload[viewType][id] = data;
//       });
//     }
//     case SET_FILTER_PAYLOAD: {
//       return produce(state, (draftState) => {
//         const { data, viewType } = action.payload;
//         draftState.sideFilterObjPayload[viewType] = data;
//       });
//     }
//     case SAVE_FILTERS_RECEIVED: {
//       return produce(state, (draftState) => {
//         draftState.saveFiltersReceived[action.payload] = true;
//       });
//     }
//     case TRIGGER_REFRESH: {
//       return produce(state, (draftState) => {
//         draftState.triggerRefresh = !draftState.triggerRefresh;
//       });
//     }
//     case UPDATE_AGGREGATE_LOAN_AMOUNT: {
//       return produce(state, (draftState) => {
//         draftState.aggregateLoanAmount = convertToMillions(action.payload);
//       });
//     }

//     case UPDATE_ES_QUERY: {
//       return produce(state, (draftState) => {
//         const query = action.payload;
//         delete query.aggs;
//         delete query.size;
//         draftState.esQuery = query;
//       });
//     }
//     case SET_CUSTOM_COLUMN_LIST: {
//       return produce(state, (draftState) => {
//         const { data, viewType } = action.payload;
//         draftState.customColumnList[viewType] = data;
//       });
//     }
//     case SET_RAW_LOAN_LIST_DATA: {
//       const { loanLists } = action.payload;
//       return produce(state, (draftState) => {
//         draftState.rawLoanListData = loanLists;
//       });
//     }
//     case SET_PAGE_BLOCK: {
//       return produce(state, (draftState) => {
//         draftState.isPageBlocked = action.payload;
//       });
//     }
//     default:
//       return state;
//   }
// };

export function transformMasterData(responseArr: any, masterViewType: any) {
  return responseArr.map((data: any) => {
    const bundle = data?._source?.bundle;
    const loan = data?._source?.loan;
    const ruleVersions = data?._source?.ruleVersions;
    const loanInfo = loan?.loanInfo;
    const loanEconomics = loan?.loanEconomics;
    const loanConfigId = loan?.loanDetailId?.loanConfigId;
    const loanSummaryId = loan?.loanDetailId?.loanSummaryId;
    const loanResult = data?._source?.result?.loanResult;
    const areThereProperties = data?._source?.properties?.length;
    const areThereExceptions = data?._source?.exceptions?.length;
    const preCloseGrades = data?._source?.preCloseGrades;
    const postCloseGrades = data?._source?.postCloseGrades;
    const firstPropertyLocation =
      data?._source?.properties?.[0]?.propertyLocation;
    const loanStage = loan?.loanStage;
    let preCloseEDGrade = "";
    let postCloseEDGrade = "";
    let preCloseSDGrade = "";
    let postCloseSDGrade = "";
    if (preCloseGrades?.length) {
      preCloseGrades.forEach((grade: any) => {
        if (grade?.name?.toUpperCase() === "SECURITIZATION_GRADE") {
          preCloseEDGrade = grade?.value;
        } else if (grade?.name?.toUpperCase() === "WORKFLOW GRADE") {
          preCloseSDGrade = grade?.value;
        }
      });
    }
    if (postCloseGrades?.length) {
      postCloseGrades.forEach((grade: any) => {
        if (grade?.name?.toUpperCase() === "SECURITIZATION_GRADE") {
          postCloseEDGrade = grade?.value;
        } else if (grade?.name?.toUpperCase() === "WORKFLOW GRADE") {
          postCloseSDGrade = grade?.value;
        }
      });
    }
    const edGrade = loanStage === "PRE" ? preCloseEDGrade : postCloseEDGrade;
    const sdGrade = loanStage === "PRE" ? preCloseSDGrade : postCloseSDGrade;
    const loanType = loan?.loanType?.replace(/ /g, "");
    const masterPassCond =
      (loanConfigId?.zillow === "Yes" || loanConfigId?.zillow === "true") &&
      (loanConfigId?.truliaCrime === "Yes" ||
        loanConfigId?.truliaCrime === "true") &&
      // intentionally == only. Do not change
      ((loanConfigId?.ttfVersionId == null &&
        (loanConfigId?.tapeToFile === "Yes" ||
          loanConfigId?.tapeToFile === "true")) ||
        loanSummaryId?.teamIndiaTapetoFile === "Yes" ||
        loanSummaryId?.teamIndiaTapetoFile === "Overridden") &&
      (loanConfigId?.appraisal === "Clear" ||
        loanConfigId?.appraisal === "Yes" ||
        loanConfigId?.appraisal === "true") &&
      (loanConfigId?.streetView === "Yes" ||
        loanConfigId?.streetView === "true");

    const masterValue =
      masterViewType === "bridge"
        ? masterPassCond &&
          (isTruthy(loanSummaryId.budgetReview) ||
            [null, undefined].includes(loanSummaryId.budgetReview) ||
            (loanResult?.loanCharacterisations?.rehabType !== "Heavy Rehab" &&
              loanInfo.toorakProduct !== ToorakProductEnum.GroundUp &&
              (!loanEconomics?.financedBudgetAmount ||
                ["0.00", "$0.00"].includes(
                  loanEconomics.financedBudgetAmount
                ))))
          ? "Pass"
          : "--"
        : masterPassCond &&
          (loanSummaryId?.escrowReconciliation === "Yes" ||
            loanSummaryId?.escrowReconciliation === "true") &&
          (loanSummaryId?.femaSearch === "Yes" ||
            loanSummaryId?.femaSearch === "true") &&
          (loanSummaryId.seasoned === "No" ||
            (loanSummaryId.seasoned === "Yes" &&
              loanSummaryId.paymentHistoryandUPBChecked === "Yes"))
          ? "Pass"
          : "--";
    return {
      primaryLoanId: loanInfo?.primaryLoanId,
      toorakLoanId: data?._id,
      originator: data?._source?.originator?.accountName,
      toorakYield: loanResult?.toorakInterests?.toorakYield,
      finalToorakYield: loanResult?.toorakInterests?.finalToorakYield,
      pricingDiscount: loanResult?.toorakInterests?.pricingDiscount,
      finalToorakPrice: loanResult?.loanPricing?.finalToorakPrice,
      propertyType: areThereProperties
        ? areThereProperties === 1
          ? data?._source.properties[0].propertyinfo?.propertyType
          : `[Multiple properties -${data?._source.properties.length}]`
        : "",
      propertyTypeList: areThereProperties
        ? data?._source.properties
          .map((prop: any) => prop.propertyinfo?.propertyType)
          .filter(Boolean)
        : [],
      loanStructure: loanInfo?.loanStructure,
      toorakProduct: loanInfo?.toorakProduct,
      loanStage,
      interestReserve: loanEconomics?.financedInterestReserve,
      financedBudgetAmount: loanEconomics?.financedBudgetAmount,
      loanProcessType: loan?.loanProcessType,
      fundingType: loan?.loanDetailId?.fundingType,
      isWarehouseFunded: loan?.loanDetailId?.isWarehouseFunded,
      loanType: loanType?.includes("30YearLoan")
        ? getLoanType[1].displayValue
        : loanType,
      loanStatus: loan?.loanState,
      subStatus: areThereExceptions
        ? data?._source?.exceptions
          .filter((obj: any) => obj?.type?.toLowerCase() === "exception")
          .every((obj: any) =>
            ["waived", "close", "clear", "satisfied", "rescind"].includes(
              obj?.status?.toLowerCase()
            )
          )
          ? "Clear"
          : "Open"
        : "In-Review",
      firstOpenConditionDate: data?._source?.firstOpenConditionDate,
      address: areThereProperties
        ? data?._source.properties.length === 1
          ? [
            firstPropertyLocation?.addressNumber,
            firstPropertyLocation?.addressLine1,
            firstPropertyLocation?.addressLine2,
            firstPropertyLocation?.addressLine3,
            firstPropertyLocation?.city,
            firstPropertyLocation?.state,
            firstPropertyLocation?.pincode,
            firstPropertyLocation?.country
          ]
            .filter(Boolean)
            .join(", ")
          : `[Multiple properties -${data?._source.properties.length}]`
        : "",
      addressList: areThereProperties
        ? data?._source.properties.map((prop: any) =>
          [
            prop.propertyLocation?.addressNumber,
            prop.propertyLocation?.addressLine1,
            prop.propertyLocation?.addressLine2,
            prop.propertyLocation?.addressLine3,
            prop.propertyLocation?.city,
            prop.propertyLocation?.state,
            prop.propertyLocation?.pincode,
            prop.propertyLocation?.country
          ]
            .filter(Boolean)
            .join(", ")
        )
        : [],
      loanPurpose: loanInfo?.loanPurpose,
      interestRateAsOfCutOffDate: loanEconomics?.interestRateAsOfCutOffDate,
      prepaymentPenaltyMonths: loanEconomics?.prepaymentPenaltyMonths,
      interestOnlyPeriod: loanEconomics?.interestOnlyPeriod,
      dscr: loanResult?.loanEconomics?.dscr,
      originalLtc: loanResult?.loanFeatures?.originalLtc,
      originalAsIsLtv: loanResult?.loanFeatures?.originalAsIsLtv,
      originalAsRepairedLtv: loanResult?.loanFeatures?.originalAsRepairedLtv,
      rateLockExpiryDate: loan?.rateLockExpiryDate,
      effectiveStartDate: ruleVersions?.rateSheet?.effectiveStartDate,
      originalCreditScoreMedian:
        data?._source?.borrowerDetails?.loanUser?.originalCreditScoreMedian,
      originalLoanAmount: loanEconomics?.originalLoanAmount,
      currentLoanBalance: loanEconomics?.currentLoanBalance,
      adjustedAsIsValue: data?._source?.result?.propertiesResults?.length
        ? data?._source.result.propertiesResults.reduce(
          (prev: any, next: any) =>
            prev + next?.loanFeatures?.adjustedAsIsValue,
          0
        )
        : "",
      preCloseEDGrade,
      postCloseEDGrade,
      cutOffDateLoanAmount: loanEconomics?.cutOffDateLoanAmount,
      cutOffDateMaximumLoanAmount: loanEconomics?.cutOffDateMaximumLoanAmount,
      lending:
        loanInfo?.loanStructure === "Multiple Draws"
          ? loanEconomics?.cutOffDateLoanAmount
          : loanEconomics?.cutOffDateMaximumLoanAmount,
      totalBudgetAmount: loanEconomics?.totalBudgetAmount,
      originalAsIsAppraisalValue: areThereProperties
        ? data?._source.properties[0].propertyEconomics
          ?.originalAsIsAppraisalValue
        : "",
      originalAsRepairedAppraisedValue: areThereProperties
        ? data?._source.properties[0].propertyEconomics
          ?.originalAsRepairedAppraisedValue
        : "",
      firstLoss: loanResult?.firstLoss?.firstLossPercentage,
      firstPaymentDateOfLoan: loanInfo?.firstPaymentDateOfLoan,
      takeoutPartnerId: loanInfo?.takeoutPartner,
      experience: data?._source?.borrowerDetails?.loanUser?.experience,
      edGrade,
      sdGrade,
      status: loanConfigId?.status || "",
      closingDate: bundle?.closingDate,
      holdback: loanResult?.firstLoss?.totalCreditReserveHoldback,
      comments: loanConfigId?.comments || "",
      master: masterValue,
      appraisal: isTruthy(loanConfigId?.appraisal)
        ? "Clear"
        : ["No", "false", false].includes(loanConfigId?.appraisal)
          ? "Reject"
          : loanConfigId?.appraisal || "In-Review",
      appraiserList: loanConfigId?.appraiserList,
      streetView: loanConfigId?.streetView,
      zillow: loanConfigId?.zillow,
      truliaCrime: loanConfigId?.truliaCrime,
      femaSearch: loanSummaryId?.femaSearch,
      budgetReview: loanSummaryId?.budgetReview,
      ttfVersionId: loanConfigId?.ttfVersionId,
      tapeToFile: loanConfigId?.tapeToFile,
      teamIndiaTapetoFile: loanSummaryId?.teamIndiaTapetoFile,
      custodyDocuments: loanConfigId?.custodyDocuments,
      toorakEligibility: loanConfigId?.toorakEligibility || "",
      escrowReconciliation: loanSummaryId?.escrowReconciliation,
      seasoned: loanSummaryId?.seasoned,
      paymentHistoryandUPBChecked: loanSummaryId?.paymentHistoryandUPBChecked,
      dueDiligenceName: loan?.dueDiligencePartyInfo?.accountName,
      dateUploaded: loanConfigId?.dateUploaded,
      loanSubmissionDate: loan?.loanSubmissionDate,
      initialLoanSubmissionDate: loan?.initialLoanSubmissionDate,
      dayCounter: loan?.loanSubmissionDate
        ? getBusinessDatesCount(loan?.loanSubmissionDate)
        : "",
      targetPurchaseDate: loanInfo?.targetPurchaseDate || "",
      spocName: data?._source?.spocPartyInfo?.fullName,
      riskScoresVersion: loanResult?.riskScores?.riskScoresVersion,
      riskScore: loanResult?.riskScores?.riskScoresVersion === "2.0" ?
        `${(parseFloat(loanResult?.riskScores?.totalAggregateRisk ?? "0.0") * 100).toFixed(3)}%` : loanResult?.riskScores?.totalAggregateRisk,
      riskBucket: loanResult?.riskScores?.riskBucket,
      rehabType: loanResult?.loanCharacterisations?.rehabType,
      ratelockRequestedDate: loan.loanDetailId.rateLocks?.[0]?.created_on,
      ratelockStatus: getRateLockRequestedStatus(loan.loanDetailId.rateLocks?.[0]?.rateLockStatus),
      needEnhancedReview:
        loanResult?.loanCharacterisations?.needEnhancedReview ??
        loanResult?.loanCharacterization?.needEnhancedReview
    };
  });
}
